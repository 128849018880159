import { SvgIcon } from '@mui/material';

interface ArrowUpIconProps {
  width?: string;
  height?: string;
  color?: string;
  display?: 'block' | 'none';
}

const ArrowUpIcon = ({ width = '20px', height = '20px', color = '#FFFFFF', display = 'block' }: ArrowUpIconProps) => {
  return (
    <SvgIcon inheritViewBox={true} sx={{ width, height, color, display }}>
      <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.15852 11.4581C7.82093 11.4581 7.58723 11.304 7.45742 10.9958C7.32761 10.6876 7.3813 10.4149 7.61848 10.1777L9.48063 8.31556C9.55863 8.23756 9.64089 8.1804 9.72742 8.14408C9.81396 8.10776 9.90745 8.0896 10.0079 8.0896C10.1083 8.0896 10.2018 8.10776 10.2883 8.14408C10.3749 8.1804 10.4571 8.23756 10.5351 8.31556L12.3973 10.1777C12.6344 10.4149 12.6881 10.6876 12.5583 10.9958C12.4285 11.304 12.1948 11.4581 11.8572 11.4581H8.15852Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export { ArrowUpIcon };
