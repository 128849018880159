import { useState, useCallback, useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
  MenuItem,
  SelectChangeEvent,
  debounce,
  styled,
  Stack,
} from '@mui/material';

import { useAppSelector, useAppDispatch } from 'app/hooks';
import { setType, resetLocations, selectLocations, setBuilding, setName } from 'features/locations';
import { checkComplexName } from 'features/locations/locations-api';
import { useSearchBuildingQuery } from 'features/locations/locations-queries';
import { buildingTypeFormatter } from 'lib/utils';
import { MoisBuilding, BuildingType } from 'models';

import { FmkFormCard, FmkFormLabel, FmkTextField, FmkSelect } from 'components/fds';
import { AddressType } from './AddressType';

const SelectedAddr = styled('div')(() => ({
  padding: '12px',
  borderRadius: '8px',
  backgroundColor: 'rgba(0, 128, 255, 0.04)',
}));

export const ComplexForm = ({ disabledField = [] }: { disabledField?: any }) => {
  const {
    complex,
    originalData: { complex: originalComplex },
  } = useAppSelector(selectLocations);
  const dispatch = useAppDispatch();

  const [complexName, setComplexName] = useState('');
  const [buildingSearchWord, setBuildingSearchWord] = useState('');
  const [showBuildingSelect, setShowBuildingSelect] = useState(false);
  const [openBuildingSelect, setOpenBuildingSelect] = useState(false);
  const [nameStatus, setNameStatus] = useState<'none' | 'ok' | 'error'>('none');

  // 주소 검색 쿼리
  const { refetch, data, isFetching } = useSearchBuildingQuery(buildingSearchWord);

  // 위치명 입력
  const onComplexNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNameStatus('none');
    dispatch(setName(''));
    setComplexName(e.target.value.replaceAll(' ', ''));
    isComplexNameAvailable(e.target.value.replaceAll(' ', ''));
  };

  // 위치명 중복검사
  const isComplexNameAvailable = useCallback(
    debounce(async (complexName: string) => {
      if (complexName.length < 2) return;

      try {
        const { status } = await checkComplexName(complexName);

        if (status === 200) {
          dispatch(setName(complexName));
          setNameStatus('ok');
        }
      } catch (e) {
        if (complexName === originalComplex.name) {
          dispatch(setName(originalComplex.name));
          setNameStatus('none');
        } else {
          setNameStatus('error');
        }
      }
    }, 500),
    [],
  );

  // 위치명 TextField 상태에 따른 문구
  const styleTextField = {
    none: '최소 2자 ~ 최대 40자까지 입력 가능.',
    ok: '사용 가능한 위치명 입니다.',
    error: '이미 사용중인 위치명입니다. 다른 위치명을 입력해주세요.',
  };

  // 주소 검색버튼 클릭
  const onBuildingSearchBtnClick = async () => {
    if (buildingSearchWord.length < 2) return;
    setShowBuildingSelect(true);
    dispatch(setBuilding(null));
    refetch().then((result) => {
      if (result.data?.status === 200) {
        setOpenBuildingSelect(true);
      }
    });
  };

  // 도로명 주소 가공
  const handleRoadAddrBdNm = (mois: MoisBuilding) => {
    const { zipNo, roadAddrPart1, bdNm } = mois;
    return `(${zipNo}) ${roadAddrPart1} ${bdNm}`;
  };

  // select box에서 주소를 선택했을때
  const onBuildingSelectChange = (e: SelectChangeEvent<any>) => {
    dispatch(setBuilding(e.target.value));
    setShowBuildingSelect(false);
  };

  // 선택된 주소에서 x 버튼 클릭시
  const onCancelBtnClick = () => {
    dispatch(setBuilding(null));
    if (data) setShowBuildingSelect(true);
  };

  // 빌딩타입 셀렉트박스 아이템용 배열 생성
  const buildingTypeArray = Object.values(BuildingType)
    .filter((value: any) => !isNaN(value))
    .map((value: any) => buildingTypeFormatter(value));

  useEffect(() => {
    // 처음 렌더링 후 store의 complex에 값이 있다면 local state에도 같은 값을 할당
    if (complex.name && complexName === '') setComplexName(complex.name);
  }, [complex]);

  return (
    <FmkFormCard sx={{ marginBottom: '24px' }}>
      {/* 기본 위치 정보 */}
      <Typography variant="h5">기본 위치 정보</Typography>

      {/* 위치명 */}
      <Stack mt={'32px'}>
        <FmkFormLabel title="위치명" required />
        <FmkTextField
          value={complexName}
          status={nameStatus}
          onChange={onComplexNameChange}
          placeholder={'위치명을 입력해 주세요.'}
          helperText={styleTextField[nameStatus]}
          autoFocus={true}
          inputProps={{ maxLength: 40, autoComplete: 'off' }}
          disabled={disabledField.includes('name')}
        />
      </Stack>

      {/* 주소 검색 */}
      <Stack mt={'32px'}>
        <FmkFormLabel title="주소" required />
        <Stack direction={'row'} alignItems={'flex-start'}>
          <TextField
            sx={{ width: '408px', marginBottom: '8px' }}
            onChange={(e) => setBuildingSearchWord(e.target.value)}
            onKeyDown={async (e) => {
              if (e.key === 'Enter' && !openBuildingSelect) {
                await onBuildingSearchBtnClick();
              }
            }}
            placeholder={'도로명, 지번 주소로 검색해 주세요.'}
            value={buildingSearchWord}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{ autoComplete: 'off' }}
            disabled={disabledField.includes('building')}
          />
          <Button
            color="secondary"
            variant="contained"
            disabled={buildingSearchWord.length < 2}
            onClick={onBuildingSearchBtnClick}
            sx={{ marginLeft: '8px' }}
          >
            검색
          </Button>
        </Stack>
      </Stack>

      {/* 주소 셀렉트박스 */}
      {showBuildingSelect && (
        <FmkSelect
          displayEmpty
          size={'small'}
          sx={{ width: '100%' }}
          renderValue={() => {
            if (isFetching) return <Typography variant="body1">조회중: {buildingSearchWord}</Typography>;
            if (data?.status === 204)
              return <Typography variant="body1">조회 결과 없음: {buildingSearchWord}</Typography>;
            return <Typography>주소 선택</Typography>;
          }}
          onChange={onBuildingSelectChange}
          open={openBuildingSelect}
          onOpen={() => setOpenBuildingSelect(true)}
          onClose={() => setOpenBuildingSelect(false)}
          value={''}
        >
          {data?.status === 200 &&
            data?.data.map((v: any, i: number) => {
              return (
                <MenuItem value={v} key={i}>
                  <Stack spacing={'8px'} sx={{ padding: '6px 0px' }}>
                    <Stack direction={'row'}>
                      <AddressType>도로명</AddressType>
                      <Typography variant="body1">{handleRoadAddrBdNm(v)}</Typography>
                    </Stack>
                    <Stack direction={'row'}>
                      <AddressType>지번</AddressType>
                      <Typography variant="body1">{v.jibunAddr}</Typography>
                    </Stack>
                  </Stack>
                </MenuItem>
              );
            })}
        </FmkSelect>
      )}

      {/* 선택된 주소 */}
      {complex?.building && (
        <SelectedAddr>
          <Stack spacing={'8px'}>
            <Stack direction={'row'}>
              <AddressType>도로명</AddressType>
              <Typography sx={{ width: '368px' }} variant="body2">
                {handleRoadAddrBdNm(complex?.building)}
              </Typography>
              {!disabledField.includes('building') && (
                <ClearIcon
                  onClick={onCancelBtnClick}
                  sx={{ width: '20px', height: '20px', marginLeft: '12px', color: 'gray.700', cursor: 'pointer' }}
                />
              )}
            </Stack>
            <Stack direction={'row'}>
              <AddressType>지번</AddressType>
              <Typography sx={{ width: '368px' }} variant="body2">
                {complex?.building?.jibunAddr}
              </Typography>
            </Stack>
          </Stack>
        </SelectedAddr>
      )}

      {/* 위치 종류 셀렉트박스 */}
      <Stack mt={'32px'}>
        <FmkFormLabel title="위치 종류" required />
        <FmkSelect
          size={'small'}
          renderValue={(selected: any) => {
            if (!selected) return <Typography>위치 종류 선택</Typography>;
            return <Typography>{buildingTypeArray[selected]}</Typography>;
          }}
          value={complex.type}
          onChange={(e: SelectChangeEvent<any>) => {
            dispatch(setType(e.target.value));
            dispatch(resetLocations());
          }}
          disabled={disabledField.includes('type')}
        >
          <MenuItem value={0} key={0}>
            <Typography>선택하지 않음 </Typography>
          </MenuItem>
          {buildingTypeArray.map(
            (type, index) =>
              index > 0 && (
                <MenuItem value={index} key={index}>
                  <Typography>{type}</Typography>
                </MenuItem>
              ),
          )}
        </FmkSelect>
      </Stack>
    </FmkFormCard>
  );
};
