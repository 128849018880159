import axios, { CanceledError, CancelTokenSource } from 'axios';

import RestApiClient from 'lib/rest-api-client';
import {
  CreateComplexLocationPayload,
  PostComplexPayload,
  ComplexListResponse,
  GetComplexesParams,
  SearchComplexesParams,
  LocationParams,
  PutComplexPayload,
} from 'models';
import { showError } from '../notification/notification-slice';

const restApiClient = new RestApiClient({
  baseUrl: process.env.REACT_APP_MEDIA_DEVICES_API_URL || '',
  timeout: 10 * 1000,
});

// 위치관리 페이지 조회
const getComplexes = async (params: GetComplexesParams): Promise<ComplexListResponse> => {
  try {
    const { data } = await restApiClient.get('/v1/complexes', { params });
    return data as ComplexListResponse;
  } catch (e) {
    showError({ message: '위치 목록을 가져오는 도중 오류가 발생했습니다' });
    return {
      items: [],
      nextPageToken: undefined,
      total: 0,
    };
  }
};

const searchComplexes = async (params: SearchComplexesParams): Promise<ComplexListResponse> => {
  try {
    const { data } = await restApiClient.get('/v1/complexes/search', { params });
    data.nextPage = data.total - params.page * params.size > 0 ? params.page + 1 : undefined;
    return data as ComplexListResponse;
  } catch (e) {
    showError({ message: '위치 목록을 가져오는 도중 오류가 발생했습니다' });
    return {
      items: [],
      nextPage: undefined,
      total: 0,
    };
  }
};

// 기본위치 및 하위 상세위치 조회
const getComplexById = async (complexId: string | undefined) => {
  return await restApiClient.get(`/v1/complexes/${complexId}`);
};

// 위치명 중복 검사
let checkComplexNameCancelToken: CancelTokenSource | undefined;
const checkComplexName = async (name: string) => {
  try {
    if (checkComplexNameCancelToken) {
      checkComplexNameCancelToken.cancel('checkComplexName-cancel');
    }
    checkComplexNameCancelToken = axios.CancelToken.source();

    return await restApiClient.get(`/v1/complexes/availability?q=name:eq:${name}`, {
      cancelToken: checkComplexNameCancelToken.token,
    });
  } catch (e) {
    if (e instanceof CanceledError && e.message === 'checkComplexName-cancel') {
      return {
        status: 'cancelled',
      };
    }
    return Promise.reject(e);
  }
};

// 건물(주소) 검색
const searchBuilding = async (searchWord: string) => {
  return await restApiClient.get(`/v1/mois/buildings?q=${encodeURIComponent(searchWord)}&pageSize=10`);
};

// 기본위치 등록
const postComplex = async (payload: PostComplexPayload) => {
  return await restApiClient.post('/v1/complexes', payload);
};

// 기본위치 수정
const putComplex = async (payload: PutComplexPayload) => {
  return await restApiClient.put(`/v1/complexes/${payload.complexId}`, payload.data);
};

// 기본위치 삭제
const deleteComplex = async (complexId: string) => {
  return await restApiClient.delete(`/v1/complexes/${complexId}`);
};

// 상세위치 등록
const postLocations = async (payload: { complexId: string; data: CreateComplexLocationPayload }) => {
  return await restApiClient.post(`/v1/complexes/${payload.complexId}/locations`, payload.data);
};

// 상세위치 수정
const putLocation = async (payload: { complexId: string; locationId: string; data: CreateComplexLocationPayload }) => {
  return await restApiClient.put(`/v1/complexes/${payload.complexId}/locations/${payload.locationId}`, payload.data);
};

// 상세위치 삭제
const deleteLocation = async (params: LocationParams) => {
  return await restApiClient.delete(`/v1/complexes/${params.complexId}/locations/${params.locationId}`);
};

// 상세위치에 매체 연결
const putAssociateDevice = async (params: LocationParams) => {
  return await restApiClient.put(`/v1/complexes/${params.complexId}/locations/${params.locationId}/device`, {
    serialNumber: params.serialNumber,
  });
};
// 상세위치에 연결된 매체 해제
const deleteAssociatedDevice = async (params: LocationParams) => {
  return await restApiClient.delete(`/v1/complexes/${params.complexId}/locations/${params.locationId}/device`);
};

export {
  checkComplexName,
  searchBuilding,
  postComplex,
  searchComplexes,
  deleteComplex,
  getComplexes,
  postLocations,
  deleteLocation,
  putAssociateDevice,
  deleteAssociatedDevice,
  getComplexById,
  putComplex,
  putLocation,
};
