import axios, { CanceledError, CancelTokenSource } from 'axios';

import RestApiClient from 'lib/rest-api-client';
import { convertDateStringToEpoch } from 'lib/utils';

import { ComplexPayload } from './new-complex-editor-slice';
import { ComplexSearchItem } from 'models/complex';

let searchComplexCancelToken: CancelTokenSource | undefined;
const searchComplexCancelMessage = 'canceled-by-user';

const restApiClient = new RestApiClient({
  baseUrl: process.env.REACT_APP_MEDIA_DEVICES_API_URL || '',
  timeout: 5000,
});

const postComplex = async (payload: ComplexPayload) => {
  const data = {
    ...payload,
    contract: {
      ...payload.contract,
      madeAt: convertDateStringToEpoch(payload.contract.madeAt),
      beginsAt: convertDateStringToEpoch(payload.contract.beginsAt),
      endsAt: convertDateStringToEpoch(payload.contract.endsAt),
    },
  };
  return await restApiClient.post('/complex', data);
};

const searchComplexes = async (keyword: string): Promise<ComplexSearchItem[] | undefined> => {
  if (searchComplexCancelToken) {
    searchComplexCancelToken.cancel(searchComplexCancelMessage);
  }
  searchComplexCancelToken = axios.CancelToken.source();
  try {
    const response = await restApiClient.get('/complex/search', {
      params: { q: keyword },
      cancelToken: searchComplexCancelToken?.token,
    });
    return response.data;
  } catch (e) {
    // return an empty list if the request has been cancelled by subsequent search
    if (e instanceof CanceledError && e.message === searchComplexCancelMessage) {
      return [];
    }
    return undefined;
  }
};

export { postComplex, searchComplexes };
