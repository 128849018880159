import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ThemeProvider } from '@mui/material';

import { store } from './app/store';
import App from './App';
import reportWebVitals from './report-web-vitals';
import './index.css';
import { fmkTheme } from './theme/fmk-theme';

const container = document.getElementById('root')!;
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <ThemeProvider theme={fmkTheme}>
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
