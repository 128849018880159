import moment from 'moment';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';

import { useAppDispatch } from 'app/hooks';
import { ModalButton } from 'components/promotions-modal/';
import { Promotion } from 'models';
import { patchPromotionAsync, PublishPromotionPayload } from 'features/promotions/promotion-slice';

interface LiveModalProps {
  open: boolean;
  onClose: (open: boolean) => void;
  promotion: Promotion;
}

const renderDate = (date: string): string => {
  if (!date) {
    return '';
  }
  const mDate = moment(date);
  const locale = window.navigator.language;
  return Intl.DateTimeFormat(locale, { dateStyle: 'medium', timeStyle: 'short' }).format(mDate.toDate());
};

const LivePublishModal = ({ open, promotion, onClose }: LiveModalProps) => {
  const dispatch = useAppDispatch();
  const appleTreeUrl = process.env.REACT_APP_APPLETREE_URL;

  const cancelModal = () => {
    onClose(false);
  };

  const onPublishPromotion = () => {
    const payload: PublishPromotionPayload = { promotionId: promotion.id, isPublished: !promotion.isPublished };
    dispatch(patchPromotionAsync(payload));
    onClose(false);
  };

  return (
    <Dialog open={open} onClose={cancelModal} maxWidth="xl">
      <Box>
        <Box sx={{ background: '#E8EBED', width: '640px', height: '248px', pl: 1 }}>
          <DialogTitle fontSize="24px" sx={{ pt: 4 }}>
            {promotion.isPublished ? '배너 Live 반영 해제' : '배너 확인 후 Live 반영'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="body1" component={'div'} fontSize="14px">
              <Typography>이벤트명 : {promotion.name}</Typography>
              <Typography>
                노출 기간 : {renderDate(promotion.startDate)} ~ {renderDate(promotion.endDate)}
              </Typography>
              <Typography>
                노출 위치 :{' '}
                {promotion.locationType === 'product' ? promotion.product : `개별단지 ${promotion.complex}개`}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ModalButton
              cancelButtonText="취소"
              publishedButtonText={promotion.isPublished ? '배너 Live 반영 해제' : '배너 Live 반영 시키기'}
              onCancel={cancelModal}
              onPublish={onPublishPromotion}
            />
          </DialogActions>
        </Box>
        {!promotion.isPublished && (
          <DialogContent>
            <iframe src={`${appleTreeUrl}/event/?promotionId=${promotion.id}`} width="375px" height="860px"></iframe>
          </DialogContent>
        )}
      </Box>
    </Dialog>
  );
};

export type { LiveModalProps };
export { LivePublishModal };
