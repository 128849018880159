import { TextField, TextFieldProps, styled } from '@mui/material';

type FmkTextFieldType = TextFieldProps & {
  status?: 'none' | 'ok' | 'error';
  statusHelperText?: {
    none: string;
    ok: string;
    error: string;
  };
};

export const FmkTextField = styled((props: FmkTextFieldType) => (
  <TextField error={props.status === 'error'} {...props} />
))((props) => {
  return {
    '& .MuiFormHelperText-root': {
      color: props.status === 'ok' ? props.theme.palette.success.main : '',
    },
  };
});
