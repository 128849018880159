import { createAsyncThunk } from '@reduxjs/toolkit';

import { getProvincesStat, getStatusStat, getTypesStat, searchDevices } from './devices-api';

import { showError, showWarning } from '../notification/notification-slice';
import { DeviceSearchResult } from './devices-slice';

interface DeviceLocationStat {
  name: string;
  devices: number;
  complexes: number;
}

interface DeviceTypesStat {
  name: string;

  devices: number;
}

interface DeviceStatusStat {
  status: string;
  devices: number;
}

interface LoadResult {
  locationStat: DeviceLocationStat[] | undefined;
  typesStat: DeviceTypesStat[] | undefined;
  statusStat: DeviceStatusStat[] | undefined;
  devices: DeviceSearchResult | undefined;
}

const loadAsync = createAsyncThunk('devices/load', async (): Promise<LoadResult> => {
  const results = await Promise.allSettled([
    getProvincesStat(),
    getTypesStat(),
    getStatusStat(),
    searchDevices(undefined),
  ]);

  return {
    locationStat: (results[0] as any).value,
    typesStat: (results[1] as any).value,
    statusStat: (results[2] as any).value,
    devices: (results[3] as any).value,
  };
});

interface SearchOption {
  search: string;
  value: string;
}

const searchDevicesAsync = createAsyncThunk(
  'devices/search',
  async (option: SearchOption | undefined, { dispatch }): Promise<DeviceSearchResult> => {
    try {
      const response = await searchDevices(option);
      if (response.status === 'ok' && response.devices.length === 0) {
        dispatch(showWarning({ message: '검색 결과가 없습니다. 다른 검색어를 사용해 주세요' }));
      }
      return response;
    } catch (e) {
      dispatch(showError({ message: '매체 검색 중 오류가 발생했습니다' }));
      return { status: 'error', devices: [] };
    }
  },
);

const getProvinceStatAsync = createAsyncThunk('devices/get/stat/province', async (_, { dispatch }) => {
  const data = await getProvincesStat();
  if (data) {
    return data;
  } else {
    dispatch(showError({ message: '차트를 읽는 중 오류가 발생했습니다. 페이지를 새로 고침해 주세요' }));
    return undefined;
  }
});

const getTypesStatAsync = createAsyncThunk('devices/get/stat/types', async (_, { dispatch }) => {
  const data = await getTypesStat();
  if (data) {
    return data;
  } else {
    dispatch(showError({ message: '차트를 읽는 중 오류가 발생했습니다. 페이지를 새로 고침해 주세요' }));
    return undefined;
  }
});

const getStatusStatAsync = createAsyncThunk('devices/get/stat/status', async () => {
  const data = await getStatusStat();
  if (data) {
    return data;
  } else {
    return undefined;
  }
});

export type { DeviceLocationStat, DeviceTypesStat, DeviceStatusStat, LoadResult, SearchOption };
export { loadAsync, getProvinceStatAsync, getStatusStatAsync, getTypesStatAsync, searchDevicesAsync };
