import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { getPromotionsAsync, selectPromotions } from 'features/promotions/promotion-slice';
import { LivePublishModal } from 'components/promotions-modal';
import { LoadingIndicator } from './LoadingIndicator';
import { Promotion } from 'models';
import moment from 'moment';

interface CellPromotionData {
  status: string;
  thumbnailImage: string;
  name: string;
  text: string;
  landingUrl: string;
  startDate: string;
  endDate: string;
  location: string[];
  note: string;
  isPublished: boolean;
}

interface HeadCell {
  id: keyof CellPromotionData;
  label: string;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

const headCells: readonly HeadCell[] = [
  { id: 'thumbnailImage', label: '배너 이미지', align: 'center' },
  { id: 'name', label: '이벤트명', align: 'left' },
  { id: 'text', label: '배너 텍스트', align: 'left' },
  { id: 'landingUrl', label: '랜딩 URL', align: 'left' },
  { id: 'startDate', label: '노출 시작 일시', align: 'left' },
  { id: 'endDate', label: '노출 종료 일시', align: 'left' },
  { id: 'location', label: '노출 위치', align: 'left' },
  { id: 'note', label: '특이 사항', align: 'left' },
  { id: 'status', label: '상태', align: 'center' },
  { id: 'isPublished', label: 'Live 반영', align: 'center' },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding={'normal'}>
            {headCell.label}
            {<Box component="span" sx={visuallyHidden}></Box>}
          </TableCell>
        ))}
        <TableCell align={'center'}>수정</TableCell>
      </TableRow>
    </TableHead>
  );
}
const PromotionTable = () => {
  const ROWS_PER_PAGE = 15;
  const navigate = useNavigate();

  const [promotionModalData, setPromotionModalData] = useState({
    id: '',
    status: '',
    imageUrl: '',
    isPublished: false,
    firstText: '',
    secondText: '',
    startDate: '',
    endDate: '',
    landingPageUrl: '',
    locationType: '',
    product: '',
    complex: 0,
    name: '',
    displayOrder: 0,
  });
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const { items, totalItems, currentPage, loadingStatus, isProcessing, keyword } = useAppSelector(selectPromotions);

  useEffect(() => {
    if (loadingStatus === 'idle' && items.length === 0) {
      dispatch(getPromotionsAsync({ page: currentPage, rowsPerPage: ROWS_PER_PAGE }));
    }
  }, [false]);

  const handleClick = (id: string) => {
    console.log('handleClick', id);
    // TODO: promotion 상세보기
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    dispatch(getPromotionsAsync({ page: newPage, keyword, rowsPerPage: ROWS_PER_PAGE }));
  };

  const renderStatus = (promotion: Promotion) => {
    if (!promotion.isPublished) {
      return 'Live 미반영';
    } else {
      const currentDate = new Date();
      const startDate = new Date(promotion.startDate);
      const endDate = new Date(promotion.endDate);
      if (startDate <= currentDate && currentDate <= endDate) {
        return 'Live 반영중';
      } else if (currentDate < startDate) {
        return 'Live 반영대기';
      } else {
        return 'Live 반영종료';
      }
    }
  };

  const renderDate = (date: string): string => {
    const mDate = moment(date);
    const locale = window.navigator.language;
    return Intl.DateTimeFormat(locale, { dateStyle: 'medium', timeStyle: 'short' }).format(mDate.toDate());
  };

  const openModal = (promotion: Promotion) => {
    setOpen(true);
    setPromotionModalData(promotion);
  };

  const onModification = (promotionId: string) => {
    navigate(`/promotions/${promotionId}`);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography>프로모션의 총 개수 : {totalItems}</Typography>
      {(loadingStatus === 'loading' || isProcessing) && <LinearProgress />}
      {isProcessing && <LoadingIndicator />}
      {loadingStatus === 'idle' && !isProcessing && (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
              <EnhancedTableHead />
              <TableBody>
                {items.length > 0 &&
                  items.map((promotion) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => handleClick(promotion.id)}
                        role="checkbox"
                        tabIndex={-1}
                        key={promotion.id}
                      >
                        <TableCell align="center">
                          <img
                            alt="image"
                            style={{ display: 'block', margin: 'auto' }}
                            src={promotion.imageUrl}
                            height={65}
                            width={65}
                          />
                        </TableCell>
                        <TableCell align="left">{promotion.name}</TableCell>
                        <TableCell align="left">
                          {promotion.firstText}
                          <br />
                          {promotion.secondText}
                        </TableCell>
                        <TableCell align="left">
                          <a href={promotion.landingPageUrl} target="_blank">
                            {promotion.landingPageUrl}
                          </a>
                        </TableCell>
                        <TableCell align="left">{renderDate(promotion.startDate)}</TableCell>
                        <TableCell align="left">{renderDate(promotion.endDate)}</TableCell>
                        <TableCell align="left">
                          {promotion.locationType === 'product' ? promotion.product : `개별단지 ${promotion.complex}개`}
                        </TableCell>
                        <TableCell align="left">{promotion?.note}</TableCell>
                        <TableCell component="th" id={promotion.id} scope="promotion" padding="none" align="center">
                          {renderStatus(promotion)}
                        </TableCell>
                        <TableCell align="center" key={promotion.id}>
                          {promotion.isPublished ? (
                            <>
                              <Typography>반영 완료</Typography>
                              <Button color="error" onClick={() => openModal(promotion)}>
                                반영 해제
                              </Button>
                            </>
                          ) : (
                            <Button onClick={() => openModal(promotion)}>반영하기</Button>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Button onClick={() => onModification(promotion.id)}>수정</Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {loadingStatus === 'idle' && items.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center">
                      <Typography variant="subtitle1">등록한 프로모션이 없습니다</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            component="div"
            count={totalItems}
            rowsPerPage={ROWS_PER_PAGE}
            page={currentPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      )}
      {<LivePublishModal open={open} promotion={promotionModalData} onClose={setOpen}></LivePublishModal>}
    </Box>
  );
};

export { PromotionTable };
