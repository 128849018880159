import { ChangeEvent, Component } from 'react';
import { Box, Card, CardContent, Container, IconButton, TextField, styled, Stack, Typography } from '@mui/material';
import { Map, Search } from '@mui/icons-material';
import { AddressSearchService } from 'services/address-search-service';
import { Address } from 'models';
import { AddressSkeleton } from './AddressSkeleton';
import { addressFormatter } from 'lib/address-formatter';
import { debounce } from 'lib/utils';

/**
 * AddressFinderProps AddressFinder 컴포넌트의 속성을 정의합니다
 * (defines props for the AddressFinder component).
 */
interface AddressFinderProps {
  /**
   * 검색 버튼 없이 검색창에 입력한 주소를 autoSearchDebounce 에 지정한 시간 후 자동으로 검색합니다
   * (performs address search automatically after the delay specified by autoSearchDebounce).
   */
  autoSearch?: boolean;
  /**
   * 자동 검색 시 입력 후 검색을 수행하기까지 지연 시간을 설정합니다. autoSearch가 true인 경우에만 동작합니다
   * (sets the delay time for the automatic search. It only works when autoSearch is set to true).
   */
  autoSearchDebounce?: number;
  /**
   * onSelect 검색한 주소 목록에서 주소를 선택하면 호출할 콜백을 지정합니다
   * (sets the callback function that to be invoked when select an address from the list).
   * @param address 선택한 주소
   * @param user 주소를 등록 할 사용자
   */
  onSelect?: (address: Address) => void;
  /**
   * 검색 입력창에 기본적으로 표시할 텍스트를 지정합니다
   * (sets the placeholder text on the search input).
   */
  inputPlaceholder?: string;
}

type AddressLoadingStatus = 'init' | 'loading' | 'loaded' | 'error';

interface AddressFinderState {
  keyword: string;
  loadingStatus: AddressLoadingStatus;
  selectedIndex: number;
  addresses: Address[];
}

const initialProps: AddressFinderProps = {
  autoSearch: false,
  autoSearchDebounce: 0,
  inputPlaceholder: '건물명 검색',
};

const Item = styled(Box)(() => ({
  textAlign: 'center',
}));

class AddressFinderComponent extends Component<AddressFinderProps, AddressFinderState> {
  static defaultProps = initialProps;

  constructor(props: AddressFinderProps) {
    super(props);
    this.state = {
      keyword: '',
      loadingStatus: 'init',
      selectedIndex: -1,
      addresses: [],
    };
  }

  render() {
    return (
      <Container>
        <Stack spacing={1}>
          <Stack direction="row">
            <TextField
              autoFocus
              data-testid="search-input"
              variant="outlined"
              size="small"
              fullWidth={true}
              placeholder={this.props.inputPlaceholder}
              onChange={this.handleInputChange}
            />
            {!this.props.autoSearch && (
              <IconButton color="primary" onClick={() => this.onSearch()} data-testid="search-button">
                <Search />
              </IconButton>
            )}
          </Stack>
          {this.getContent()}
        </Stack>
      </Container>
    );
  }

  handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ keyword: e.target.value });
    this.debounceInput();
  };

  private onSearch(): void {
    const addressService = new AddressSearchService();
    this.setState({ loadingStatus: 'loading' });

    addressService
      .searchAll(this.state.keyword)
      .then((addresses: Address[]) => {
        this.setState({ loadingStatus: 'loaded' });
        this.setState({ addresses });
      })
      .catch((_) => {
        this.setState({ loadingStatus: 'error' });
      });
  }

  private getContent(): JSX.Element {
    switch (this.state.loadingStatus) {
      case 'init':
        return this.getInitialEmptyContent();
      case 'loading':
        return this.getLoadingContent();
      case 'loaded':
        return this.state.addresses.length > 0 ? this.getAddressTableContent() : this.getAddressNotFoundContent();
      case 'error':
        return this.getErrorContent();
      default:
        return <></>;
    }
  }

  private getLoadingContent(): JSX.Element {
    return (
      <Item>
        <AddressSkeleton />
      </Item>
    );
  }

  private getInitialEmptyContent(): JSX.Element {
    return (
      <Item>
        <Typography variant="body1">주소를 검색해 주세요.</Typography>
      </Item>
    );
  }

  private getErrorContent(): JSX.Element {
    return (
      <Item>
        <Typography variant="body1">주소를 검색하지 못했습니다. 잠시 후 다시 시도해 주세요.</Typography>
      </Item>
    );
  }

  private getAddressNotFoundContent(): JSX.Element {
    return (
      <Item>
        <Typography variant="body1">주소를 검색하지 못했습니다. 다른 주소로 다시 검색해 주세요.</Typography>
      </Item>
    );
  }

  private getAddressTableContent(): JSX.Element {
    return (
      <Stack spacing={1}>
        {this.state.addresses.map((addr: Address, index: number) => (
          <Card key={`address-row-${index}`} sx={{ cursor: 'pointer' }}>
            <CardContent
              onClick={() => {
                this.setState({ selectedIndex: index });
                this.props.onSelect ? this.props.onSelect(addr) : this.handleOnSelected(addr);
              }}
            >
              <Stack direction="row" spacing={0.5}>
                <Map color="primary" />
                <Typography>{addressFormatter(addr)}</Typography>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>
    );
  }

  private debounceInput = this.props.autoSearch
    ? debounce(() => this.onSearch(), this.props.autoSearchDebounce || 500)
    : () => {
        /* do nothing */
      };

  private handleOnSelected(_: Address): void {
    /* do nothing */
  }
}

export const AddressFinder = AddressFinderComponent;
export type { AddressFinderProps };
