import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled, Stack, Typography, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { useAppDispatch, useAppSelector } from 'app/hooks';

import { resetLocationsState, selectLocations, setActiveStep } from 'features/locations/locations-slice';
import { usePostComplexMutation, usePostLocationMutation } from 'features/locations/locations-queries';
import { PostComplexPayload, BuildingType } from 'models';

import { FmkDialog, DialogConfig, FmkStepper, ScrollIndicator } from 'components/fds';
import HeaderWithTitle from 'components/shared/layout/HeaderWithTitle';
import { Notification } from 'components/shared/Notification';
import { LoadingIndicator } from 'components/icons';

import Complex from 'pages/locations/Complex';
import Locations from 'pages/locations/Locations';

import { trimAllValues } from 'lib/utils';

// 메인 스타일
const Main = styled('main')(({ theme }) => ({
  paddingTop: '40px',
  backgroundColor: theme.palette.gray['150'],
  marginTop: '80px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const isDone = (buildingType: number, location: any) => {
  const { UNKNOWN, APARTMENT, CAMPUS, OFFICE, SUBWAY, CULTURAL_FACILITIES, OTHER } = BuildingType;

  const locationItem = trimAllValues(location);

  if ([UNKNOWN, APARTMENT, CULTURAL_FACILITIES, OTHER].includes(buildingType)) {
    return !!(locationItem?.installedOn && locationItem?.liftIndex && locationItem?.locationType);
  } else if ([CAMPUS, OFFICE].includes(buildingType)) {
    return !!(locationItem?.installedOn && locationItem?.liftIndex);
  } else if (buildingType === SUBWAY) {
    return !!(locationItem?.installedOn && locationItem?.liftIndex && locationItem?.line);
  }
  return false;
};

const ComplexCreatePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeStep, complex, locations } = useAppSelector(selectLocations);

  const [dialogConfig, setDialogConfig] = useState<DialogConfig | null>(null);
  const [registeredComplexId, setRegisteredComplexId] = useState('');

  const { mutateAsync: postComplex } = usePostComplexMutation();
  const { mutateAsync: postLocation } = usePostLocationMutation();

  const canRegister = () => {
    if (locations.length > 0) {
      // 상세위치 입력을 시작한 경우
      return (
        !!(complex.building && complex.name && complex.type) &&
        locations.every((location: any) => isDone(complex.type, location))
      );
    } else {
      // 기본위치만 입력된 경우
      return !!(complex.building && complex.name && complex.type);
    }
  };

  const onHeaderCancelClick = () => {
    if (complex.name === '' && complex.building === null && complex.type === 0 && locations.length === 0) {
      navigate('/locations');
      return;
    }
    setDialogConfig({
      title: '신규 위치 등록 취소',
      children: (
        <Typography variant="body1">
          작성중인 위치 정보는 저장되지 않습니다. <br />
          신규 위치 등록을 취소하시겠습니까?
        </Typography>
      ),
      okButtonText: '네, 취소할게요',
      onClose: () => setDialogConfig(null),
      onOk: () => {
        navigate('/locations');
      },
    });
  };

  const onRegisterClick = async () => {
    setDialogConfig({
      children: (
        <Stack alignItems={'center'}>
          <LoadingIndicator />
          <Typography variant="subtitle1" sx={{ textAlign: 'center', lineHeight: '1.4' }}>
            신규 위치 정보를 <span style={{ color: '#0080FF' }}>등록 중</span>입니다. <br />
            잠시만 기다려주세요.
          </Typography>
        </Stack>
      ),
    });

    if (locations.length > 0) {
      if (registeredComplexId) {
        // 직전에 등록된 기본위치가 있는상태, 상세위치 등록만 실행
        await postLocation(
          { complexId: registeredComplexId, data: locations.map((location) => trimAllValues(location)) },
          {
            onSettled: () => setDialogConfig(null),
          },
        );
      } else {
        const result = await postComplex(complex as PostComplexPayload);
        if (result) {
          setRegisteredComplexId(result.data.id);
          await postLocation(
            { complexId: result.data.id, data: locations.map((location) => trimAllValues(location)) },
            {
              onSettled: () => setDialogConfig(null),
            },
          );
        } else {
          setDialogConfig(null);
        }
      }
    } else {
      // 기본위치 등록만 실행
      postComplex(complex as PostComplexPayload, {
        onSettled: () => setDialogConfig(null),
      });
    }
  };

  const onStepperClick = (step: number) => {
    if (activeStep === 0 && !!(complex.building && complex.name && complex.type)) {
      dispatch(setActiveStep(step));
    } else if (activeStep === 1) {
      dispatch(setActiveStep(step));
    }
  };

  const canStepperClick = () => {
    if (activeStep === 0 && !!(complex.building && complex.name && complex.type)) {
      return 'pointer';
    } else if (activeStep === 1) {
      return 'pointer';
    }
    return 'not-allow';
  };

  useEffect(() => {
    return () => {
      dispatch(resetLocationsState());
    };
  }, []);

  return (
    <>
      <HeaderWithTitle
        pageTitle="신규 위치 등록"
        adornedStart={
          activeStep === 1 && (
            <Button
              onClick={() => dispatch(setActiveStep(0))}
              variant="text"
              size="large"
              color="gray"
              sx={{ color: 'gray.700' }}
              startIcon={<ArrowBackIosIcon />}
            >
              이전 단계
            </Button>
          )
        }
        adornedEnd={
          <Stack direction={'row'} alignItems="center" spacing={'32px'} justifyContent="flex-end">
            <Button onClick={onHeaderCancelClick} variant="text" size="large" color="gray" sx={{ color: 'gray.700' }}>
              취소
            </Button>
            <Button variant="contained" color="gradient" onClick={onRegisterClick} disabled={!canRegister()}>
              등록하기
            </Button>
          </Stack>
        }
      />
      <div style={{ height: 'calc(100vh - 80px)', backgroundColor: '#F5F5F5' }}>
        <Main>
          <FmkStepper
            activeStep={activeStep}
            stepperClick={onStepperClick}
            steps={['기본 위치 정보 입력', '상세 위치 정보 입력 (선택)']}
            canClick={canStepperClick}
          />
          {activeStep === 0 && <Complex />}
          {activeStep === 1 && <Locations canEmpty />}
          <ScrollIndicator />
        </Main>
      </div>
      <Notification />
      <FmkDialog open={!!dialogConfig} {...dialogConfig} />
    </>
  );
};

export { ComplexCreatePage, isDone, Main };
