import { DataGrid, GridFilterModel, GridRowParams } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { GridInputRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  width: '100%',
  border: 0,
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnHeaders': {
    position: 'sticky',
    top: '165px',
    zIndex: 2,
    color: theme.palette.gray[500],
    borderBottom: `1px solid ${theme.palette.gray[300]}`,
    backgroundColor: theme.palette.gray[100],
  },
  '& .MuiDataGrid-columnHeader': {
    ':focus': {
      outline: 'none',
    },
    ':focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.gray[700],
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-main': {
    overflow: 'unset',
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0!important',
  },
  '& .MuiDataGrid-cell': {
    height: '70px',
    color: theme.palette.gray[800],
    fontWeight: 400,
    borderBottom: `1px solid ${theme.palette.gray[200]}`,
    ':focus': {
      outline: 'none',
    },
    ':focus-within': {
      outline: 'none',
    },
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  '& .MuiCheckbox-root svg': {
    color: theme.palette.gray[300],
  },
  '& .MuiCheckbox-root.Mui-checked svg': {
    color: theme.palette.primary.main,
  },
}));

interface FmkDataGridProps {
  rows: any;
  columns: any;
  onRowClick: (params: GridRowParams) => void;
  rowSelection: GridInputRowSelectionModel;
  onSelectionChange: (newSelection: any) => void;
  filterModel?: GridFilterModel;
}

const PAGE_SIZE = 10;
const FmkDataGrid = ({ rows, columns, onRowClick, rowSelection, onSelectionChange, filterModel }: FmkDataGridProps) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  return (
    <StyledDataGrid
      checkboxSelection
      disableRowSelectionOnClick
      rowSelectionModel={rowSelection}
      onRowSelectionModelChange={onSelectionChange}
      rows={rows}
      columns={columns}
      onRowClick={onRowClick}
      columnHeaderHeight={70}
      getRowHeight={() => 'auto'}
      getEstimatedRowHeight={() => 70}
      autoHeight
      filterModel={filterModel}
      pageSizeOptions={[PAGE_SIZE]}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      slots={{
        noRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            <Typography sx={{ color: 'gray.500', textAlign: 'center' }}>
              등록된 상세 위치가 없습니다
              <br />
              상세 위치를 추가해주세요
            </Typography>
          </Stack>
        ),
      }}
    />
  );
};

export { FmkDataGrid };
