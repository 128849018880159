import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { UserClaims } from '@okta/okta-auth-js';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectUser, setUserProfile } from 'features/user/user-slice';
import { UserProfile } from 'models';
import { LoginPage, PromotionAddPage, PromotionListPage, PromotionUpdatePage } from 'pages';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const AppRouter = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const setUser = (user: UserClaims): void => {
    if (user) {
      const profile: UserProfile = {
        email: user.email || '',
        lastName: user.family_name || '',
        firstName: user.given_name || '',
        locale: user.locale || '',
        timezone: user.zoneinfo || '',
      };
      dispatch(setUserProfile(profile));
    }
  };

  const ProtectedRoute = ({ children }: ProtectedRouteProps): JSX.Element => {
    const oktaContext = useOktaAuth();

    const isTokenExpired = (): boolean => {
      const tokens = oktaContext.oktaAuth.tokenManager.getTokensSync();
      if (!tokens.accessToken) {
        return true;
      }

      const now = new Date();
      const expiryDate = new Date(oktaContext.oktaAuth.tokenManager.getExpireTime(tokens.accessToken) * 1000);
      return now > expiryDate;
    };

    useEffect(() => {
      if (isTokenExpired()) {
        // if a user is not authenticated, try to renew the access token
        oktaContext.oktaAuth.tokenManager.renew('accessToken').catch(() => {
          // if the token is not renewed, redirect to log in UI
          navigate('/login');
        });
      }
    }, [isTokenExpired()]);

    if (!oktaContext?.authState) {
      return <></>;
    } else {
      if (oktaContext.authState.isAuthenticated && !isTokenExpired()) {
        !user.profile && oktaContext.oktaAuth.getUser().then((user: UserClaims) => setUser(user));
        return <>{children}</>;
      } else {
        return <Navigate to="/login" />;
      }
    }
  };

  const onAuthResume = (): void => {
    navigate('/login');
  };

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/promotions" />} />
      <Route path="/promotions" element={<ProtectedRoute children={<PromotionListPage />} />} />
      <Route path="/promotions/add" element={<ProtectedRoute children={<PromotionAddPage />} />} />
      <Route path="/promotions/:promotionId" element={<ProtectedRoute children={<PromotionUpdatePage />} />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/login/callback" element={<LoginCallback onAuthResume={onAuthResume} />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default AppRouter;
