import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';
import { GridColDef, GridFilterModel, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid';

import { BuildingType, ComplexDetailResponse } from 'models';
import { installedOnFormatter, locationTypeFormatter } from 'lib/utils';

import { FmkDataGrid } from 'components/fds/FmkDataGrid';
import { FmkInfoChip } from 'components/fds/FmkInfoChip';
import { AddOutlined } from '@mui/icons-material';
import { FmkTab } from 'components/fds/FmkTab';
import { LocationDetailActions } from 'components/locations/LocationDetailActions';
import { useDeleteLocationsMutation } from 'features/locations/locations-queries';
import { FmkTag } from 'components/fds/FmkTag';

const { APARTMENT, SUBWAY, CULTURAL_FACILITIES, OTHER } = BuildingType;

interface LocationTableProps {
  complexId: string;
  data: ComplexDetailResponse;
  setOpenDrawer: (openDrawer: boolean) => void;
  openDialog: (subject: string, associated: boolean, locationId: string) => void;
  setLocationId: (locationId: string) => void;
}

const LocationTable = ({ complexId, data, setOpenDrawer, openDialog, setLocationId }: LocationTableProps) => {
  const navigate = useNavigate();
  const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([]);
  const defaultLocationAction = { numberOfSelection: 0, canDelete: false, canAssociateProduct: false };
  const [rowSelectionAction, setRowSelectionAction] = useState(defaultLocationAction);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const filterTabLabels = ['전체', '매체 연결필요', '매체 연결완료'];
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const mutation = useDeleteLocationsMutation();

  const buildingType = data?.buildingType;
  const matchedDevicesCount =
    data?.details.reduce((acc: number, crr: any) => (crr.device?.serialNumber ? ++acc : acc), 0) || 0;
  const unmatchedDevicesCount = (data?.details?.length || 0) - matchedDevicesCount;

  const onRowClick = (params: GridRowParams) => {
    data && setLocationId(params.row.id);
    setOpenDrawer(true);
  };

  const onSelectionChange = (newSelection: string[]) => {
    setRowSelection(newSelection);

    if (newSelection.length === 0) {
      setRowSelectionAction(defaultLocationAction);
      setShowActionButtons(false);
      return;
    }

    const isAnyDeviceMapped = data?.details
      .filter((x) => newSelection.includes(x.location.locationId))
      .some((x) => !!x.mappedAt);

    const newLocationAction = {
      numberOfSelection: newSelection.length,
      canDelete: !isAnyDeviceMapped,
      canAssociateProduct: !isAnyDeviceMapped,
    };

    setShowActionButtons(true);
    setRowSelectionAction(newLocationAction);
  };

  const handleClickTab = (label: string) => {
    setFilterModel({
      items:
        label === '전체'
          ? []
          : [
              {
                field: 'device',
                operator: 'contains',
                value: label === '매체 연결필요' ? '연결필요' : '연결완료',
              },
            ],
    });
  };

  const handleCloseActionButtons = () => {
    setShowActionButtons(false);
    setRowSelection([]);
  };

  const handleDeleteLocations = () => {
    mutation.mutate({ complexId, locationIdArray: rowSelection.map((id: any) => id as string) });
    handleCloseActionButtons();
  };

  const columns: GridColDef[] = [
    { field: 'installedOn', headerName: '내/외부', flex: 1 },
    { field: 'sector', headerName: '동', flex: 1 },
    {
      field: 'zone',
      headerName: '구역',
      flex: 1,
    },
    {
      field: 'floor',
      headerName: '층',
      sortable: false,
      flex: 1,
    },
    {
      field: 'liftIndex',
      headerName: '호기',
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.row.liftIndex}
          </div>
        );
      },
    },
    {
      field: 'note',
      headerName: '기타',
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.note}</div>
        );
      },
    },
    {
      field: 'product',
      headerName: '상품 연결여부',
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      flex: 1,
      renderCell: (params) => {
        if (params.row.device === '연결완료') return <FmkInfoChip text={'무슨무슨 상품'} />;
        return (
          <Typography variant="body1" color="gray.500">
            상품 연결 전
          </Typography>
        );
      },
    },
    {
      field: 'device',
      headerName: '매체 연결여부',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) =>
        params.row.device === '연결완료' ? (
          <FmkTag label="연결완료" severity="success" />
        ) : (
          <FmkTag label="연결필요" severity="error" />
        ),
    },
    {
      field: 'mappedAt',
      headerName: '매체상태',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => openDialog('매체', !!params.row.mappedAt, params.row.id)}
            variant={params.row.mappedAt ? 'outlined' : 'contained'}
            color={params.row.mappedAt ? 'gray' : 'secondary'}
            size="small"
            sx={{ margin: 'auto' }}
          >
            {params.row.mappedAt ? '연결해제' : '연결하기'}
          </Button>
        );
      },
    },
  ];

  if ([APARTMENT, CULTURAL_FACILITIES, OTHER].includes(data?.buildingType)) {
    columns.splice(1, 0, { field: 'locationType', headerName: '구분', flex: 1 });
  } else if (data?.buildingType === SUBWAY) {
    columns.splice(0, 4, { field: 'line', headerName: '지하철 호선', flex: 1 });
  }

  const rows = data?.details.map((detail: any) => {
    return {
      id: detail.location.locationId,
      installedOn: installedOnFormatter(detail.location.attributes.installedOn),
      ...([1, 5, 6].includes(data?.buildingType) && {
        locationType: locationTypeFormatter(detail.location.attributes.locationType),
      }),
      ...(buildingType !== SUBWAY && { sector: detail.location.attributes.sector || '-' }),
      ...(buildingType !== SUBWAY && { zone: detail.location.attributes.zone || '-' }),
      ...(buildingType !== SUBWAY && { floor: detail.location.attributes.floor || '-' }),
      ...(buildingType === SUBWAY && { line: detail.location.attributes.line }),
      liftIndex: detail.location.attributes.liftIndex,
      note: detail.location.attributes.note || '-',
      product: detail.device?.serialNumber,
      device: detail.device?.serialNumber ? '연결완료' : '연결필요',
      mappedAt: detail.device?.serialNumber,
    };
  });

  return (
    <>
      <Stack
        direction={'row'}
        sx={{
          position: 'sticky',
          top: '80px',
          width: '100%',
          padding: '24px 0',
          zIndex: 2,
          justifyContent: 'space-between',
          backgroundColor: 'common.white',
        }}
      >
        <Stack direction={'row'} sx={{ gap: '8px' }}>
          <FmkTab
            labels={filterTabLabels}
            subLabels={[data?.details.length, unmatchedDevicesCount, matchedDevicesCount]}
            handleClickTab={handleClickTab}
          />
        </Stack>
        <Button
          variant="outlined"
          color="gray"
          size="small"
          startIcon={<AddOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/complexes/${complexId}/locations`);
          }}
        >
          상세 위치 등록
        </Button>
      </Stack>
      <div style={{ height: 'inherit', width: '100%' }}>
        <FmkDataGrid
          rows={rows}
          columns={columns}
          onRowClick={onRowClick}
          rowSelection={rowSelection}
          onSelectionChange={onSelectionChange}
          filterModel={filterModel}
        />
        <LocationDetailActions
          showActionButtons={showActionButtons}
          rowSelectionAction={rowSelectionAction}
          handleDeleteLocations={handleDeleteLocations}
          handleCloseActionButtons={handleCloseActionButtons}
        />
      </div>
    </>
  );
};

export { LocationTable };
