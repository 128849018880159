import { useEffect } from 'react';

import useWebSocket from 'react-use-websocket';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { showSuccess } from 'features/notification/notification-slice';
import { refreshDeviceDetailsAsync } from 'features/devices/device-editor-thunks';
import { getStatusStatAsync, replaceStatusIndicators } from 'features/devices';
import { getPromotionsAsync, selectPromotions, unsetIsProcessing } from 'features/promotions/promotion-slice';

const WebSocketListener = () => {
  const dispatch = useAppDispatch();
  const { currentPage, keyword } = useAppSelector(selectPromotions);

  const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL || '', {
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const message = JSON.parse(lastJsonMessage.toString());
      switch (message.messageType) {
        case 'com.fmk.eos.devices.events.v1.UpdatedAttributes': // one or more device attributes has been changed (e.g., volume, backlight, etc)
          Promise.all([dispatch(refreshDeviceDetailsAsync(message.body.id)), dispatch(getStatusStatAsync())]).then(
            ([_, action2]) => {
              dispatch(showSuccess({ message: `매체 ${message.body.id}의 상태가 변경되었습니다` }));
              const statusTask = action2 as any;
              statusTask.value && dispatch(replaceStatusIndicators(statusTask.value));
            },
          );
          break;
        case 'com.fmk.eos.devices.events.v1.UpdatedRfSync': // update rfsync info
          dispatch(refreshDeviceDetailsAsync(message.body.id)).then(() =>
            dispatch(showSuccess({ message: `매체 ${message.body.id}의 RF 동기화 정보가 변경되었습니다` })),
          );
          break;
        case 'com.fmk.eos.devices.events.v1.UpdatedLocation': // the location of device has been changed (complex, building, address details, etc)
          dispatch(refreshDeviceDetailsAsync(message.body.id)).then(() =>
            dispatch(showSuccess({ message: `매체 ${message.body.id}의 설치 위치가 변경되었습니다` })),
          );
          break;
        case 'com.fmk.eos.devices.events.v1.InstalledApplications': // new version of apps have been installed
          dispatch(refreshDeviceDetailsAsync(message.body.id)).then(() =>
            dispatch(showSuccess({ message: `매체 ${message.body.id}의 앱이 업데이트되었습니다` })),
          );
          break;
        case 'com.fmk.hestia.promotionHandlers.events.CreatedPromotion': // new promotion have been created
          dispatch(getPromotionsAsync({ page: currentPage, keyword })).then(() =>
            dispatch(showSuccess({ message: '프로모션이 생성됐습니다' })),
          );
          dispatch(unsetIsProcessing());
          break;
        case 'com.fmk.hestia.promotionHandlers.events.DeletedPromotion': // promotion have been deleted
          dispatch(getPromotionsAsync({ page: currentPage, keyword })).then(() =>
            dispatch(showSuccess({ message: '프로모션이 삭제됐습니다' })),
          );
          dispatch(unsetIsProcessing());
          break;
        case 'com.fmk.hestia.promotionHandlers.events.PublishedPromotion': // promotion have been published
          dispatch(getPromotionsAsync({ page: currentPage, keyword })).then(() =>
            dispatch(showSuccess({ message: '프로모션이 라이브에 반영됐습니다' })),
          );
          dispatch(unsetIsProcessing());
          break;
        case 'com.fmk.hestia.promotionHandlers.events.UnpublishedPromotion': // promotion have been unpublished
          dispatch(getPromotionsAsync({ page: currentPage, keyword })).then(() =>
            dispatch(showSuccess({ message: '프로모션 라이브 반영이 취소됐습니다' })),
          );
          dispatch(unsetIsProcessing());
          break;
        case 'com.fmk.hestia.promotionHandlers.events.UpdatedPromotion': // new promotion have been updated
          dispatch(getPromotionsAsync({ page: currentPage, keyword })).then(() =>
            dispatch(showSuccess({ message: '프로모션이 수정됐습니다' })),
          );
          dispatch(unsetIsProcessing());
          break;
      }
    }
  }, [lastJsonMessage]);

  return <></>;
};

export { WebSocketListener };
