import { SvgIcon } from '@mui/material';

interface NotificationIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const NotificationIcon = ({ width = '32px', height = '32px', color = '#949494' }: NotificationIconProps) => {
  return (
    <SvgIcon inheritViewBox={true} sx={{ width, height, color }}>
      <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.66716 25.1537C6.3834 25.1537 6.1458 25.058 5.95436 24.8665C5.76291 24.6751 5.66719 24.4375 5.66719 24.1537C5.66719 23.87 5.76291 23.6324 5.95436 23.4409C6.1458 23.2495 6.3834 23.1537 6.66716 23.1537H8.33386V13.4871C8.33386 11.711 8.88941 10.1191 10.0005 8.71142C11.1116 7.30373 12.5561 6.40331 14.3339 6.01016V5.07682C14.3339 4.60673 14.4941 4.21143 14.8146 3.89092C15.1351 3.57041 15.5304 3.41016 16.0005 3.41016C16.4706 3.41016 16.8659 3.57041 17.1864 3.89092C17.5069 4.21143 17.6671 4.60673 17.6671 5.07682V6.01016C19.4449 6.40331 20.8893 7.30373 22.0005 8.71142C23.1116 10.1191 23.6671 11.711 23.6671 13.4871V23.1537H25.3338C25.6176 23.1537 25.8552 23.2495 26.0466 23.4409C26.2381 23.6324 26.3338 23.87 26.3338 24.1537C26.3338 24.4375 26.2381 24.6751 26.0466 24.8665C25.8552 25.058 25.6176 25.1537 25.3338 25.1537H6.66716ZM16.0005 29.0768C15.3441 29.0768 14.7783 28.8434 14.3031 28.3768C13.8279 27.9101 13.5903 27.34 13.5903 26.6665H18.4107C18.4107 27.34 18.1774 27.9101 17.7107 28.3768C17.2441 28.8434 16.674 29.0768 16.0005 29.0768Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export { NotificationIcon };
