import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DeviceGroup } from 'models';
import { RootState } from '../../app/store';
import { getAll } from './device-group-api';
import { showError } from '../notification/notification-slice';

interface DeviceGroupState {
  items: DeviceGroup[] | undefined;
  loadingStatus: 'idle' | 'loading' | 'failed';
}

const initialState: DeviceGroupState = {
  items: undefined,
  loadingStatus: 'idle',
};

const getDeviceGroupsAsync = createAsyncThunk('device-groups/get-all', async (_, { dispatch }) => {
  try {
    return getAll();
  } catch (error: any) {
    dispatch(showError({ message: '매체 그룹을 로드하지 못했습니다. 잠시 후 다시 시도해 주세요.' }));
    return [];
  }
});

export const deviceGroupSlice = createSlice({
  name: 'deviceGroups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeviceGroupsAsync.pending, (state: DeviceGroupState) => {
        state.loadingStatus = 'loading';
      })
      .addCase(getDeviceGroupsAsync.fulfilled, (state: DeviceGroupState, action) => {
        state.loadingStatus = 'idle';
        state.items = action.payload;
      })
      .addCase(getDeviceGroupsAsync.rejected, (state: DeviceGroupState) => {
        state.loadingStatus = 'failed';
      });
  },
});

const selectDeviceGroups = (state: RootState) => state.deviceGroups;

// export types
export type { DeviceGroupState };

// export async actions
export { getDeviceGroupsAsync };
// export selectors
export { selectDeviceGroups };

export default deviceGroupSlice.reducer;
