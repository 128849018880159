import { RootState } from 'app/store';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { MoisBuilding, CreateComplexLocationPayload } from 'models';

interface LocationWithStatus extends CreateComplexLocationPayload {
  openStatus?: boolean;
}

interface LocationState {
  activeStep: number;
  complex: {
    name: string; // 위치명
    building: MoisBuilding | null; // 주소
    type: number; // BuildingType
  };
  locationToShow: number;
  locations: LocationWithStatus[]; // 상세위치
  originalData: {
    complex: {
      name: string; // 위치명
      building: any; // 주소
      type: number; // BuildingType
    };
    locations: LocationWithStatus[];
  };
}

const initialState: LocationState = {
  activeStep: 0,
  complex: {
    name: '',
    building: null,
    type: 0,
  },
  locationToShow: 0,
  locations: [],
  originalData: {
    complex: {
      name: '',
      building: null,
      type: 0,
    },
    locations: [],
  },
};

export const initialLocationState: CreateComplexLocationPayload = {
  installedOn: 0,
  locationType: 0,
  sector: '',
  zone: '',
  floor: '',
  liftIndex: '',
  line: '',
  note: '',
};

const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setActiveStep: (state: Draft<LocationState>, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    setName: (state: Draft<LocationState>, action: PayloadAction<string>) => {
      state.complex.name = action.payload;
    },
    setBuilding: (state: Draft<LocationState>, action: PayloadAction<MoisBuilding | null>) => {
      state.complex.building = action.payload;
    },
    setType: (state: Draft<LocationState>, action: PayloadAction<number>) => {
      state.complex.type = action.payload;
    },
    resetLocationsState: (state: Draft<LocationState>) => {
      Object.assign(state, initialState);
    },
    setLocation: (state: Draft<LocationState>, action: PayloadAction<any>) => {
      state.locations = [{ ...action.payload, openStatus: true }];
    },
    setLocations: (state: Draft<LocationState>, action: PayloadAction<any>) => {
      state.locations = state.locations?.map((v, i) => {
        return action.payload.index === i ? { ...v, ...action.payload.item } : v;
      });
    },
    addLocationForm: (state: Draft<LocationState>, action: PayloadAction<number>) => {
      const addCount = state.locations.length + action.payload > 30 ? 30 - state.locations.length : action.payload;
      const added = Array.from({ length: addCount }, (_, i) => {
        return state.locations.length === 0
          ? {
              ...initialLocationState,
              openStatus: i === 0,
            }
          : {
              ...state.locations[state.locations.length - 1],
              openStatus: i === 0,
            };
      });

      state.locations = state.locations.concat(added);
    },
    removeLocation: (state: Draft<LocationState>, action: PayloadAction<number>) => {
      state.locations = state.locations.filter((_, index) => index !== action.payload);
    },
    toggleOpenStatus: (state: Draft<LocationState>, action: PayloadAction<number>) => {
      state.locations = state.locations.map((v, i) => (i === action.payload ? { ...v, openStatus: !v.openStatus } : v));
    },
    setOpenStatusAll: (state: Draft<LocationState>, action: PayloadAction<boolean>) => {
      state.locations = state.locations.map((v) => {
        return { ...v, openStatus: action.payload };
      });
    },
    resetLocations: (state: Draft<LocationState>) => {
      state.locations = [];
    },
    setOriginalData: (state: Draft<LocationState>, action: PayloadAction<any>) => {
      state.originalData.complex = action.payload.complex;
      if (action.payload.locations) {
        state.originalData.locations = action.payload.locations;
      }
    },
  },
});

export const selectLocations = (root: RootState) => root.locations;
export const {
  setActiveStep,
  setName,
  setBuilding,
  setType,
  resetLocationsState, // locations state 전체 상태 초기화
  setLocation, // 상세 위치 수정
  setLocations, // 상세위치 등록
  addLocationForm, // 상세위치 입력 폼 추가
  removeLocation, // 상세위치 삭제
  toggleOpenStatus,
  setOpenStatusAll,
  resetLocations, // 상세위치 초기화
  setOriginalData,
} = locationSlice.actions;
export type { LocationState };
export default locationSlice.reducer;
