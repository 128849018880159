import RestApiClient from 'lib/rest-api-client';
import { DeviceListViewItem } from 'models/device';

import { DeviceLocationStat, DeviceStatusStat, DeviceTypesStat, SearchOption } from './devices-thunks';
import axios, { CanceledError, CancelTokenSource } from 'axios';
import { DeviceSearchResult } from './devices-slice';

let deviceSearchCancelToken: CancelTokenSource | undefined;
const deviceSearchCancelMessage = 'devices-search-cancelled';

const restApiClient = new RestApiClient({
  baseUrl: process.env.REACT_APP_MEDIA_DEVICES_API_URL || '',
  timeout: 10 * 1000,
});

const getProvincesStat = async (): Promise<DeviceLocationStat[] | undefined> => {
  try {
    const response = await restApiClient.get('/devices/stat/provinces');
    return response.data as DeviceLocationStat[];
  } catch (e) {
    return undefined;
  }
};

const getTypesStat = async (): Promise<DeviceTypesStat[] | undefined> => {
  try {
    const response = await restApiClient.get('/devices/stat/types');
    return response.data as DeviceTypesStat[];
  } catch (e) {
    return undefined;
  }
};

const getStatusStat = async (): Promise<DeviceStatusStat[] | undefined> => {
  try {
    const response = await restApiClient.get('/devices/stat/status');
    return response.data as DeviceStatusStat[];
  } catch (e) {
    return undefined;
  }
};

const searchDevices = async (option: SearchOption | undefined): Promise<DeviceSearchResult> => {
  try {
    if (deviceSearchCancelToken) {
      deviceSearchCancelToken.cancel(deviceSearchCancelMessage);
    }
    deviceSearchCancelToken = axios.CancelToken.source();

    const response = option
      ? await restApiClient.get('/devices', { params: option, cancelToken: deviceSearchCancelToken.token })
      : await restApiClient.get('/devices', { cancelToken: deviceSearchCancelToken.token });
    return {
      status: 'ok',
      devices: (response.data || []) as DeviceListViewItem[],
    };
  } catch (e) {
    if (e instanceof CanceledError && e.message === deviceSearchCancelMessage) {
      return {
        status: 'cancelled',
        devices: [],
      };
    }
    return Promise.reject(e);
  }
};

export { getProvincesStat, getStatusStat, getTypesStat, searchDevices };
