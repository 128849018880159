import { Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, Stepper, styled } from '@mui/material';

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.gray[400],
    width: '48px',
  },
}));

const CustomStepIcon = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  width: '24px',
  height: '24px',
  textAlign: 'center',
  borderRadius: '100px',
  lineHeight: '24px',
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.gray[400]}`,
  color: theme.palette.gray[400],
  ...theme.typography.body2,
  ...(ownerState.active && {
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.gray[800]}`,
    backgroundColor: theme.palette.gray[800],
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, className, icon } = props;

  return (
    <CustomStepIcon ownerState={{ active }} className={className}>
      {icon}
    </CustomStepIcon>
  );
}

export const FmkStepper = ({
  activeStep,
  steps,
  stepperClick,
  canClick,
}: {
  activeStep: number;
  steps: string[];
  stepperClick: (index: number) => void;
  canClick: () => string;
}) => {
  return (
    <Stepper nonLinear sx={{ marginBottom: '24px' }} activeStep={activeStep} connector={<CustomStepConnector />}>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: {
          optional?: React.ReactNode;
        } = {};
        return (
          <Step key={index} {...stepProps} onClick={() => stepperClick(index)}>
            <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon} sx={{ cursor: canClick() }}>
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
