import { SvgIcon } from '@mui/material';

interface MenuIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const MenuIcon = ({ width = '32px', height = '32px', color = '#FFFFFF' }: MenuIconProps) => {
  return (
    <SvgIcon inheritViewBox={true} sx={{ width, height, color }}>
      <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.99997 22.6779C4.71663 22.6779 4.47914 22.597 4.2875 22.4352C4.09583 22.2734 4 22.0729 4 21.8337C4 21.5946 4.09583 21.3942 4.2875 21.2327C4.47914 21.0711 4.71663 20.9904 4.99997 20.9904H26.3333C26.6166 20.9904 26.8541 21.0713 27.0458 21.2331C27.2374 21.3948 27.3333 21.5953 27.3333 21.8345C27.3333 22.0737 27.2374 22.274 27.0458 22.4355C26.8541 22.5971 26.6166 22.6779 26.3333 22.6779H4.99997ZM4.99997 17.1826C4.71663 17.1826 4.47914 17.1018 4.2875 16.94C4.09583 16.7782 4 16.5777 4 16.3386C4 16.0994 4.09583 15.899 4.2875 15.7375C4.47914 15.576 4.71663 15.4952 4.99997 15.4952H26.3333C26.6166 15.4952 26.8541 15.5761 27.0458 15.7379C27.2374 15.8997 27.3333 16.1001 27.3333 16.3393C27.3333 16.5785 27.2374 16.7788 27.0458 16.9404C26.8541 17.1019 26.6166 17.1826 26.3333 17.1826H4.99997ZM4.99997 11.6875C4.71663 11.6875 4.47914 11.6066 4.2875 11.4448C4.09583 11.283 4 11.0825 4 10.8434C4 10.6042 4.09583 10.4039 4.2875 10.2423C4.47914 10.0808 4.71663 10 4.99997 10H26.3333C26.6166 10 26.8541 10.0809 27.0458 10.2427C27.2374 10.4045 27.3333 10.605 27.3333 10.8441C27.3333 11.0833 27.2374 11.2836 27.0458 11.4452C26.8541 11.6067 26.6166 11.6875 26.3333 11.6875H4.99997Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export { MenuIcon };
