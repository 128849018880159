import { useNavigate, useLocation } from 'react-router-dom';

import { List, ListItem, ListItemButton, ListItemText, Box } from '@mui/material';

import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectNavigationOpen, setNavigationOpen } from 'features/navigation/navigation-slice';
import { useState } from 'react';
import { ArrowUpIcon, ArrowDownIcon, ScheduleIcon } from 'components/icons';

const hoverColor = '#00E16D';

interface SubMenu {
  title: string;
  path: string;
  show: boolean;
}

interface NavigationMenu {
  title: string;
  path?: string;
  icon: (props?: any) => JSX.Element;
  show: boolean;
  subMenus?: SubMenu[];
}

const NavigationDrawerList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navigationOpen = useAppSelector(selectNavigationOpen);
  const dispatch = useAppDispatch();

  const menus: NavigationMenu[] = [
    {
      title: '프로모션',
      icon: (props: any) => <ScheduleIcon {...props} />,
      show: true,
      subMenus: [{ title: '프로모션 관리', path: '/promotions', show: true }],
    },
  ].filter((menu: NavigationMenu) => menu.show);

  const currentPath = '/' + pathname.split('/')[1];
  const selectedIndex = menus.findIndex(
    (menu) => menu.path === currentPath || menu.subMenus?.find((subMenu) => subMenu.path === currentPath),
  );
  const [menuToToggle, setToggle] = useState(selectedIndex);
  const [menuHovered, setHover] = useState(-1);

  const handleClickMenu = (path: string) => {
    dispatch(setNavigationOpen(true));
    navigate(path);
  };

  return (
    <List sx={{ padding: 0, overflow: 'visible' }}>
      {/* 토글 형식 상위 메뉴 */}
      {menus.map((menu: NavigationMenu, mainIdx) => (
        <ListItem disablePadding sx={{ display: 'block' }} key={menu.title}>
          <ListItemButton
            onClick={() => {
              menu.path ? handleClickMenu(menu.path) : setToggle(mainIdx);
            }}
            onMouseOver={() => !navigationOpen && setHover(mainIdx)}
            onMouseLeave={() => !navigationOpen && setHover(-1)}
            sx={{
              flexDirection: navigationOpen ? 'row' : 'column',
              justifyContent: 'center',
              gap: '8px',
              height: navigationOpen ? '48px' : '110px',
              padding: '0 32px',
              background:
                mainIdx === selectedIndex
                  ? 'linear-gradient(180deg, rgba(0, 44, 89, 0.4) 0%, rgba(0, 44, 89, 0) 131.73%)'
                  : 'none',
              '&:hover': { color: hoverColor, '& path': { fill: hoverColor } },
            }}
          >
            {menu.icon({ width: navigationOpen ? '20px' : '32px', height: navigationOpen ? '20px' : '32px' })}
            <ListItemText
              disableTypography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                flexGrow: navigationOpen ? 1 : 'unset',
              }}
            >
              {menu.title}
            </ListItemText>
            {/* 2depth 메뉴인 경우에만 토글 아이콘을 노출함 */}
            {menu.subMenus &&
              (mainIdx === menuToToggle ? (
                <ArrowUpIcon display={navigationOpen ? 'block' : 'none'} />
              ) : (
                <ArrowDownIcon display={navigationOpen ? 'block' : 'none'} />
              ))}
          </ListItemButton>
          {/* 서브 메뉴 리스트 */}
          {menu.subMenus && (
            <List
              onMouseOver={() => !navigationOpen && setHover(mainIdx)}
              onMouseLeave={() => !navigationOpen && setHover(-1)}
              sx={{
                display: mainIdx === menuToToggle ? 'block' : 'none',
                padding: '12px 0',
                backgroundColor: '#002C59',
                ...(!navigationOpen && {
                  display: mainIdx === menuHovered ? 'block' : 'none',
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  padding: '10px 12px',
                  borderRadius: '0px 24px 24px 0px',
                  transform: 'translateX(100%)',
                }),
              }}
            >
              {menu.subMenus.map((subMenu) => (
                <ListItem component={'div'} sx={{ display: 'block' }} key={subMenu.title} disablePadding>
                  <ListItemButton
                    onClick={() => handleClickMenu(subMenu.path)}
                    sx={{
                      padding: '0',
                      margin: navigationOpen ? '0' : '10px 0',
                      minHeight: 48,
                      width: navigationOpen ? '100%' : '136px',
                      '&:hover': { color: hoverColor },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '48px',
                        width: navigationOpen ? '244px' : '136px',
                        borderRadius: navigationOpen ? ' 0px 100px 100px 0px' : '100px',
                        background: subMenu.path === currentPath ? (theme) => theme.palette.gradient.dark : 'none',
                      }}
                    >
                      <ListItemText
                        disableTypography
                        sx={{
                          ml: navigationOpen ? 8 : 3,
                          fontSize: '14px',
                          fontWeight: '500',
                        }}
                      >
                        {subMenu.title}
                      </ListItemText>
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export { NavigationDrawerList };
