import React from 'react';

import { AppBar, Box, Toolbar, styled } from '@mui/material';

import Header from './Header';
import { NavigationDrawer } from 'components/shared/layout/NavigationDrawer';
import { Notification } from 'components/shared/Notification';

interface LayoutProps {
  hideAppBar?: boolean;
  children: React.ReactNode;
  appBarChildren?: React.ReactNode;
}

const Main = styled('main')(() => ({
  marginTop: '80px',
  width: '100%',
}));

export default function Layout({ hideAppBar, children, appBarChildren }: LayoutProps) {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.3/dist/leaflet.css"
        integrity="sha256-kLaT2GOSpHechhsozzB+flnD+zUyjE2LlfWPgU04xyI="
        crossOrigin=""
      />
      <Box sx={{ display: 'flex' }}>
        {!hideAppBar && <NavigationDrawer />}
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          {!hideAppBar && (
            <AppBar
              position="fixed"
              elevation={0}
              sx={{
                left: 0,
                justifyContent: 'center',
                height: '80px',
                backgroundColor: 'common.white',
                borderBottom: '1px solid',
                borderColor: 'gray.200',
              }}
            >
              <Toolbar sx={{ paddingLeft: '0px !important' }}>
                <Header children={appBarChildren} />
              </Toolbar>
            </AppBar>
          )}
          <Main sx={{ height: 'calc(100vh - 80px)' }}>{children}</Main>
        </Box>
      </Box>
      <Notification />
    </>
  );
}
