import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const LoadingIndicator = () => {
  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography variant="subtitle1">
                  실행하신 내용을 서버에 요청하고 있습니다. 완료 메시지가 나타날 때 까지 기다려주세요.
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export { LoadingIndicator };
