import { ChangeEvent, useEffect, useState } from 'react';
import Layout from 'components/shared/layout/Layout';
import { useInput } from 'lib/utils';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { getS3PreSignedUrl } from 'features/promotions/promotion-api';
import {
  NewPromotionPayload,
  postPromotionAsync,
  ProductList,
  selectPromotions,
} from 'features/promotions/promotion-slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import axios from 'axios';
import moment from 'moment/moment';
import { v4 } from 'uuid';
import { FileInput, RequiredMark, Item } from 'components/promotions-form';

const START_DATE = moment(new Date().setHours(5, 0)).format('YYYY-MM-DDTHH:mm');
const END_DATE = moment(new Date().setHours(23, 55)).format('YYYY-MM-DDTHH:mm');

const BANNER_FILE_SIZE_MAX_LIMIT = 9 * 100 * 1024;
const MAX_NAME_INPUT = 50;
const MAX_TEXT_INPUT = 11;
const CSV_FILE_NAME = '분기페이지_배너_노출_단지_리스트.csv';

const s3Upload = async (file: File, uuid: string) => {
  const path = `${uuid}/${file.name}`;
  const { data } = await getS3PreSignedUrl(path);

  // S3 upload 할 때 Authorization 값을 제거해야 정상적으로 업로드 됩니다.
  delete axios.defaults.headers.common.Authorization;
  await axios.put(data.signedUrl, file);

  return path;
};

const PromotionAddPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isProcessing } = useAppSelector(selectPromotions);

  const [eventName, setEventName] = useInput('', (value) => value.length <= MAX_NAME_INPUT);
  const [bannerFile, setBannerFile] = useState<File>();
  const [bannerText, setBannerText] = useState(['', '']);
  const [landingUrl, setLandingUrl] = useInput('');
  const [timeFrame, setTimeFrame] = useState({ startDate: START_DATE, endDate: END_DATE });
  const [buildingListFile, setBuildingListFile] = useState<File>();
  const [displayLocationType, setDisplayLocationType] = useInput('products');
  const [note, setNote] = useInput('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [displayOrder, setDisplayOrder] = useState<number | undefined>(undefined);
  const [isRegularMember, setRegularMember] = useState<boolean>(false);
  const [productList, setProductList] = useState([
    {
      code: 'S',
      package: '1',
      name: '서울생활권 H1',
      selected: false,
    },
    {
      code: 'S',
      package: '2',
      name: '서울생활권 H2',
      selected: false,
    },
    {
      code: 'G',
      package: '0',
      name: '지역생활권',
      selected: false,
    },
    {
      code: 'B',
      package: '0',
      name: '부산생활권',
      selected: false,
    },
  ]);

  useEffect(() => {
    setButtonDisabled(isFormIncomplete());
  }, [eventName, bannerFile, bannerText, landingUrl, timeFrame, displayLocationType]);

  useEffect(() => {
    if (isProcessing) {
      navigate('/promotions');
    }
  }, [isProcessing]);

  const isFormIncomplete = () => {
    return (
      eventName.length === 0 ||
      bannerFile === undefined ||
      landingUrl.length === 0 ||
      bannerText[0].length === 0 ||
      bannerText[1].length === 0 ||
      displayLocationType.length === 0
    );
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeFrame({ ...timeFrame, [e.target.id]: e.target.value });
  };

  const handleSeoulAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setProductList(
      productList.map((product) => {
        if (product.code !== 'S') {
          return product;
        }
        return { ...product, selected: event.target.checked };
      }),
    );
  };

  const handleProductListChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProductList(
      productList.map((product) => {
        if (product.code + product.package === event.target.name) {
          return { ...product, selected: event.target.checked };
        } else {
          return product;
        }
      }),
    );
  };

  const handleBannerText = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number): void => {
    if (event.target.value.length > MAX_TEXT_INPUT) return;

    const newBannerText = [...bannerText];
    newBannerText[index] = event.target.value;
    setBannerText(newBannerText);
  };

  const handleBannerFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const file = (target.files as FileList)[0];

    if (file === undefined) return;

    // 파일 타입 검증
    if (!file.type.startsWith('image/')) {
      target.value = '';
      return alert('이미지만 업로드할 수 있습니다.');
    }
    // 파일 크기 검증
    if (file.size > BANNER_FILE_SIZE_MAX_LIMIT) {
      target.value = '';
      return alert('업로드 가능한 최대 용량은 900KB입니다.');
    }

    setBannerFile(file);
  };

  const handleBuildingListFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const file = (target.files as FileList)[0];

    // 파일 타입 검증
    if (file.type !== 'text/csv') {
      target.value = '';
      return alert('.csv 파일만 업로드할 수 있습니다.');
    }

    setBuildingListFile(file);
  };

  const isValidHttpUrl = (string: string) => {
    try {
      const url = new URL(string);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
      return false;
    }
  };

  const isValidSubmitData = () => {
    if (!isValidHttpUrl(landingUrl)) {
      return alert('랜딩 URL을 입력해주세요\nhttp:// 또는 https://로 시작해야합니다');
    } else if (timeFrame.startDate > timeFrame.endDate) {
      return alert('노출 시작 시간이 종료 시간 보다 늦습니다\n시작 시간을 종료 시간 이전으로 선택해 주세요');
    } else if (displayLocationType === 'products' && !productList.find((product) => product.selected)) {
      return alert('노출 위치의 상품을 선택해주세요');
    } else if (displayLocationType === 'building' && !buildingListFile) {
      return alert('개별단지 파일을 업로드해주세요');
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!isValidSubmitData()) return;
    setButtonDisabled(true);
    const uuid = v4();

    const bannerUrl = await s3Upload(bannerFile!, uuid);

    // 선택한 상품만 리턴할 수 있도록 처리힙니다.
    let products: ProductList[] = [];
    let complexFilePath = '';

    switch (displayLocationType) {
      case 'building':
        complexFilePath = await s3Upload(buildingListFile!, uuid);
        break;
      case 'products': {
        products = productList
          .filter((product) => product.selected)
          .map((product) => ({ code: product.code, packageNumber: product.package }));

        break;
      }
    }

    // DB에는 UTC 시간을 저장하기로 했기 때문에 LocalDateTime을 UTC로 변환하여 BE로 보냅니다.
    timeFrame.startDate = new Date(timeFrame.startDate).toISOString();
    timeFrame.endDate = new Date(timeFrame.endDate).toISOString();

    const newPromotion: NewPromotionPayload = {
      name: eventName.trim(),
      bannerUrl,
      bannerText: [bannerText[0], bannerText[1]],
      landingPageUrl: landingUrl.trim(),
      timeFrame,
      complexListUploadedFilePath: displayLocationType === 'products' ? undefined : complexFilePath,
      productList: displayLocationType === 'products' ? products : undefined,
      displayOrder,
      note,
      isRegularMember,
    };

    dispatch(postPromotionAsync(newPromotion));
  };

  const generateCsv = () => {
    const csvContent = 'building_id\n\n';
    const downloadUrl = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv;encoding:utf-8' }));

    const anchor = document.createElement('a');
    anchor.href = downloadUrl;
    anchor.setAttribute('download', CSV_FILE_NAME);
    anchor.click();
  };

  const handleDisplayOrder = (event: SelectChangeEvent<string>) => {
    setDisplayOrder(Number(event.target.value) || undefined);
  };

  const handleRegularMember = (event: ChangeEvent<HTMLInputElement>) => {
    setRegularMember(event.target.checked);
  };

  return (
    <Layout>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
            <Item sx={{ width: '50%' }}>
              <Typography variant="body1">
                이벤트 이름 <RequiredMark />
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="글자수 제한 : 빈 칸 포함 50자까지"
                onChange={setEventName}
                value={eventName}
              />
            </Item>
            <Item>
              <Typography variant="body1">
                배너 이미지 <RequiredMark />
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="body1">{bannerFile?.name}</Typography>
                <label htmlFor="bannerImg">
                  <FileInput>배너 이미지 업로드( 정사각형 비율, 최대 용량 900KB)</FileInput>
                </label>
                <input
                  type="file"
                  onChange={handleBannerFileChange}
                  accept="image/*"
                  name="bannerImg"
                  id="bannerImg"
                  style={{ display: 'none' }}
                />
              </Stack>
            </Item>
            <Item sx={{ width: '50%' }}>
              <Stack direction="column" spacing={2}>
                <Typography variant="body1">
                  배너 텍스트 <RequiredMark />
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="첫 번째 줄 / 글자수 제한 : 빈 칸 포함 11자까지"
                  onChange={(e) => handleBannerText(e, 0)}
                  value={bannerText[0]}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="두 번째 줄 / 글자수 제한 : 빈 칸 포함 11자까지"
                  onChange={(e) => handleBannerText(e, 1)}
                  value={bannerText[1]}
                />
              </Stack>
            </Item>
            <Item sx={{ width: '50%' }}>
              <Typography variant="body1">
                랜딩 URL <RequiredMark />
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="랜딩 URL"
                onChange={setLandingUrl}
                value={landingUrl}
              />
            </Item>
            <FormControlLabel
              control={<Checkbox checked={isRegularMember} onChange={handleRegularMember} name="isRegularMemeber" />}
              label="정회원 처리"
            />
            <Item sx={{ width: '50%' }}>
              <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                <Item>
                  <Typography variant="body1">
                    노출 시작 일시 <RequiredMark />
                  </Typography>
                  <TextField
                    type="datetime-local"
                    id="startDate"
                    variant="outlined"
                    onChange={handleDateChange}
                    value={timeFrame.startDate}
                    inputProps={{ max: timeFrame.endDate }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Item>
                <Item>
                  <Typography variant="body1">
                    노출 종료 일시 <RequiredMark />
                  </Typography>
                  <TextField
                    type="datetime-local"
                    id="endDate"
                    variant="outlined"
                    onChange={handleDateChange}
                    value={timeFrame.endDate}
                    inputProps={{ min: timeFrame.startDate }}
                  />
                </Item>
              </Stack>
            </Item>

            <Item>
              <Typography variant="body1">노출 순서</Typography>
              <Select value={String(displayOrder)} onChange={handleDisplayOrder}>
                <MenuItem key={0} value={''}>
                  선택안함
                </MenuItem>
                {Array.from({ length: 10 }, (_, index) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
            </Item>

            <Item sx={{ width: '50%' }}>
              <Typography variant="body1">
                노출 위치 <RequiredMark />
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={displayLocationType}
                  onChange={setDisplayLocationType}
                >
                  <FormControlLabel value="products" control={<Radio />} label="상품 선택" />
                  <FormControlLabel value="building" control={<Radio />} label="개별 단지" />
                </RadioGroup>
              </FormControl>
              {displayLocationType === 'products' ? (
                <Item>
                  {
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={productList[0].selected && productList[1].selected}
                              onChange={handleSeoulAllClick}
                              name="seoulAll"
                            />
                          }
                          label="서울생활권 전체"
                        />
                        {productList.map((product) => (
                          <div key={product.code + product.package}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={product.selected}
                                  onChange={handleProductListChange}
                                  name={product.code + product.package}
                                />
                              }
                              label={product.name}
                            />
                          </div>
                        ))}
                      </FormGroup>
                    </FormControl>
                  }
                </Item>
              ) : (
                <Item>
                  <Stack direction="row" alignItems="center" spacing={3}>
                    <Typography variant="body2">{buildingListFile?.name}</Typography>
                    <label htmlFor="buildingExcel">
                      <FileInput>빌딩 아이디 CSV 파일 업로드</FileInput>
                    </label>
                    <input
                      type="file"
                      onChange={handleBuildingListFile}
                      name="buildingExcel"
                      id="buildingExcel"
                      accept=".csv"
                      style={{ display: 'none' }}
                    />
                    <Button onClick={generateCsv}>CSV 포맷 다운로드</Button>
                  </Stack>
                </Item>
              )}
            </Item>
            <Item sx={{ width: '50%' }}>
              <Typography variant="body1">특이사항</Typography>
              <TextField multiline fullWidth variant="outlined" onChange={setNote} value={note} />
            </Item>
          </Stack>
        </CardContent>
      </Card>
      <Item sx={{ marginTop: 4 }}>
        <Button variant="outlined" onClick={() => navigate(-1)}>
          취소
        </Button>
        <Button sx={{ m: 1 }} variant="contained" onClick={() => handleSubmit()} disabled={buttonDisabled}>
          배너 등록
        </Button>
      </Item>
    </Layout>
  );
};

export { PromotionAddPage };
