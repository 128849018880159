import { ActionReducerMapBuilder, createAsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { GetPromotionsPayload, GetPromotionsResponse, Promotion, PromotionDetails } from 'models';

import {
  getPromotion,
  getPromotionList,
  patchPublish,
  putPromotion,
  postPromotion,
  deletePromotion,
} from './promotion-api';
import { showError, showSuccess } from 'features/notification/notification-slice';

interface ProductList {
  code: string;
  packageNumber: string;
}

interface NewPromotionPayload {
  name: string;
  bannerUrl: string;
  bannerText: string[];
  landingPageUrl: string;
  timeFrame: { startDate: string; endDate: string };
  complexListUploadedFilePath?: string;
  productList?: ProductList[];
  displayOrder?: number;
  note: string;
  isRegularMember: boolean;
}

interface Banner {
  id: string;
  imageUrl: string;
  published: boolean;
  text: string[];
}

interface DisplayLocation {
  productList?: ProductList[];
  complexListUploadedFilePath?: string;
}

interface UpdatePromotionPayload {
  id: string;
  name: string;
  banner: Banner;
  landingPageUrl: string;
  timeFrame: { startDate: string; endDate: string };
  location: DisplayLocation;
  displayOrder?: number;
  note: string;
  isRegularMember: boolean;
}

interface PublishPromotionPayload {
  promotionId: string;
  isPublished: boolean;
}

interface PromotionState {
  currentPage: number;
  totalItems: number;
  items: Promotion[];
  keyword?: string;
  promotion: PromotionDetails | undefined;
  loadingStatus: 'idle' | 'loading' | 'failed';
  isProcessing: boolean;
}

const initialState: PromotionState = {
  currentPage: 0,
  totalItems: 0,
  items: [],
  keyword: '',
  promotion: undefined,
  loadingStatus: 'idle',
  isProcessing: false,
};

const convertProductCode = (product: string) => {
  return product
    .replace(/\(S,0\)/g, '서울생활권 전체')
    .replace(/\(S,1\)/g, '서울 half 1')
    .replace(/\(S,2\)/g, '서울 half 2')
    .replace(/\(G,0\)/g, '지역생활권')
    .replace(/\(B,0\)/g, '부산생활권')
    .replace(/,/g, ', ');
};

const getPromotionAsync = createAsyncThunk(
  'promotions/get-one',
  async (promotionId: string, { dispatch }): Promise<PromotionDetails> => {
    try {
      const response = await getPromotion(promotionId);
      return response.data;
    } catch (error: any) {
      dispatch(showError({ message: '프로모션 조회 중 오류가 발생했습니다' }));
      return Promise.reject(error);
    }
  },
);

const getPromotionsAsync = createAsyncThunk(
  'promotions/get-all',
  async (requestPayload: GetPromotionsPayload, { dispatch }): Promise<GetPromotionsActionPayload> => {
    try {
      const { data } = await getPromotionList(requestPayload.page, requestPayload?.keyword);
      return {
        totalItems: data.total,
        items: data.promotionList,
        currentPage: requestPayload.page,
      };
    } catch (error: any) {
      dispatch(showError({ message: '프로모션을 로드하지 못했습니다. 잠시 후 다시 시도해 주세요.' }));
      return Promise.reject(error);
    }
  },
);

const postPromotionAsync = createAsyncThunk('promotion/post', async (payload: NewPromotionPayload, { dispatch }) => {
  try {
    const response = await postPromotion(payload);
    dispatch(
      showSuccess({
        message: '배너 등록 반영까지 최대 1~2분이 소요될 수 있습니다. 잠시만 기다려주세요.',
        duration: 6000,
      }),
    );
    return response.data;
  } catch (error: any) {
    dispatch(showError({ message: error?.response?.message || '프로모션 등록을 요청하는 도중 오류가 발생했습니다' }));
    return Promise.reject(error);
  }
});

const putPromotionAsync = createAsyncThunk('promotion/put', async (payload: UpdatePromotionPayload, { dispatch }) => {
  try {
    const response = await putPromotion(payload, payload.id);
    dispatch(
      showSuccess({
        message: '배너 수정 반영까지 최대 1~2분이 소요될 수 있습니다. 잠시만 기다려주세요.',
        duration: 6000,
      }),
    );
    return response.data;
  } catch (error: any) {
    dispatch(showError({ message: error?.response.message || '프로모션 변경을 요청하는 도중 오류가 발생했습니다' }));
    return Promise.reject(error);
  }
});

const deletePromotionAsync = createAsyncThunk('promotion/delete', async (promotionId: string, { dispatch }) => {
  try {
    const response = await deletePromotion(promotionId);
    dispatch(
      showSuccess({
        message: '배너 삭제 요청 반영까지 최대 1~2분이 소요될 수 있습니다. 잠시만 기다려주세요.',
        duration: 6000,
      }),
    );
    return response.data;
  } catch (error: any) {
    dispatch(showError({ message: error?.response.message || '프로모션 삭제를 요청하는 도중 오류가 발생했습니다' }));
    return Promise.reject(error);
  }
});

const patchPromotionAsync = createAsyncThunk(
  'promotion/patch',
  async (payload: PublishPromotionPayload, { dispatch }) => {
    try {
      const response = await patchPublish(payload.promotionId, payload.isPublished);
      dispatch(
        showSuccess({
          message: 'Live 반영까지 최대 1~2분이 소요될 수 있습니다. 잠시만 기다려주세요.',
          duration: 6000,
        }),
      );
      return response.data;
    } catch (error: any) {
      dispatch(showError({ message: error?.response?.message || 'Live 반영 요청에 실패했습니다.' }));
      return Promise.reject(error);
    }
  },
);

interface GetPromotionsActionPayload extends GetPromotionsResponse {
  currentPage: number;
}

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    searchPromotionName: (state: Draft<PromotionState>, action: PayloadAction<string>) => {
      state.keyword = action.payload;
      state.currentPage = initialState.currentPage;
    },
    resetKeyword: (state: Draft<PromotionState>) => {
      state.keyword = initialState.keyword;
    },
    unsetIsProcessing: (state: Draft<PromotionState>) => {
      state.isProcessing = initialState.isProcessing;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<PromotionState>) => {
    builder
      .addCase(getPromotionsAsync.pending, (state: PromotionState) => {
        state.loadingStatus = 'loading';
      })
      .addCase(
        getPromotionsAsync.fulfilled,
        (state: PromotionState, action: PayloadAction<GetPromotionsActionPayload>) => {
          state.loadingStatus = 'idle';
          state.totalItems = action.payload.totalItems;
          state.items = action.payload.items.map((promotion) => {
            if (promotion.locationType === 'product') {
              promotion.product = convertProductCode(promotion.product);
            }
            return promotion;
          });
          state.currentPage = action.payload.currentPage;
        },
      )
      .addCase(getPromotionsAsync.rejected, (state: PromotionState) => {
        state.loadingStatus = 'failed';
      })
      .addCase(patchPromotionAsync.rejected, (state: PromotionState) => {
        state.loadingStatus = 'failed';
        state.isProcessing = false;
      })
      .addCase(patchPromotionAsync.pending, (state: PromotionState) => {
        state.loadingStatus = 'loading';
        state.isProcessing = true;
      })
      .addCase(patchPromotionAsync.fulfilled, (state: PromotionState) => {
        state.loadingStatus = 'idle';
      })
      .addCase(postPromotionAsync.rejected, (state: PromotionState) => {
        state.loadingStatus = 'failed';
        state.isProcessing = false;
      })
      .addCase(postPromotionAsync.pending, (state: PromotionState) => {
        state.loadingStatus = 'loading';
        state.isProcessing = true;
      })
      .addCase(postPromotionAsync.fulfilled, (state: PromotionState) => {
        state.loadingStatus = 'idle';
      })
      .addCase(putPromotionAsync.rejected, (state: PromotionState) => {
        state.loadingStatus = 'failed';
        state.isProcessing = false;
      })
      .addCase(putPromotionAsync.pending, (state: PromotionState) => {
        state.loadingStatus = 'loading';
        state.isProcessing = true;
      })
      .addCase(putPromotionAsync.fulfilled, (state: PromotionState) => {
        state.loadingStatus = 'idle';
      })
      .addCase(getPromotionAsync.pending, (state: PromotionState) => {
        state.loadingStatus = 'loading';
      })
      .addCase(getPromotionAsync.rejected, (state: PromotionState) => {
        state.loadingStatus = 'failed';
      })
      .addCase(getPromotionAsync.fulfilled, (state: PromotionState, action: PayloadAction<PromotionDetails>) => {
        state.loadingStatus = 'idle';
        state.promotion = action.payload;
      })
      .addCase(deletePromotionAsync.pending, (state: PromotionState) => {
        state.loadingStatus = 'loading';
        state.isProcessing = true;
      })
      .addCase(deletePromotionAsync.rejected, (state: PromotionState) => {
        state.loadingStatus = 'failed';
        state.isProcessing = false;
      })
      .addCase(deletePromotionAsync.fulfilled, (state: PromotionState) => {
        state.loadingStatus = 'idle';
      });
  },
});

// selector
const selectPromotions = (state: RootState) => state.promotions;

// export types
export type { NewPromotionPayload, ProductList, PromotionState, PublishPromotionPayload, UpdatePromotionPayload };
export const { resetKeyword, searchPromotionName, unsetIsProcessing } = promotionSlice.actions;

// export async actions
export {
  getPromotionsAsync,
  patchPromotionAsync,
  postPromotionAsync,
  putPromotionAsync,
  getPromotionAsync,
  deletePromotionAsync,
};

// export selectors
export { selectPromotions };

export default promotionSlice.reducer;
