import { Avatar, Typography } from '@mui/material';

import { UserProfile } from 'models';

type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface UserAvatarProps {
  profile: UserProfile | undefined;
  size?: AvatarSize;
}

const nameFormatter = (profile: UserProfile): string => {
  if (profile.firstName?.match(/^[a-zA-Z]+$/) && profile.lastName?.match(/^[a-zA-Z]+$/)) {
    return profile.firstName?.charAt(0) + profile.lastName?.charAt(0) || '';
  } else {
    return profile.firstName || '';
  }
};

const getNumericSize = (size?: AvatarSize): number => {
  switch (size) {
    case 'xs':
      return 16;
    case 'sm':
      return 24;
    case 'md':
      return 32;
    case 'lg':
      return 40;
    case 'xl':
      return 48;
    default:
      return 40;
  }
};

const UserAvatar = ({ profile, size }: UserAvatarProps) => {
  const displayName = profile ? nameFormatter(profile) : '?';
  const numericSize = getNumericSize(size);

  return displayName ? (
    <Avatar
      sx={{
        width: numericSize,
        height: numericSize,
        fontSize: numericSize / 2,
        backgroundColor: 'primary.main',
        color: 'common.white',
      }}
    >
      <Typography variant="body3">{displayName}</Typography>
    </Avatar>
  ) : (
    <Avatar
      sx={{ width: numericSize, height: numericSize, backgroundColor: 'primary.main', color: 'common.white' }}
      src="/broken-image.jpg"
    />
  );
};

export { UserAvatar };
