import React from 'react';

import { Badge, Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useOktaAuth } from '@okta/okta-react';

import { useAppSelector } from 'app/hooks';

import { selectUser } from 'features/user/user-slice';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { NotificationIcon } from 'components/icons';

const ProfileSection = () => {
  const oktaAuth = useOktaAuth();

  const user = useAppSelector(selectUser);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async (): Promise<void> => {
    await oktaAuth.oktaAuth.signOut();
  };

  return (
    <>
      <Button disableRipple={true} disableFocusRipple={true} disableElevation={true} onClick={handleClick}>
        <Box sx={{ borderRadius: '50%', border: 2, borderColor: 'gray.300' }}>
          <UserAvatar profile={user.profile} size="lg" />
        </Box>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="medium" />
          </ListItemIcon>
          <ListItemText>로그아웃</ListItemText>
        </MenuItem>
      </Menu>
      <Badge color="primary" variant="dot">
        <NotificationIcon />
      </Badge>
    </>
  );
};

export default ProfileSection;
