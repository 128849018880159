import { Box, Collapse } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { selectNavigationOpen } from 'features/navigation/navigation-slice';
import ProfileSection from './ProfileSection';

const Header = ({ children }: { children?: React.ReactNode }) => {
  const navigationOpen = useAppSelector(selectNavigationOpen);

  return (
    <>
      <Collapse orientation="horizontal" in={navigationOpen} collapsedSize={'80px'} sx={{ flexShrink: 0 }}>
        <Box sx={{ width: '256px' }}></Box>
      </Collapse>
      <Box sx={{ marginLeft: '32px' }}>{children || <Box sx={{ flexGrow: 1 }} />}</Box>
      <Box sx={{ flexGrow: 1 }} />
      <ProfileSection />
    </>
  );
};

export default Header;
