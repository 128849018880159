import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import { getPromotionsAsync, searchPromotionName, selectPromotions } from 'features/promotions/promotion-slice';

const PromotionPageTitle = () => {
  const ROWS_PER_PAGE = 15;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { keyword } = useAppSelector(selectPromotions);

  const onConfirm = () => {
    navigate('/promotions/add');
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    dispatch(getPromotionsAsync({ page: 0, keyword, rowsPerPage: ROWS_PER_PAGE }));
  };

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" padding={5}>
      <Grid>
        <Typography variant="h5">분기페이지 배너 관리</Typography>
        <Typography variant="subtitle1">배너 등록 후, [반영] 버튼을 누르셔야 라이브에 반영됩니다.</Typography>
      </Grid>
      <Stack spacing={2} direction={'row'}>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          배너 등록
        </Button>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} direction={'row'}>
            <TextField
              id="input-with-icon-adornment"
              placeholder={'이벤트명 검색'}
              variant="standard"
              onChange={(searchKeyword) => dispatch(searchPromotionName(searchKeyword.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <Button type="submit" variant="contained" color="inherit">
              검색
            </Button>
          </Stack>
        </form>
      </Stack>
    </Grid>
  );
};

export { PromotionPageTitle };
