import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

import { DeviceListViewItem } from 'models/device';

import {
  DeviceLocationStat,
  DeviceStatusStat,
  DeviceTypesStat,
  getProvinceStatAsync,
  getTypesStatAsync,
  loadAsync,
  LoadResult,
  searchDevicesAsync,
  SearchOption,
} from './devices-thunks';

type LoadingStatus = 'loaded' | 'loading' | 'init';

interface DeviceDashboardState {
  locationStat: DeviceLocationStat[] | undefined;
  typesStat: DeviceTypesStat[] | undefined;
  statusStat: DeviceStatusStat[] | undefined;
  searchOption: SearchOption | undefined;
  mapState: MapState;
  loading: LoadingStatus;
  locationStatLoading: LoadingStatus;
  typesStatLoading: LoadingStatus;
  searching: boolean;
  devices: DeviceListState;
}

type DeviceListGroup = 'device-type' | 'building' | 'province';

interface DeviceListState {
  total: number;
  items: DeviceListViewItem[];
  groupBy: DeviceListGroup;
  groups: GroupedDeviceList;
}

interface MapState {
  zoom: number;
  center: { lat: number; lng: number };
}
interface DeviceSearchResult {
  status: 'ok' | 'cancelled' | 'error';
  devices: DeviceListViewItem[];
}

type GroupedDeviceList = Record<string, DeviceListViewItem[]>;

const initialState: DeviceDashboardState = {
  locationStat: undefined,
  typesStat: undefined,
  statusStat: undefined,
  devices: {
    items: [],
    total: 0,
    groupBy: 'device-type',
    groups: {},
  },
  searchOption: undefined,
  mapState: {
    zoom: 7,
    center: {
      lat: 36.75,
      lng: 127.96,
    },
  },
  loading: 'init',
  typesStatLoading: 'init',
  locationStatLoading: 'init',
  searching: false,
};

const groupDevices = (state: DeviceListState): void => {
  let keySelector: (device: DeviceListViewItem) => string;

  switch (state.groupBy) {
    case 'building':
      keySelector = (device: DeviceListViewItem) => (device.building?.id ? device.building.id : '0000');
      break;
    case 'device-type':
      keySelector = (device: DeviceListViewItem) => device.deviceType;
      break;
    case 'province':
      keySelector = (device: DeviceListViewItem) => (device.building?.province ? device.building.province : '0000');
      break;
    default:
      keySelector = (_: DeviceListViewItem) => '0000';
      break;
  }

  state.groups = state.items.reduce((list: GroupedDeviceList, device: DeviceListViewItem) => {
    const key = keySelector(device);

    if (list[key]) {
      list[key].push(device);
    } else {
      list[key] = [device];
    }
    return list;
  }, {} as GroupedDeviceList);
};

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    changeSearchOption: (state: Draft<DeviceDashboardState>, action: PayloadAction<SearchOption>) => {
      state.searchOption = action.payload;
    },
    setMap: (state: Draft<DeviceDashboardState>, action: PayloadAction<MapState>) => {
      state.mapState = action.payload;
    },
    changeGrouping: (state: Draft<DeviceDashboardState>, action: PayloadAction<DeviceListGroup>) => {
      state.devices.groupBy = action.payload;
      groupDevices(state.devices);
    },
    replaceDevice: (state: Draft<DeviceDashboardState>, action: PayloadAction<DeviceListViewItem>) => {
      const deviceList = [...state.devices.items];
      const index = deviceList.findIndex(
        (device: DeviceListViewItem) => device.serialNumber === action.payload.serialNumber,
      );
      if (index >= 0) {
        deviceList.splice(index, 1, action.payload);
        state.devices.items = deviceList;
        groupDevices(state.devices);
      }
    },
    replaceStatusIndicators: (state: Draft<DeviceDashboardState>, action: PayloadAction<DeviceStatusStat[]>) => {
      state.statusStat = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DeviceDashboardState>) => {
    builder
      .addCase(loadAsync.pending, (state: Draft<DeviceDashboardState>) => {
        state.loading = 'loading';
        state.searching = true;
      })
      .addCase(loadAsync.fulfilled, (state: Draft<DeviceDashboardState>, action: PayloadAction<LoadResult>) => {
        state.loading = 'loaded';
        state.locationStat = action.payload.locationStat;
        state.typesStat = action.payload.typesStat;
        state.statusStat = action.payload.statusStat;
        if (action.payload.devices && action.payload.devices.status === 'ok') {
          state.searching = false;
          state.devices.items = action.payload.devices.devices.map((device: DeviceListViewItem) => ({
            ...device,
            coordinates: device.building.coordinates,
          }));
          state.devices.total = state.devices.items.length;
          groupDevices(state.devices);
        }
      })
      .addCase(loadAsync.rejected, (state: Draft<DeviceDashboardState>) => {
        state.loading = 'init';
        state.searching = false;
      })
      .addCase(searchDevicesAsync.pending, (state: Draft<DeviceDashboardState>) => {
        state.searching = true;
      })
      .addCase(searchDevicesAsync.rejected, (state: Draft<DeviceDashboardState>) => {
        state.searching = false;
      })
      .addCase(
        searchDevicesAsync.fulfilled,
        (state: Draft<DeviceDashboardState>, action: PayloadAction<DeviceSearchResult>) => {
          if (action.payload && action.payload.status === 'ok') {
            state.searching = false;
            state.devices.items = action.payload.devices.map((device: DeviceListViewItem) => ({
              ...device,
              coordinates: device.building.coordinates,
            }));
            state.devices.total = state.devices.items.length;
            groupDevices(state.devices);
          }
        },
      )
      .addCase(getProvinceStatAsync.pending, (state: Draft<DeviceDashboardState>) => {
        state.locationStatLoading = 'loading';
      })
      .addCase(getProvinceStatAsync.rejected, (state: Draft<DeviceDashboardState>) => {
        state.locationStatLoading = 'init';
      })
      .addCase(
        getProvinceStatAsync.fulfilled,
        (state: Draft<DeviceDashboardState>, action: PayloadAction<DeviceLocationStat[] | undefined>) => {
          state.locationStatLoading = 'loaded';
          if (action.payload) {
            state.locationStat = action.payload;
          }
        },
      )
      .addCase(getTypesStatAsync.pending, (state: Draft<DeviceDashboardState>) => {
        state.typesStatLoading = 'loading';
      })
      .addCase(getTypesStatAsync.rejected, (state: Draft<DeviceDashboardState>) => {
        state.typesStatLoading = 'init';
      })
      .addCase(
        getTypesStatAsync.fulfilled,
        (state: Draft<DeviceDashboardState>, action: PayloadAction<DeviceTypesStat[] | undefined>) => {
          state.typesStatLoading = 'loaded';
          if (action.payload) {
            state.typesStat = action.payload;
          }
        },
      );
  },
});

// selectors
export const selectDashboard = (state: RootState) => state.devices;

export const { changeSearchOption, setMap, changeGrouping, replaceDevice, replaceStatusIndicators } =
  devicesSlice.actions;

export type { DeviceSearchResult };
export default devicesSlice.reducer;
