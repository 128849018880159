import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

type NotificationSeverity = 'error' | 'warning' | 'info' | 'success';

interface NotificationState {
  message: string;
  severity: NotificationSeverity;
  duration: number | undefined;
  dismissed: boolean;
  navigateTo?: string;
}

interface ShowNotificationPayload {
  message: string;
  duration?: number;
  navigateTo?: string;
}

const initialState: NotificationState = {
  message: '',
  severity: 'error',
  duration: 0,
  dismissed: true,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showError: (state: Draft<NotificationState>, action: PayloadAction<ShowNotificationPayload>) => {
      state.message = action.payload.message;
      state.duration = action.payload.duration || 3000;
      state.severity = 'error';
      state.dismissed = false;
      state.navigateTo = action.payload.navigateTo;
    },
    showSuccess: (state: Draft<NotificationState>, action: PayloadAction<ShowNotificationPayload>) => {
      state.message = action.payload.message;
      state.duration = action.payload.duration || 3000;
      state.severity = 'success';
      state.dismissed = false;
    },
    showInfo: (state: Draft<NotificationState>, action: PayloadAction<ShowNotificationPayload>) => {
      state.message = action.payload.message;
      state.duration = action.payload.duration || 3000;
      state.severity = 'info';
      state.dismissed = false;
    },
    showWarning: (state: Draft<NotificationState>, action: PayloadAction<ShowNotificationPayload>) => {
      state.message = action.payload.message;
      state.duration = action.payload.duration || 3000;
      state.severity = 'warning';
      state.dismissed = false;
    },
    dismissNotification: (state: Draft<NotificationState>) => {
      state.dismissed = true;
      state.navigateTo = '';
    },
  },
});

const selectNotification = (state: RootState) => state.notification;

export { selectNotification };

export type { NotificationState };

export const { dismissNotification, showError, showInfo, showSuccess, showWarning } = notificationSlice.actions;

export default notificationSlice.reducer;
