import RestApiClient from 'lib/rest-api-client';
import { DevicesPayload } from './new-complex-editor-slice';

const restApiClient = new RestApiClient({
  baseUrl: process.env.REACT_APP_MEDIA_DEVICES_API_URL || '',
  timeout: 5000,
});

const getS3SignedUrl = async (s3FileName: string) => {
  return await restApiClient.get('/signed-url', { params: { s3FileName } });
};

const putDevicesToComplex = async (complexId: string, payload: DevicesPayload) => {
  return await restApiClient.put(`/complex/${complexId}/devices`, payload);
};

const validateUploadedDevices = async (complexId: string, path: string, numberOfDevices: number) => {
  return await restApiClient.post('/complex/validate-devices', { complexId, path, numberOfDevices });
};

export { getS3SignedUrl, putDevicesToComplex, validateUploadedDevices };
