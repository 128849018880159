import { useEffect } from 'react';

import { Link, Paper } from '@mui/material';

import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { changeSearchOption, searchDevicesAsync, selectDashboard } from 'features/devices';
import { DeviceListViewItem } from 'models/device';

const markerIcon = new Icon({
  iconUrl: 'https://img.icons8.com/ultraviolet/2x/visit.png',
  iconSize: [42, 42],
});

const MapController = () => {
  const { mapState } = useAppSelector(selectDashboard);
  const map = useMap();

  useEffect(() => {
    map.setView(mapState.center, mapState.zoom);
  }, [mapState]);

  return <></>;
};

const DeviceMap = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { devices, mapState } = useAppSelector(selectDashboard);

  const buildingDevicesMap = devices.items.reduce(
    (
      obj: Record<string, { location: { lat: number; lng: number }; devices: number; buildingName: string }>,
      device: DeviceListViewItem,
    ) => {
      const key = device.building?.id || 'unknown';
      if (obj[key]) {
        const numOfDevices = obj[key].devices || 0;
        obj[key].devices = numOfDevices + 1;
      } else {
        obj[key] = {
          location: device.building.coordinates || { lat: 0, lng: 0 },
          buildingName: device.building?.buildingName || '',
          devices: 1,
        };
      }

      return obj;
    },
    {},
  );

  const onMarkerClick = (buildingId: string): void => {
    const option = { search: 'bdid', value: buildingId };
    dispatch(changeSearchOption(option));
    dispatch(searchDevicesAsync(option));
  };

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '10px',
      }}
    >
      <MapContainer
        center={mapState.center}
        zoom={mapState.zoom}
        scrollWheelZoom={true}
        style={{ width: '100%', height: '50vh', borderRadius: '10px' }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {Object.keys(buildingDevicesMap)
          .filter((key: string) => key !== 'unknown')
          .map((key: string, index: number) => {
            const building = buildingDevicesMap[key];
            const pos = building.location || { lat: 0, lng: 0 };
            return (
              <Marker key={index} position={pos} icon={markerIcon}>
                <Popup>
                  <Link onClick={() => onMarkerClick(key)}>
                    {building.buildingName} ({buildingDevicesMap[key].devices}대)
                  </Link>
                </Popup>
              </Marker>
            );
          })}
        <MapController />
      </MapContainer>
    </Paper>
  );
};

export { DeviceMap };
