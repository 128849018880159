import { MenuItem, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';

import { useAppDispatch } from 'app/hooks';

import { setLocations, toggleOpenStatus } from 'features/locations/locations-slice';
import { BuildingType, InstalledOn, LocationType } from 'models';

import { FmkTag, FmkSelect, FmkFormLabel, FmkFormCard } from 'components/fds';

import { installedOnFormatter, locationTypeFormatter } from 'lib/utils';
import { isDone } from 'pages/locations/ComplexCreatePage';

// 엘리베이터 내/외부
const installedOn = Object.values(InstalledOn)
  .filter((value: any) => !isNaN(value))
  .map((value: any) => installedOnFormatter(value));

// 엘리베이터 구분
const locationType = Object.values(LocationType)
  .filter((value: any) => !isNaN(value))
  .map((value: any) => locationTypeFormatter(value));

const InputField = (props: {
  title: string;
  placeholder?: string;
  inputType?: string;
  required?: boolean;
  name: string;
  value?: any;
  onChange?: any;
  disabled?: boolean;
}) => {
  const {
    title,
    placeholder = '',
    inputType = 'text',
    name,
    value = '',
    onChange = undefined,
    required,
    disabled,
  } = props;

  return (
    <Stack sx={{ width: '240px' }}>
      <FmkFormLabel title={title} required={required} />
      {inputType === 'select' && title === '엘리베이터 내/외부' && (
        <FmkSelect size={'small'} value={value} name={name} onChange={onChange} disabled={disabled}>
          {installedOn.map((v: any, i: any) => {
            return (
              <MenuItem value={i} key={i}>
                {v}
              </MenuItem>
            );
          })}
        </FmkSelect>
      )}
      {inputType === 'select' && title === '엘리베이터 구분' && (
        <FmkSelect size={'small'} value={value} name={name} onChange={onChange} disabled={disabled}>
          {locationType.map((v: any, i: any) => {
            return (
              <MenuItem value={i} key={i}>
                {v}
              </MenuItem>
            );
          })}
        </FmkSelect>
      )}
      {inputType === 'text' && <TextField name={name} placeholder={placeholder} value={value} disabled={disabled} />}
    </Stack>
  );
};

const LocationForm = (props: {
  disabledField?: any;
  locationItem?: any;
  buildingType: number;
  index: number;
  foldable: boolean;
  onRemove?: () => void;
}) => {
  const { buildingType, index, onRemove, locationItem, foldable, disabledField = [] } = props;
  const { UNKNOWN, APARTMENT, CAMPUS, OFFICE, SUBWAY, CULTURAL_FACILITIES, OTHER } = BuildingType;

  const dispatch = useAppDispatch();

  const onFieldChange = (e: any) => {
    dispatch(setLocations({ index, item: { [e.target.name]: e.target.value } }));
  };

  return (
    <>
      {locationItem.openStatus ? (
        <FmkFormCard sx={{ padding: '24px 28px', marginBottom: '16px' }} onChange={(e) => onFieldChange(e)}>
          {/* 카드 타이틀 */}
          <Stack direction="row" alignItems="center" mb={'23px'} justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={'8px'}>
              <Typography variant="subtitle1" sx={{ color: 'gray.800' }}>
                상세 위치 {foldable && index + 1}
              </Typography>
              {isDone(buildingType, locationItem) && <DoneIcon sx={{ color: 'success.main' }} />}
            </Stack>
            <Stack direction={'row'} spacing="8px" alignItems={'center'}>
              {onRemove && <DeleteOutlineIcon onClick={onRemove} sx={{ cursor: 'pointer', color: 'gray.700' }} />}
              {foldable && (
                <UnfoldLessIcon
                  onClick={() => dispatch(toggleOpenStatus(index))}
                  sx={{ cursor: 'pointer', color: 'gray.700' }}
                />
              )}
            </Stack>
          </Stack>

          {/* input fields */}
          <Stack spacing={'16px'}>
            {[UNKNOWN, APARTMENT, CULTURAL_FACILITIES, OTHER].includes(buildingType) && (
              <>
                <Stack direction="row" spacing="16px">
                  <InputField
                    title="엘리베이터 내/외부"
                    inputType="select"
                    name="installedOn"
                    value={locationItem?.installedOn}
                    onChange={(e: any) => onFieldChange(e)}
                    required
                    disabled={disabledField.includes('installedOn')}
                  />
                  <InputField
                    title="엘리베이터 구분"
                    inputType="select"
                    name="locationType"
                    value={locationItem?.locationType}
                    onChange={(e: any) => onFieldChange(e)}
                    required
                    disabled={disabledField.includes('locationType')}
                  />
                  <InputField
                    title="동"
                    placeholder="101동, A동, 관리동 등 입력"
                    name="sector"
                    value={locationItem?.sector}
                  />
                </Stack>
                <Stack direction="row" spacing="16px">
                  <InputField title="구역" placeholder="로비, 고층용 등 입력" name="zone" value={locationItem?.zone} />
                  <InputField
                    title="층"
                    placeholder="1층, B1층 등 입력"
                    name="floor"
                    value={locationItem?.floor}
                    disabled={disabledField.includes('floor')}
                  />
                  <InputField
                    title="호기"
                    placeholder="1호기, 1-2라인 짝수 등 입력"
                    name="liftIndex"
                    value={locationItem?.liftIndex}
                    required
                    disabled={disabledField.includes('liftIndex')}
                  />
                </Stack>
              </>
            )}

            {[CAMPUS, OFFICE].includes(buildingType) && (
              <>
                <Stack direction="row" spacing="16px">
                  <InputField
                    title="엘리베이터 내/외부"
                    inputType="select"
                    name="installedOn"
                    value={locationItem?.installedOn}
                    onChange={(e: any) => onFieldChange(e)}
                    required
                    disabled={disabledField.includes('installedOn')}
                  />
                  <InputField
                    title="동"
                    placeholder="101동, A동, 관리동 등 입력"
                    name="sector"
                    value={locationItem?.sector}
                  />
                </Stack>
                <Stack direction="row" spacing="16px">
                  <InputField title="구역" placeholder="로비, 고층용 등 입력" name="zone" value={locationItem?.zone} />
                  <InputField
                    title="층"
                    placeholder="1층, B1층 등 입력"
                    name="floor"
                    value={locationItem?.floor}
                    disabled={disabledField.includes('floor')}
                  />
                  <InputField
                    title="호기"
                    placeholder="1호기, 1-2라인 짝수 등 입력"
                    name="liftIndex"
                    value={locationItem?.liftIndex}
                    required
                    disabled={disabledField.includes('liftIndex')}
                  />
                </Stack>
              </>
            )}

            {buildingType === SUBWAY && (
              <>
                <Stack direction="row" spacing="16px">
                  <InputField
                    title="엘리베이터 내/외부"
                    inputType="select"
                    name="installedOn"
                    value={locationItem?.installedOn}
                    onChange={(e: any) => onFieldChange(e)}
                    required
                    disabled={disabledField.includes('installedOn')}
                  />
                  <InputField
                    title="지하철 호선"
                    placeholder="1호기, 동해선 등 입력"
                    name="line"
                    value={locationItem?.line}
                    required
                    disabled={disabledField.includes('line')}
                  />
                  <InputField
                    title="호기"
                    placeholder="1호기, 1-2라인 짝수 등 입력"
                    name="liftIndex"
                    value={locationItem?.liftIndex}
                    required
                    disabled={disabledField.includes('liftIndex')}
                  />
                </Stack>
              </>
            )}

            {/* 공통 필드 */}
            <Stack>
              <FmkFormLabel title="기타" />
              <TextField
                name="note"
                multiline
                placeholder="상세 위치의 기타 정보를 자유롭게 작성해 주세요."
                value={locationItem?.note}
                onChange={(e) => onFieldChange(e)}
                sx={{
                  '.MuiInputBase-root': {
                    minHeight: '80px',
                    height: 'unset',
                    padding: '12px',
                    alignItems: 'flex-start',
                  },
                }}
                maxRows={3}
              />
            </Stack>
          </Stack>
        </FmkFormCard>
      ) : (
        <FmkFormCard sx={{ padding: '24px 28px', marginBottom: '16px', width: '808px' }} className="close">
          {/* 카드 타이틀 */}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={'8px'}>
              <Typography variant="subtitle1" sx={{ color: 'gray.800' }}>
                상세 위치 {foldable && index + 1}
              </Typography>
              {isDone(buildingType, locationItem) ? (
                <DoneIcon sx={{ color: 'success.main' }} />
              ) : (
                <FmkTag label="작성필요" severity="error" />
              )}
            </Stack>
            <Stack direction={'row'} spacing="8px" alignItems={'center'}>
              {locationItem?.sector && <FmkTag label={locationItem.sector} />}
              {locationItem?.zone && <FmkTag label={locationItem.zone} />}
              {locationItem?.floor && <FmkTag label={locationItem.floor} />}
              {locationItem?.line && <FmkTag label={locationItem.line} />}
              {locationItem?.liftIndex && <FmkTag label={locationItem.liftIndex} />}
              {onRemove && <DeleteOutlineIcon onClick={onRemove} sx={{ cursor: 'pointer', color: 'gray.700' }} />}
              {foldable && (
                <UnfoldMoreIcon
                  onClick={() => dispatch(toggleOpenStatus(index))}
                  sx={{ cursor: 'pointer', color: 'gray.700' }}
                />
              )}
            </Stack>
          </Stack>
        </FmkFormCard>
      )}
    </>
  );
};

export { LocationForm };
