import ClearIcon from '@mui/icons-material/Clear';

const ClearButton = () => {
  return (
    <ClearIcon
      sx={{
        color: '#454C53',
        fontSize: '16px',
        padding: '2px',
        background: '#D9D9D9',
        borderRadius: '20px',
      }}
    />
  );
};

export { ClearButton };
