import { createAsyncThunk } from '@reduxjs/toolkit';

import { DeviceActivity, DeviceDetail, DeviceListViewItem, DeviceApp, DeviceAppInfo, RfSyncInfo } from 'models/device';
import { showError, showSuccess } from 'features/notification/notification-slice';
import {
  SetAttributeParams,
  getDevice,
  getDeviceActivities,
  getInstalledApps,
  requestAttributeChange,
  checkForUpdate,
  updateApps,
  updateRfSyncInfo,
  deleteRfSyncInfo,
} from './device-api';
import { replaceDevice } from './devices-slice';

interface DeviceDetailResponse {
  device: DeviceDetail | undefined;
  activities: DeviceActivity[];
  apps: DeviceApp[] | undefined;
}

interface UpdateDeviceLocationPayload {
  serialNumber: string;
  complexId: string;
  buildingId: string;
  detail?: string;
  floor?: number;
  extra?: string;
}

interface UpdateDeviceApplicationPayload {
  serialNumber: string;
  applications: { appName: string; desiredVersion: number }[];
}

interface UpdateRfSyncInfoPayload {
  serialNumber: string;
  rfSyncInfo: RfSyncInfo;
}

/**
 * 매체 앱 정보를 화면에 표시하기 위해 필요한 정보를 추가하는 함수
 * @param appInfo getDeviceApplications API호출의 결과로 받아온 앱 정보
 * @returns getApplicationNewerVersion API호출의 결과로 받아온 값을 appInfo와 합친 데이터
 */
const makeAppsState = async (appInfo: DeviceAppInfo[]): Promise<DeviceApp[]> => {
  const appNames = [
    {
      appName: 'com.fmk.core.controller',
      appNameKr: '컨트롤러',
    },
    {
      appName: 'com.fmk.core.installer',
      appNameKr: '인스톨러',
    },
    {
      appName: 'com.fmk.core.launcher',
      appNameKr: '런처',
    },
    {
      appName: 'com.fmk.core.player',
      appNameKr: '플레이어',
    },
  ];

  // getApplicationNewerVersion API 호출을 위해 "appName:version,...,appName:version" 형태로 변경
  // 설치되지 않은 앱은 version을 0으로 하여 상위버전을 조회할 수 있게 함
  const param = appNames
    .map((appName) => {
      const version = appInfo.find((info: DeviceAppInfo) => info.appName === appName.appName)?.version ?? 0;
      return `${appName.appName}:${version}`;
    })
    .join(',');

  const { data: newerVersions } = await checkForUpdate(param);

  // 설치되지 않은 앱의 version, installedAt은 state에 null 로 저장
  const apps = appNames.map((appName) => {
    return {
      ...appName,
      installedAt: appInfo.find((info: DeviceAppInfo) => info.appName === appName.appName)?.installedAt ?? null,
      version: appInfo.find((info: DeviceAppInfo) => info.appName === appName.appName)?.version ?? null,
      availableVersions: newerVersions.find((newer: DeviceAppInfo) => newer.appName === appName.appName)
        ?.availableVersions,
    };
  });

  return apps;
};

const getDeviceDetailAsync = createAsyncThunk(
  'device/get',
  async (serialNumber: string, { dispatch }): Promise<DeviceDetailResponse> => {
    try {
      const response = await Promise.allSettled([
        getDevice(serialNumber),
        getDeviceActivities(serialNumber),
        getInstalledApps(serialNumber),
      ]);

      const appInfo = (response[2] as any).value.data || [];
      const apps = await makeAppsState(appInfo);

      return {
        device: (response[0] as any).value.data,
        activities: (response[1] as any).value.data || [],
        apps: apps || [],
      };
    } catch (e) {
      dispatch(showError({ message: '매체 조회 중 오류가 발생했습니다' }));
      return Promise.reject(e);
    }
  },
);

const refreshDeviceDetailsAsync = createAsyncThunk(
  'device/get/activities',
  async (serialNumber: string, { dispatch }): Promise<DeviceDetailResponse> => {
    try {
      const response = await Promise.allSettled([
        getDevice(serialNumber),
        getDeviceActivities(serialNumber),
        getInstalledApps(serialNumber),
      ]);

      const appInfo = (response[2] as any).value.data || [];
      const apps = await makeAppsState(appInfo);
      const deviceResponse = (response[0] as any).value.data;

      if ((response[1] as any).value.status === 204) {
        dispatch(replaceDevice(deviceResponse as DeviceListViewItem));
      }
      return {
        device: (response[0] as any).value.data,
        activities: (response[1] as any).value.data || [],
        apps: apps || [],
      };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

const requestChangeAttributeAsync = createAsyncThunk(
  'device/attributes/volume/set',
  async (payload: SetAttributeParams, { dispatch }) => {
    try {
      await requestAttributeChange(payload);
      const newActivities = (await getDeviceActivities(payload.serialNumber)).data || [];
      dispatch(showSuccess({ message: '매체 상태 변경을 성공적으로 요청했습니다' }));
      return newActivities;
    } catch (e) {
      dispatch(showError({ message: '매체 상태 변경을 요청하는 도중 오류가 발생했습니다' }));
      return Promise.reject(e);
    }
  },
);

const updateApplicationVersionAsync = createAsyncThunk(
  'device/application/update',
  async (payload: UpdateDeviceApplicationPayload, { dispatch }) => {
    try {
      await updateApps(payload);
      const newActivities = (await getDeviceActivities(payload.serialNumber)).data || [];
      dispatch(showSuccess({ message: '앱 버전 업그레이드를 성공적으로 요청했습니다' }));
      return newActivities;
    } catch (e) {
      dispatch(showError({ message: '앱 버전 업그레이드를 하는 도중 오류가 발생했습니다' }));
      return Promise.reject(e);
    }
  },
);

const updateRfSyncInfoAsync = createAsyncThunk(
  'device/rfsync/update',
  async (payload: UpdateRfSyncInfoPayload, { dispatch }) => {
    try {
      await updateRfSyncInfo(payload);
      const newActivities = (await getDeviceActivities(payload.serialNumber)).data || [];
      dispatch(showSuccess({ message: 'RF 동기화 설정 변경을 성공적으로 요청했습니다' }));
      return newActivities;
    } catch (e) {
      dispatch(showError({ message: 'RF 동기화 설정 변경을 하는 도중 오류가 발생했습니다' }));
      return Promise.reject(e);
    }
  },
);

const deleteRfSyncInfoAsync = createAsyncThunk('device/rfsync/delete', async (serialNumber: string, { dispatch }) => {
  try {
    await deleteRfSyncInfo(serialNumber);
    const newActivities = (await getDeviceActivities(serialNumber)).data || [];
    dispatch(showSuccess({ message: 'RF 동기화 해제를 성공적으로 요청했습니다' }));
    return newActivities;
  } catch (e) {
    dispatch(showError({ message: 'RF 동기화 해제를 하는 도중 오류가 발생했습니다' }));
    return Promise.reject(e);
  }
});

export type {
  DeviceDetailResponse,
  UpdateDeviceLocationPayload,
  UpdateDeviceApplicationPayload,
  UpdateRfSyncInfoPayload,
};

export {
  getDeviceDetailAsync,
  refreshDeviceDetailsAsync,
  requestChangeAttributeAsync,
  updateApplicationVersionAsync,
  updateRfSyncInfoAsync,
  deleteRfSyncInfoAsync,
};
