import { SvgIcon } from '@mui/material';

interface ArrowDownIconProps {
  width?: string;
  height?: string;
  color?: string;
  display?: 'block' | 'none';
}

const ArrowDownIcon = ({
  width = '20px',
  height = '20px',
  color = '#FFFFFF',
  display = 'block',
}: ArrowDownIconProps) => {
  return (
    <SvgIcon inheritViewBox={true} sx={{ width, height, color, display }}>
      <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.47917 11.6876L7.625 9.81258C7.38889 9.57647 7.33334 9.30564 7.45834 9.00008C7.58334 8.69453 7.81945 8.54175 8.16667 8.54175H11.8542C12.1875 8.54175 12.42 8.69453 12.5517 9.00008C12.6839 9.30564 12.6319 9.57647 12.3958 9.81258L10.5417 11.6876C10.4583 11.757 10.375 11.8126 10.2917 11.8542C10.2083 11.8959 10.1111 11.9167 10 11.9167C9.90278 11.9167 9.8125 11.8959 9.72917 11.8542C9.64584 11.8126 9.5625 11.757 9.47917 11.6876Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export { ArrowDownIcon };
