import { Box, Button } from '@mui/material';

interface ModalButtonProps {
  cancelButtonText: string;
  publishedButtonText: string;
  onCancel: () => void;
  onPublish?: () => void;
}

const ModalButton = ({ cancelButtonText, publishedButtonText, onCancel, onPublish }: ModalButtonProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1, pr: 3 }}>
      <Button
        style={{ color: '#0F02C3' }}
        variant="text"
        onClick={() => {
          onCancel && onCancel();
        }}
        sx={{ mr: 1 }}
      >
        {cancelButtonText}
      </Button>
      <Box sx={{ flexDirection: 'row' }} />
      <Button
        style={{ color: 'white', backgroundColor: '#0F02C3', borderRadius: '8px' }}
        variant="outlined"
        onClick={() => {
          onPublish && onPublish();
        }}
      >
        {publishedButtonText}
      </Button>
    </Box>
  );
};

export { ModalButton };
