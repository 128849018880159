import { Box, styled } from '@mui/material';

const FileInput = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 12px',
  gap: '4px',
  cursor: 'pointer',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '17px',
  color: '#0F02C3',
  border: '1px solid #C9CDD2',
  borderRadius: '4px',
}));

export { FileInput };
