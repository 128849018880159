import { useTheme, CSSObject, Drawer, Theme, Toolbar, IconButton } from '@mui/material';

import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectNavigationOpen, setNavigationOpen } from 'features/navigation/navigation-slice';
import { FomigoLogo, MenuIcon } from 'components/icons';
import { NavigationDrawerList } from './NavigationDrawerList';

const OPENED_NAVIGATION_WIDTH = 256;
const CLOSED_NAVIGATION_WIDTH = 80;
const hoverColor = '#00E16D';

const NavigationDrawer = () => {
  const dispatch = useAppDispatch();
  const navigationOpen = useAppSelector(selectNavigationOpen);
  const theme = useTheme();

  const openedMixin = (theme: Theme): CSSObject => ({
    width: OPENED_NAVIGATION_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    boxSizing: 'border-box',
    width: CLOSED_NAVIGATION_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  });

  return (
    <Drawer
      variant={'permanent'}
      open={navigationOpen}
      sx={{
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(navigationOpen && openedMixin(theme)),
        ...(!navigationOpen && closedMixin(theme)),
      }}
      PaperProps={{
        sx: {
          color: 'common.white',
          background: '#001B34',
          borderRadius: '0px 24px 0px 0px',
          overflow: 'visible',
          ...(navigationOpen && openedMixin(theme)),
          ...(!navigationOpen && closedMixin(theme)),
        },
      }}
    >
      <Toolbar
        sx={{
          justifyContent: navigationOpen ? 'space-between' : 'center',
          alignItems: 'start',
          padding: navigationOpen ? '32px 28px 60px 24px' : '32px 24px 32px 24px',
        }}
      >
        <FomigoLogo width={'95.29px'} height={'27.46px'} color={'white'} display={navigationOpen ? 'block' : 'none'} />
        <IconButton
          edge="start"
          aria-label="menu"
          sx={{ padding: '0', ml: '0', color: 'common.white', '&:hover': { '& path': { fill: hoverColor } } }}
          onClick={() => {
            dispatch(setNavigationOpen(!navigationOpen));
          }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <NavigationDrawerList />
    </Drawer>
  );
};

export { NavigationDrawer };
