import { SvgIcon } from '@mui/material';

interface FomigoLogoProps {
  width?: string;
  height?: string;
  color?: 'black' | 'white' | '#304FFE';
  display?: 'block' | 'none';
}

const FomigoLogo = ({ width = '124px', height = '36px', color = '#304FFE', display = 'block' }: FomigoLogoProps) => {
  return (
    <SvgIcon inheritViewBox={true} sx={{ width, height, color, display }}>
      <svg viewBox="0 0 124 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.0437 6.4426C12.4879 6.4426 12.895 6.48 13.1541 6.51741V1.01923C12.4508 0.907017 11.4885 0.720003 10.2299 0.720003C3.78935 0.720003 2.78995 5.61975 2.78995 9.13559H0.458008V14.8208H2.78995V28.0987H9.45263V14.8208H13.1541V9.13559H9.45263C9.45263 7.60208 10.1189 6.4426 12.0437 6.4426Z"
          fill={color}
        />
        <path
          d="M24.6958 8.68676C18.8475 8.68676 14.6278 12.8384 14.6278 18.6358C14.6278 24.4333 18.8475 28.5849 24.6958 28.5849C30.5442 28.5849 34.7639 24.4333 34.7639 18.6358C34.7639 12.8384 30.5442 8.68676 24.6958 8.68676ZM24.6958 22.0021C22.7711 22.0021 21.3275 20.7304 21.3275 18.6358C21.3275 16.5413 22.7711 15.2322 24.6958 15.2322C26.6206 15.2322 28.0272 16.5413 28.0272 18.6358C28.0272 20.7304 26.6206 22.0021 24.6958 22.0021Z"
          fill={color}
        />
        <path
          d="M59.7114 8.68676C57.1203 8.68676 55.3436 9.84624 54.1962 11.1927C53.2708 9.77143 51.7162 8.68676 49.0511 8.68676C46.8302 8.68676 45.3866 9.54702 44.4242 10.6691V9.13559H37.7245V28.0987H44.4242V17.5512C44.4242 16.0925 45.3126 15.307 46.6081 15.307C47.8666 15.307 48.755 16.0925 48.755 17.5512V28.0987H55.4177V17.5512C55.4177 16.0925 56.306 15.307 57.6015 15.307C58.86 15.307 59.7854 16.0925 59.7854 17.5512V28.0987H66.4481V16.2421C66.4481 13.4743 65.4117 8.68676 59.7114 8.68676Z"
          fill={color}
        />
        <path
          d="M70.5189 28.0987H77.1816V9.13559H70.5189V28.0987ZM73.8502 7.34027C75.775 7.34027 77.3296 5.76936 77.3296 3.82442C77.3296 1.91689 75.775 0.345978 73.8502 0.345978C71.9254 0.345978 70.3708 1.91689 70.3708 3.82442C70.3708 5.76936 71.9254 7.34027 73.8502 7.34027Z"
          fill={color}
        />
        <path
          d="M93.7744 9.13559V10.5943C92.7009 9.43481 91.1463 8.68676 88.8884 8.68676C83.1881 8.68676 80.1529 12.9881 80.1529 17.9626C80.1529 22.9372 83.2991 27.2385 88.8884 27.2385C91.1463 27.2385 92.7009 26.5652 93.7744 25.4431V25.7797C93.7744 28.4727 92.96 30.1933 89.5547 30.1933C87.5929 30.1933 86.1493 29.333 85.6311 28.5849L80.7081 31.5397C82.2257 34.1953 86.0012 35.654 89.8878 35.654C96.5505 35.654 100.437 31.9512 100.437 26.453V9.13559H93.7744ZM90.2209 21.3662C88.1481 21.3662 86.8526 19.9449 86.8526 17.9626C86.8526 15.9803 88.1481 14.559 90.2209 14.559C92.1827 14.559 93.5523 15.8307 93.5523 17.9626C93.5523 20.0946 92.1827 21.3662 90.2209 21.3662Z"
          fill={color}
        />
        <path
          d="M113.472 8.68676C107.624 8.68676 103.404 12.8384 103.404 18.6358C103.404 24.4333 107.624 28.5849 113.472 28.5849C119.321 28.5849 123.54 24.4333 123.54 18.6358C123.54 12.8384 119.321 8.68676 113.472 8.68676ZM113.472 22.0021C111.548 22.0021 110.104 20.7304 110.104 18.6358C110.104 16.5413 111.548 15.2322 113.472 15.2322C115.397 15.2322 116.804 16.5413 116.804 18.6358C116.804 20.7304 115.397 22.0021 113.472 22.0021Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export { FomigoLogo };
