/**
 * MediaDeviceType 열거자(enum)은 현재 FMK가 보유한 매체의 종류를 나열합니다.
 * MediaDeviceType enum defines the type of media devices available.
 */

enum MediaDeviceType {
  Portrait29 = 'UGOOS-UT6',
  Portrait25Gen2 = 'FMKEV25-2',
  Landscape27Gen1 = 'FMKEV27-1',
  LandscapePortrait32Gen2 = 'fmkev32-2',
}

export { MediaDeviceType };
