import RestApiClient from 'lib/rest-api-client';
import { Registry } from 'models';
import {
  UpdateDeviceLocationPayload,
  UpdateDeviceApplicationPayload,
  UpdateRfSyncInfoPayload,
} from './device-editor-thunks';

const restApiClient = new RestApiClient({
  baseUrl: process.env.REACT_APP_MEDIA_DEVICES_API_URL || '',
  timeout: 10 * 1000,
});

interface SetAttributeParams extends Registry {
  serialNumber: string;
}

const getDevice = async (serialNumber: string) => {
  return await restApiClient.get(`/devices/${serialNumber}`);
};

const getDeviceActivities = async (serialNumber: string) => {
  return await restApiClient.get(`/devices/${serialNumber}/activities`);
};

const getInstalledApps = async (serialNumber: string) => {
  return await restApiClient.get(`/devices/${serialNumber}/applications`);
};

const checkForUpdate = async (appsVersionString: string) => {
  return await restApiClient.get(`/applications/newer?apps=${appsVersionString}`);
};

const updateApps = async (payload: UpdateDeviceApplicationPayload) => {
  return await restApiClient.patch(`/devices/${payload.serialNumber}/applications`, payload.applications);
};

const requestAttributeChange = async (payload: SetAttributeParams) => {
  return await restApiClient.patch(`/devices/${payload.serialNumber}/attributes`, payload);
};

const updateDeviceLocation = async (payload: UpdateDeviceLocationPayload) => {
  return await restApiClient.patch(`/devices/${payload.serialNumber}/location`, payload);
};

const updateRfSyncInfo = async (payload: UpdateRfSyncInfoPayload) => {
  return await restApiClient.patch(`/devices/${payload.serialNumber}/rfSync`, payload.rfSyncInfo);
};

const deleteRfSyncInfo = async (serialNumber: string) => {
  return await restApiClient.delete(`/devices/${serialNumber}/rfSync`);
};

export type { SetAttributeParams };
export {
  getDevice,
  getDeviceActivities,
  getInstalledApps,
  checkForUpdate,
  requestAttributeChange,
  updateDeviceLocation,
  updateApps,
  updateRfSyncInfo,
  deleteRfSyncInfo,
};
