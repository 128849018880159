import { Address } from 'models';

const koreanAddressFormatter = (address: Address): string => {
  const fullAddress = [
    address.State || '',
    address.City || '',
    address.Street || '',
    address.Street2 || '',
    address.BuildingName || '',
    address.Unit || '',
    address.Floor ? `${address.Floor.toString()}층` : '',
    address.Extra || '',
  ]
    .filter((token: string) => token.trim().length > 0)
    .join(' ');

  return address.Zipcode ? `${fullAddress} (${address.Zipcode})` : fullAddress;
};

const englishAddressFormatter = (address: Address): string => {
  return [
    address.Unit || '',
    address.BuildingName || '',
    address.Floor ? `${address.Floor.toString()}F` : '',
    address.Street || '',
    address.Street2 || '',
    address.State || '',
    address.City || '',
    address.Zipcode || '',
    address.Extra || '',
  ]
    .filter((token: string) => token.trim().length > 0)
    .join(' ');
};

function addressFormatter(address: Address, locale?: string): string {
  switch (locale) {
    case 'en-US':
      return englishAddressFormatter(address);
    default:
      return koreanAddressFormatter(address);
  }
}

export { addressFormatter };
