import React from 'react';

import { Box, styled, Typography } from '@mui/material';

// 헤더 스타일
const Header = styled('header')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'fixed',
  top: '0',
  left: '0',
  height: '80px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 32px',
  borderBottom: `1px solid ${theme.palette.gray[200]}`,
  color: theme.palette.gray[800],
  zIndex: 101,
}));

const HeaderWithTitle = ({
  adornedStart,
  adornedEnd,
  pageTitle,
}: {
  adornedStart?: React.ReactNode;
  adornedEnd?: React.ReactNode;
  pageTitle: string;
}) => {
  return (
    <Header>
      <Box sx={{ minWidth: '220px' }}>{adornedStart}</Box>
      <Typography variant="h4">{pageTitle}</Typography>
      <Box sx={{ minWidth: '220px' }}>{adornedEnd}</Box>
    </Header>
  );
};

export default HeaderWithTitle;
