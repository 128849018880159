import { styled } from '@mui/material';

export const AddressType = styled('span')(({ theme }) => ({
  ...theme.typography.body5,
  display: 'inline-block',
  textAlign: 'center',
  minWidth: '48px',
  height: '20px',
  borderRadius: '4px',
  backgroundColor: theme.palette.gray[100],
  color: theme.palette.gray[600],
  border: `1px solid ${theme.palette.gray[300]}`,
  marginRight: '8px',
  lineHeight: '18px',
}));
