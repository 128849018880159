import RestApiClient from 'lib/rest-api-client';
import { Address } from 'models';

interface SearchAddress {
  City: string;
  BuildingName: string;
  State: string;
  Street: string;
}

class AddressSearchService {
  private readonly apiClient: RestApiClient;
  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = process.env.REACT_APP_ADDRESS_SERVICE_API_URL || '';
    this.apiClient = new RestApiClient({ baseUrl: this.baseUrl });
  }

  /**
   * search 주어진 검색어를 이용해 주소를 검색합니다.
   * invokes search API with the given keyword.
   * @param keyword 검색할 주소 (a string that contains keyword to search addresses).
   * @return Address 배열을 리턴합니다 (an array of Address).
   */
  async searchAll(keyword: string): Promise<Address[]> {
    const searchAddressUrl = `/addresses?q=${keyword}`;

    try {
      const response = await this.apiClient.get<{ items: Address[] }>(searchAddressUrl);
      return response.data.items;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  /**
   * search 주어진 검색어를 이용해 유일한 주소를 검색합니다.
   * invokes address search API with the given keyword.
   * @param keyword 검색할 주소 (a string that begin with keyword to search addresses).
   * @return Address 배열을 리턴합니다 (an array of Address).
   */
  async searchForFilter(keyword?: string): Promise<SearchAddress[]> {
    let searchAddressUrl = '/addresses/search';
    if (keyword) {
      searchAddressUrl += `?q=${keyword}`;
    }

    try {
      const response = await this.apiClient.get<{ items: SearchAddress[] }>(searchAddressUrl);
      return response.data.items;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  /**
   * search 주어진 검색어를 이용해 유일한 주소를 검색합니다.
   * invokes address search API with the given keyword.
   * @param keyword 검색할 주소 (a string that begin with keyword to search addresses).
   * @return Address 배열을 리턴합니다 (an array of Address).
   */
  async buildingSearchForFilter(keyword?: string): Promise<SearchAddress[]> {
    let searchAddressUrl = '/buildings';
    if (keyword) {
      searchAddressUrl += `?q=${keyword}`;
    }

    try {
      const response = await this.apiClient.get<{ items: SearchAddress[] }>(searchAddressUrl);
      return response.data.items;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  async patchCoordinates(buildingIds: string[]): Promise<void> {
    try {
      await this.apiClient.patch('/buildings/coordinates', { buildingIds });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export { AddressSearchService };
export type { SearchAddress };
