import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import deviceGroupReducer from 'features/device-groups/device-group-slice';
import userReducer from 'features/user/user-slice';
import notificationReducer from 'features/notification/notification-slice';
import navigationReducer from 'features/navigation/navigation-slice';
import promotionReducer from 'features/promotions/promotion-slice';
import complexCreatorReducer from 'features/complex/new-complex-editor-slice';
import devicesReducer from 'features/devices/devices-slice';
import deviceEditorReducer from 'features/devices/device-editor-slice';
import locationsReducer from 'features/locations/locations-slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    notification: notificationReducer,
    navigation: navigationReducer,
    deviceGroups: deviceGroupReducer,
    promotions: promotionReducer,
    complexCreator: complexCreatorReducer,
    devices: devicesReducer,
    deviceEditor: deviceEditorReducer,
    locations: locationsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
