import { useState, useEffect } from 'react';

import { styled, Button, Stack, Typography, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import { LocationForm } from 'components/locations';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { addLocationForm, removeLocation, selectLocations, setOpenStatusAll } from 'features/locations/locations-slice';
import { showError } from 'features/notification/notification-slice';

const AddButton = styled((props: any) => <Button {...props} color="gray" />)(({ theme }) => ({
  height: '40px',
  border: `1px solid ${theme.palette.gray[300]}`,
  borderRadius: '100px',
  padding: '13px 16px',
  fontSize: '14px',
  fontWeight: '500',
  color: theme.palette.gray[800],
  backgroundColor: theme.palette.common.white,
}));

const AddTextField = styled(TextField)(({ theme }) => ({
  width: '183px',
  backgroundColor: theme.palette.common.white,
  input: {
    textAlign: 'right',
  },
}));

// clearable : 입력폼이 1개일때 삭제가능한지 여부
const LocationsStep = ({ canEmpty }: { canEmpty: boolean }) => {
  const { complex, locations } = useAppSelector(selectLocations);
  const dispatch = useAppDispatch();

  const [addCount, setAddCount] = useState<number | string>('');

  const onAddClick = (count: any) => {
    if (!count) return;
    dispatch(setOpenStatusAll(false));
    if (locations.length + Number(count) > 30) {
      dispatch(showError({ message: '상세 위치는 최대 30개까지 추가 가능합니다.' }));
    }
    dispatch(addLocationForm(Number(count)));
  };

  const onRemoveClick = (index: number) => {
    dispatch(removeLocation(index));
  };

  const onAddCountChange = (e: any) => {
    // 숫자만 입력되도록 함
    if (!/^[0-9]+$/.test(e.target.value)) {
      e.target.value = e.target.value.replace(/[^0-9.]/g, '');
    }
    // 성능문제로 최대 갯수 제한 필요
    if (e.target.value > 30) e.target.value = 30;
    if (e.target.value < 1) e.target.value = '';
    setAddCount(e.target.value);
  };

  const onRemove = () => {
    return canEmpty || locations.length > 1;
  };

  useEffect(() => {
    if (locations.length === 0) dispatch(addLocationForm(1));
  }, []);

  return (
    <div>
      {/* 갯수, 전체 펼치기/접기 */}
      <Stack
        sx={{ color: 'gray.600', marginBottom: '24px' }}
        direction="row"
        alignItems={'center'}
        justifyContent="space-between"
      >
        <Typography variant="subtitle2">총 {locations.length}개</Typography>
        {locations.every((location) => location.openStatus === false) ? (
          <Button
            variant="text"
            sx={{ color: 'gray.700', '&:hover': { backgroundColor: 'transparent' } }}
            startIcon={<UnfoldMoreIcon />}
            onClick={() => dispatch(setOpenStatusAll(true))}
            disabled={locations.length < 2}
          >
            전체 펼치기
          </Button>
        ) : (
          <Button
            sx={{ color: 'gray.700', '&:hover': { backgroundColor: 'transparent' } }}
            variant="text"
            startIcon={<UnfoldLessIcon />}
            onClick={() => dispatch(setOpenStatusAll(false))}
            disabled={locations.length < 2}
          >
            전체 접기
          </Button>
        )}
      </Stack>

      {/* 상세위치 폼 */}
      {locations.map((location, idx) => (
        <LocationForm
          locationItem={location}
          buildingType={complex.type}
          index={idx}
          key={idx}
          foldable
          onRemove={onRemove() ? () => onRemoveClick(idx) : undefined}
        />
      ))}

      {/* 상세위치 추가 버튼 */}
      <Stack direction={'row'} spacing={'8px'} pb={'48px'} justifyContent="flex-end" sx={{ width: '754px' }}>
        <AddButton disabled={locations.length === 30} onClick={() => onAddClick(1)}>
          <AddIcon sx={{ width: '20px', height: '20px', marginRight: '4px' }} />
          1개
        </AddButton>
        <AddButton disabled={locations.length === 30} onClick={() => onAddClick(3)}>
          <AddIcon sx={{ width: '20px', height: '20px', marginRight: '4px' }} />
          3개
        </AddButton>
        <AddButton disabled={locations.length === 30} onClick={() => onAddClick(5)}>
          <AddIcon sx={{ width: '20px', height: '20px', marginRight: '4px' }} />
          5개
        </AddButton>
        <AddTextField
          disabled={locations.length === 30}
          value={addCount}
          onChange={onAddCountChange}
          placeholder="위치 수 입력"
        />
        <Button
          disabled={locations.length === 30}
          color="secondary"
          variant="contained"
          onClick={() => {
            onAddClick(addCount);
            setAddCount('');
          }}
        >
          추가
        </Button>
      </Stack>
    </div>
  );
};

export default LocationsStep;
