import { PromotionPageTitle } from 'components/promotions-list/PromotionPageTitle';
import { PromotionTable } from 'components/promotions-list/PromotionTable';
import Layout from 'components/shared/layout/Layout';
import { useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import { resetKeyword } from 'features/promotions/promotion-slice';

const PromotionListPage = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetKeyword());
    };
  }, []);

  return (
    <Layout>
      <PromotionPageTitle />
      <PromotionTable />
    </Layout>
  );
};

export { PromotionListPage };
