import AppRouter from './AppRouter';
import { WebSocketListener } from './components/shared/WebSocketListener';
import { OktaAuth, toRelativeUrl, UserClaims } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useCallback } from 'react';
import { UserProfile } from './models';
import { setUserProfile } from './features/user/user-slice';
import { useAppDispatch } from './app/hooks';
import { useNavigate } from 'react-router-dom';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER || '',
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID || '',
  redirectUri: `${window.location.origin}/login/callback`,
});

const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const setUser = (user: UserClaims): void => {
    if (user) {
      const profile: UserProfile = {
        email: user.email || '',
        lastName: user.family_name || '',
        firstName: user.given_name || '',
        locale: user.locale || '',
        timezone: user.zoneinfo || '',
      };
      dispatch(setUserProfile(profile));
    }
  };

  const restoreOriginalUri = useCallback(async (oktaAuth: OktaAuth, originalUri: string) => {
    setUser(await oktaAuth.getUser());

    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  }, []);

  return (
    <>
      <WebSocketListener />
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <AppRouter />
      </Security>
    </>
  );
};

export default App;
