import { MediaDeviceType } from './device-type';

enum BuildingType {
  UNKNOWN = 0,
  APARTMENT = 1, // 아파트
  OFFICE = 2, // 오피스
  SUBWAY = 3, // 지하철
  CAMPUS = 4, // 캠퍼스
  CULTURAL_FACILITIES = 5, // 복합문화시설
  OTHER = 6, // 기타
}

/**
 * 엘리베이터 내/외부
 */
enum InstalledOn {
  Unknown = 0, // 지정하지 않음
  Interior = 1, // 내부
  Exterior = 2, // 외부
}

/**
 * 엘리베이터 설치 위치 구분
 */
enum LocationType {
  Unknown = 0, // 타입없음
  Residential = 1, // 거주동
  Public = 2, // 주민공동시설
  Commercial = 3, // 상가
  Management = 4, // 관리시설
  Exterior = 5, // 외부건물
}

/**
 * 상세 위치 등록시 요청 페이로드인 상세위치데이타를 표현하는 모델입니다
 * @prop {InstalledOn} installedOn 엘리베이트 내/외부 설치
 * @prop {number} locationType 엘리베이트 구분
 * @prop {string} sector 동
 * @prop {string} zone 구역
 * @prop {string} floor 층
 * @prop {string} liftIndex 호기
 * @prop {string} line 지하철 호선
 * @prop {string} note 기타설명
 */
interface CreateComplexLocationPayload {
  installedOn: InstalledOn; // 엘리베이터 내,외부
  locationType?: LocationType; // 엘리베이터 설치위치 구분
  sector?: string; // 동
  zone?: string; // 구역
  floor?: string; // 층
  liftIndex: string; // 호기
  line?: string; // 호선
  note?: string; // 기타
}

interface Building {
  id: string;
  province: string;
  city: string;
  street: string;
  buildingName: string;
  zipcode: string;
  jibunAddress: string;
  roadAddress: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}

/**
 * gets the paginated list of complexes
 * @param size the number of items to be fetched
 * @param nextPageToken a token that indicates the page to be fetched
 */
interface GetComplexesParams {
  size: number;
  nextPageToken?: string;
}

/**
 * gets the paginated list of complexes result
 * @param size the number of items to be fetched
 * @param page the page number to be fetched
 * @param keyword the keyword to search
 * @param signal an AbortSignal object coming from react-query that can be used to abort the request
 */
interface SearchComplexesParams {
  size: number;
  page: number;
  keyword: string;
  signal?: AbortSignal | undefined;
}

interface Complex {
  id: string;
  name: string;
  building: Building;
  buildingType: BuildingType;
  locations: number;
  devices: number;
}

interface ComplexListResponse {
  items: Complex[];
  nextPageToken?: string;
  nextPage?: number;
  total: number;
}

interface ComplexDetailResponse {
  id: string;
  name: string;
  building: Building;
  buildingType: BuildingType;
  modifiedAt: string;
  details: Location[];
}

interface Location {
  location: {
    locationId: string; // 상세위치 id
    attributes: {
      installedOn: InstalledOn; // 엘리베이터 내/외부
      locationType?: LocationType; // 엘리베이터 구분(선택없음, 거주동, 주민공동시설, 상가, 관리시설, 외부건물)
      sector?: string; // 동
      zone?: string; // 구역
      floor?: string; // 층
      line?: string; // 지하철 호선
      liftIndex?: string; // 엘리베이터 호기
      note?: string; // 기타
    };
  };
  device: {
    serialNumber: string | null;
    deviceType: MediaDeviceType | null;
    shadow: object | null;
    hardware: object | null;
    coordinates: {
      lat: number;
      lng: number;
    };
  };
  mappedAt?: string; // 매핑된 매체가 없을 경우 값 null
  mappedBy?: string; // 매핑된 매체가 없을 경우 값 null
}

interface LocationParams {
  complexId: string;
  locationId: string;
  serialNumber?: string;
}

interface LocationsParams {
  complexId: string;
  locationIdArray: string[];
  serialNumber?: string;
}

// 기본위치 등록
interface PostComplexPayload {
  name: string;
  building: MoisBuilding;
  type: number;
}

// 기본위치 수정
interface PutComplexPayload {
  complexId: string;
  data: {
    name: string;
    building: MoisBuilding | null;
    type: number;
  };
}

/**
 * 행안부 주소 검색 API의 건물을 표현하는 모델입니다
 * @prop {string} admCd 행정구역코드
 * @prop {string} bdKdcd 공동주택여부 (1: 공동주택, 0: 비공동주택)
 * @prop {string} bdMgtSn 건물관리번호
 * @prop {string} bdNm 건물명
 * @prop {string} buldMnnm 건물본번
 * @prop {string} buldSlno 건물부번 (부번이 없는 경우 0)
 * @prop {string} detBdNmList 상세건물명
 * @prop {string} emdNm 읍면동명
 * @prop {string} emdNo 읍면동일련번호
 * @prop {string} engAddr 도로명주소(영문)
 * @prop {string} jibunAddr 지번 정보
 * @prop {string} liNm 법정리명
 * @prop {string} lnbrMnnm 지번본번(번지)
 * @prop {string} lnbrSlno 지번부번(호) (부번이 없는 경우 0)
 * @prop {string} mtYn 산여부 (0:대지, 1:산)
 * @prop {string} rn 도로명
 * @prop {string} rnMgtSn 도로명코드
 * @prop {string} roadAddr 전체 도로명주소
 * @prop {string} roadAddrPart1 도로명주소(참고항목 제외)
 * @prop {string} roadAddrPart2 도로명주소 참고항목
 * @prop {string} sggNm 시군구명
 * @prop {string} siNm 시도명
 * @prop {string} udrtYn 지하여부 (0:지상, 1:지하)
 * @prop {string} zipNo 우편번호
 */
interface MoisBuilding {
  admCd?: string;
  bdKdcd?: string;
  bdMgtSn?: string;
  bdNm?: string;
  buldMnnm?: string;
  buldSlno?: string;
  detBdNmList?: string;
  emdNm?: string;
  emdNo?: string;
  engAddr?: string;
  jibunAddr?: string;
  liNm?: string;
  lnbrMnnm?: string;
  lnbrSlno?: string;
  mtYn?: string;
  rn?: string;
  rnMgtSn?: string;
  roadAddr?: string;
  roadAddrPart1?: string;
  roadAddrPart2?: string;
  sggNm?: string;
  siNm?: string;
  udrtYn?: string;
  zipNo?: string;
  lat?: number;
  lng?: number;
}

export { BuildingType, InstalledOn, LocationType };
export type {
  Building,
  Complex,
  CreateComplexLocationPayload,
  GetComplexesParams,
  SearchComplexesParams,
  ComplexListResponse,
  ComplexDetailResponse,
  Location,
  LocationParams,
  LocationsParams,
  PostComplexPayload,
  MoisBuilding,
  PutComplexPayload,
};
