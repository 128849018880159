import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { UserProfile } from 'models/user-profile';

interface UserState {
  profile: UserProfile | undefined;
}

const initialState: UserState = {
  profile: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile: (state: Draft<UserState>, action: PayloadAction<UserProfile | undefined>) => {
      state.profile = action.payload;
    },
  },
});

// user selector
const selectUser = (state: RootState) => state.user;

export const { setUserProfile } = userSlice.actions;
export { selectUser, userSlice };
export type { UserState };

export default userSlice.reducer;
