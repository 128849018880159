import { useState, ChangeEvent } from 'react';

import * as uuid from 'uuid';

import { Md5 } from 'ts-md5';

import { BuildingType, DeviceLocation, MediaDeviceType, InstalledOn, LocationType } from 'models';

/**
 * debounce 함수는 지정된 시간 후에 callback 함수를 호출합니다
 * (invokes callback after the given delay)
 * @param callback 콜백 함수 (a callback function to be called after the delay.
 * @param delay 밀리초 단위의 지연 시간 (amount of time to be delayed in milliseconds).
 */
const debounce = (callback: () => void, delay: number) => {
  let timer: string | number | NodeJS.Timeout | undefined;
  return () => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => callback(), delay);
  };
};

/**
 * useInput 은 HTMLInputElement의 state와 value를 업데이트 하는 함수를 반환합니다.
 * @param initialState state의 초기값.
 * @param validator onChange 시 setState를 하기전 유효성 검사를 진행하는 함수
 */
function useInput(initialState: any, validator?: (value: string) => boolean) {
  const [value, setValue] = useState(initialState);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    if (typeof validator === 'function') {
      const willData = validator(value);
      if (willData) setValue(value);
    } else setValue(value);
  };
  return [value, onChange, setValue];
}

/**
 * download a given csvContent as a CSV file with a given file name
 * @param csvContent CSV Content to download
 * @param fileName A name of CSV file. It will automatically be generated if not specified.
 */
const downloadAsCsv = (csvContent: string, fileName?: string): void => {
  // should add this BOM in order to retain 2-byte values in the CSV
  const path = fileName || `${uuid.v4()}.csv`;

  const anchor = document.createElement('a');
  anchor.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURI(csvContent)}`);
  anchor.setAttribute('download', path);
  anchor.click();
};

const formatDeviceType = (deviceType: MediaDeviceType): string => {
  switch (deviceType) {
    case MediaDeviceType.Portrait29:
      return '29형 세로/25형 세로 1세대';
    case MediaDeviceType.Portrait25Gen2:
      return '25형 세로 2세대';
    case MediaDeviceType.Landscape27Gen1:
      return '27형 가로 1세대';
    case MediaDeviceType.LandscapePortrait32Gen2:
      return '32형 2세대';
    default:
      // 매체 타입 형식에 맞지 않는 타입은 그대로 출력
      return String(deviceType);
  }
};

interface DeviceLocationFormatOption {
  hideZipcode?: boolean;
  hideDetails?: boolean;
  detailsOnly?: boolean;
}

const formatDeviceLocation = (location: DeviceLocation, option?: DeviceLocationFormatOption): string => {
  if (!location || !location.id) {
    return '';
  }

  return [
    option?.hideZipcode || option?.detailsOnly ? '' : location.zipcode ? `(${location.zipcode})` : '',
    option?.detailsOnly ? '' : location.province,
    option?.detailsOnly ? '' : location.city,
    option?.detailsOnly ? '' : location.street,
    option?.detailsOnly ? '' : location.buildingName,
    option?.hideDetails ? '' : location.locationAttributes.sector,
    option?.hideDetails ? '' : location.locationAttributes.zone,
    option?.hideDetails ? '' : location.locationAttributes.floor,
    option?.hideDetails ? '' : location.locationAttributes.liftIndex,
    option?.hideDetails ? '' : location.locationAttributes.line,
  ]
    .filter((segment: string | undefined) => !!segment)
    .join(' ')
    .trim();
};

const convertDateStringToEpoch = (date?: string): number => {
  if (!date) {
    return Math.trunc(new Date().getTime() / 1000);
  }

  return Math.trunc(new Date(Date.parse(date)).getTime() / 1000);
};

// buildingTypeFormatter
const buildingTypeFormatter = (buildingType: BuildingType): string => {
  switch (buildingType) {
    case BuildingType.APARTMENT:
      return '아파트';
    case BuildingType.CAMPUS:
      return '캠퍼스';
    case BuildingType.CULTURAL_FACILITIES:
      return '복합문화공간';
    case BuildingType.OFFICE:
      return '오피스';
    case BuildingType.SUBWAY:
      return '지하철';
    case BuildingType.OTHER:
      return '기타';
    default:
      return '';
  }
};

const installedOnFormatter = (installedOn: InstalledOn | undefined): string => {
  switch (installedOn) {
    case InstalledOn.Unknown:
      return '선택없음';
    case InstalledOn.Interior:
      return '내부';
    case InstalledOn.Exterior:
      return '외부';
    default:
      return '';
  }
};

const locationTypeFormatter = (locationType: LocationType | undefined): string => {
  switch (locationType) {
    case LocationType.Unknown:
      return '선택없음';
    case LocationType.Residential:
      return '거주동';
    case LocationType.Public:
      return '주민공동시설';
    case LocationType.Commercial:
      return '상가';
    case LocationType.Management:
      return '관리시설';
    case LocationType.Exterior:
      return '외부건물';
    default:
      return '';
  }
};

/**
 * 객체의 모든 문자열 값의 앞,뒤 공백을 제거합니다.
 * @param obj
 */
const trimAllValues = (obj: any) => {
  const copy = { ...obj };
  for (const key in copy) {
    if (typeof copy[key] === 'string') {
      copy[key] = copy[key].trim();
    }
  }

  return copy;
};

/**
 * 키-값으로 이루어진 객체로 hashId를 생성합니다.
 * @param obj : hashId를 생성하고자 하는 객체
 * @returns {string} hashId
 */
const generateHashId = (obj: any): string => {
  if (!obj) return '';
  const key = JSON.stringify(obj);
  return Md5.hashStr(key);
};

/**
 * 입력받은 두 객체가 같은지 검사하는 함수
 * 두 객체는 동일한 갯수, 동일한 이름의 키를 가져야 합니다.
 * @param x,y : 비교하고자 하는 두 객체
 * @returns {boolean}
 */
const isEqual = (x: any, y: any): boolean => {
  return generateHashId(x) === generateHashId(y);
};

export type { DeviceLocationFormatOption };
export {
  buildingTypeFormatter,
  installedOnFormatter,
  locationTypeFormatter,
  convertDateStringToEpoch,
  debounce,
  downloadAsCsv,
  formatDeviceType,
  formatDeviceLocation,
  useInput,
  generateHashId,
  isEqual,
  trimAllValues,
};
