import { useEffect } from 'react';

import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { TreeItem, treeItemClasses, TreeItemProps, TreeView } from '@mui/lab';
import { Box, Paper, styled, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import Layout from 'components/shared/layout/Layout';
import { getDeviceGroupsAsync, selectDeviceGroups } from 'features/device-groups/device-group-slice';
import { DeviceGroup } from 'models';

const StyledTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(() => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.content}`]: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: 'transparent',
    },
  },
}));

const DeviceGroupListPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const deviceGroups = useAppSelector(selectDeviceGroups);

  useEffect(() => {
    if (!Array.isArray(deviceGroups.items)) {
      dispatch(getDeviceGroupsAsync());
    }
  }, [deviceGroups.items]);

  const renderGroup = (group: DeviceGroup): JSX.Element => {
    return (
      <StyledTreeItem
        key={group.id}
        nodeId={group.id}
        color="#000000"
        label={
          <Paper variant="outlined" elevation={0} sx={{ margin: '5px 0' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0, margin: '5px 0' }}>
              <Box color="inherit" sx={{ mr: 1 }} />
              <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                {group.displayName}
              </Typography>
            </Box>
          </Paper>
        }
      >
        {group.children?.map((child: DeviceGroup) => renderGroup(child))}
      </StyledTreeItem>
    );
  };

  return (
    <Layout>
      <TreeView
        defaultCollapseIcon={<ArrowDropDown />}
        defaultExpandIcon={<ArrowRight />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        {deviceGroups.loadingStatus === 'idle' &&
          Array.isArray(deviceGroups.items) &&
          deviceGroups.items.length > 0 &&
          deviceGroups.items.map((group: DeviceGroup) => renderGroup(group))}
        {deviceGroups.loadingStatus === 'idle' && (deviceGroups.items || []).length === 0 && (
          <Typography>매체 그룹이 없습니다</Typography>
        )}
        {deviceGroups.loadingStatus === 'loading' && <Typography>매체 그룹을 로딩 중입니다</Typography>}
      </TreeView>
    </Layout>
  );
};

export { DeviceGroupListPage };
