import { styled } from '@mui/material';

const Title = styled('span')(({ theme }) => ({
  display: 'inline-block',
  fontSize: '14px',
  fontWeight: '400',
  color: theme.palette.gray[700],
  marginBottom: '8px',
}));

const FmkFormLabel = ({ title, required }: { title: string; required?: boolean }) => {
  const requiredStyle = {
    '&:after': {
      content: "'*'",
      fontSize: '14px',
      fontWeight: '500',
      color: 'error.main',
      marginLeft: '4px',
    },
  };
  return <Title sx={required ? requiredStyle : {}}>{title}</Title>;
};

export { FmkFormLabel };
