import RestApiClient from 'lib/rest-api-client';
import { NewPromotionPayload, UpdatePromotionPayload } from './promotion-slice';

const restApiClient = new RestApiClient({
  baseUrl: process.env.REACT_APP_HESTIA_SERVICE_API_URL || '',
  timeout: 5000,
});

const getPromotion = async (promotionId: string) => {
  return await restApiClient.get(`/promotions/${promotionId}`, { timeout: 10 * 1000 });
};

const getPromotionList = async (page: number, keyword?: string) => {
  const params = keyword ? { page, keyword } : { page };
  return await restApiClient.get('/promotions', { params, timeout: 10 * 1000 });
};

const getS3PreSignedUrl = async (path: string) => {
  return await restApiClient.get('/signed-urls', { params: { path } });
};

const postPromotion = async (payload: NewPromotionPayload) => {
  return await restApiClient.post('/promotions', payload);
};

const patchPublish = async (promotionId: string, publish: boolean) => {
  return await restApiClient.patch(`/promotions/${promotionId}`, { publish });
};

const putPromotion = async (payload: UpdatePromotionPayload, promotionId: string) => {
  return await restApiClient.put(`/promotions/${promotionId}`, payload);
};

const deletePromotion = async (promotionId: string) => {
  return await restApiClient.delete(`/promotions/${promotionId}`);
};

export {
  getPromotionList,
  getS3PreSignedUrl,
  postPromotion,
  patchPublish,
  putPromotion,
  getPromotion,
  deletePromotion,
};
