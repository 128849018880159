import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment/moment';
import axios from 'axios';
import { v4 } from 'uuid';

import { getS3PreSignedUrl } from 'features/promotions/promotion-api';
import {
  deletePromotionAsync,
  getPromotionAsync,
  ProductList,
  putPromotionAsync,
  selectPromotions,
  UpdatePromotionPayload,
} from 'features/promotions/promotion-slice';
import Layout from 'components/shared/layout/Layout';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useInput } from 'lib/utils';
import { FileInput, Item, RequiredMark } from 'components/promotions-form';
import { ErrorOutline } from '@mui/icons-material';

const START_DATE = moment(new Date().setHours(5, 0)).format('YYYY-MM-DDTHH:mm');
const END_DATE = moment(new Date().setHours(23, 55)).format('YYYY-MM-DDTHH:mm');

const BANNER_FILE_SIZE_MAX_LIMIT = 9 * 100 * 1024;
const MAX_NAME_INPUT = 50;
const MAX_TEXT_INPUT = 11;
const CSV_FILE_NAME = '분기페이지_배너_노출_단지_리스트.csv';

const s3Upload = async (file: File, uuid: string) => {
  const path = `${uuid}/${file.name}`;
  const { data } = await getS3PreSignedUrl(path);

  // S3 upload 할 때 Authorization 값을 제거해야 정상적으로 업로드 됩니다.
  delete axios.defaults.headers.common.Authorization;
  await axios.put(data.signedUrl, file);

  return path;
};

const convertDateFormat = (date: string) => {
  return moment(new Date(date)).format('YYYY-MM-DDTHH:mm');
};

const PromotionUpdatePage = (): JSX.Element => {
  const { promotionId } = useParams();
  const { promotion, loadingStatus, isProcessing } = useAppSelector(selectPromotions);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!promotionId) {
      navigate('/promotions');
      return;
    }

    if (loadingStatus === 'idle' && !isProcessing) {
      dispatch(getPromotionAsync(promotionId))
        .unwrap()
        .catch(() => navigate('/promotions'));
    }
  }, []);

  useEffect(() => {
    if (isProcessing) {
      navigate('/promotions');
    }
  }, [isProcessing]);

  const [open, setOpen] = useState(false);
  const [eventName, changeEventName, setEventName] = useInput('', (value) => value.length <= MAX_NAME_INPUT);
  const [bannerObjectKey, setBannerObjectKey] = useState('');
  const [bannerFile, setBannerFile] = useState<File>();
  const [bannerText, setBannerText] = useState(['', '']);
  const [landingUrl, changeLadingUrl, setLandingUrl] = useInput('');
  const [timeFrame, setTimeFrame] = useState({ startDate: START_DATE, endDate: END_DATE });
  const [buildingListFile, setBuildingListFile] = useState<File>();
  const [displayLocationType, changeDisplayLocationType, setDisplayLocationType] = useInput('product');
  const [note, changeNote, setNote] = useInput('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [displayOrder, setDisplayOrder] = useState<number | undefined>(undefined);
  const [isRegularMember, setRegularMember] = useState<boolean>(false);
  const [productList, setProductList] = useState([
    {
      code: 'S',
      packageNumber: '1',
      name: '서울생활권 H1',
      selected: false,
    },
    {
      code: 'S',
      packageNumber: '2',
      name: '서울생활권 H2',
      selected: false,
    },
    {
      code: 'G',
      packageNumber: '0',
      name: '지역생활권',
      selected: false,
    },
    {
      code: 'B',
      packageNumber: '0',
      name: '부산생활권',
      selected: false,
    },
  ]);

  const initPromotion = () => {
    if (promotion) {
      setEventName(promotion.name);
      setBannerObjectKey(promotion.banner.imageUrl);
      setBannerText([promotion.banner.firstText, promotion.banner.secondText]);
      setNote(promotion.note);
      setLandingUrl(promotion.landingPageUrl);
      setDisplayLocationType(promotion.locationType);
      setRegularMember(promotion.isRegularMember);
      if (promotion.displayOrder) {
        setDisplayOrder(promotion.displayOrder);
      }
      setTimeFrame({
        startDate: convertDateFormat(promotion.timeFrame.startDate),
        endDate: convertDateFormat(promotion.timeFrame.endDate),
      });
      if (promotion.locationType === 'product') {
        const getSelected = (product: ProductList) => {
          for (const item of promotion.displayLocation.productList) {
            if (product.code === item.code && product.packageNumber === item.packageNumber) {
              return true;
            }
          }
          return false;
        };
        setProductList(
          productList.map((product) => ({
            ...product,
            selected: getSelected(product),
          })),
        );
      } else {
        setBuildingListFile(convertBlobToFile(promotion.displayLocation.complex));
      }
    }
  };

  useEffect(() => {
    initPromotion();
  }, [promotion]);

  useEffect(() => {
    setButtonDisabled(isFormIncomplete());
  }, [eventName, bannerObjectKey, bannerText, landingUrl, timeFrame, displayLocationType]);

  const isFormIncomplete = () => {
    return (
      eventName.length === 0 ||
      bannerObjectKey.length === 0 ||
      landingUrl.length === 0 ||
      bannerText[0].length === 0 ||
      bannerText[1].length === 0 ||
      displayLocationType.length === 0
    );
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeFrame({ ...timeFrame, [e.target.id]: e.target.value });
  };

  const handleSeoulAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setProductList(
      productList.map((product) => {
        if (product.code !== 'S') {
          return product;
        }
        return { ...product, selected: event.target.checked };
      }),
    );
  };

  const handleProductListChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProductList(
      productList.map((product) => {
        if (product.code + product.packageNumber === event.target.name) {
          return { ...product, selected: event.target.checked };
        } else {
          return product;
        }
      }),
    );
  };

  const handleBannerText = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number): void => {
    if (event.target.value.length > MAX_TEXT_INPUT) return;

    const newBannerText = [...bannerText];
    newBannerText[index] = event.target.value;
    setBannerText(newBannerText);
  };

  const handleBannerFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const file = (target.files as FileList)[0];

    if (file === undefined) return;

    // 파일 타입 검증
    if (!file.type.startsWith('image/')) {
      target.value = '';
      return alert('이미지만 업로드할 수 있습니다.');
    }
    // 파일 크기 검증
    if (file.size > BANNER_FILE_SIZE_MAX_LIMIT) {
      target.value = '';
      return alert('업로드 가능한 최대 용량은 900KB입니다.');
    }

    setBannerFile(file);
    setBannerObjectKey(file.name);
  };

  const handleBuildingListFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const file = (target.files as FileList)[0];

    // 파일 타입 검증
    if (file.type !== 'text/csv') {
      target.value = '';
      return alert('.csv 파일만 업로드할 수 있습니다.');
    }

    setBuildingListFile(file);
  };

  const isValidHttpUrl = (string: string) => {
    try {
      const url = new URL(string);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
      return false;
    }
  };

  const isValidSubmitData = () => {
    if (!isValidHttpUrl(landingUrl)) {
      return alert('랜딩 URL을 입력해주세요\nhttp:// 또는 https://로 시작해야합니다');
    } else if (timeFrame.startDate > timeFrame.endDate) {
      return alert('노출 시작 시간이 종료 시간 보다 늦습니다\n시작 시간을 종료 시간 이전으로 선택해 주세요');
    } else if (displayLocationType === 'product' && !productList.find((product) => product.selected)) {
      return alert('노출 위치의 상품을 선택해주세요');
    } else if (displayLocationType === 'complex' && !buildingListFile) {
      return alert('개별단지 파일을 업로드해주세요');
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!isValidSubmitData()) return;
    setButtonDisabled(true);
    const uuid = v4();

    let bannerUrl: string | undefined;
    if (bannerFile?.size) {
      bannerUrl = await s3Upload(bannerFile, uuid);
    }

    // 선택한 상품만 리턴할 수 있도록 처리힙니다.
    let products: ProductList[] = [];
    let complexFilePath = '';

    switch (displayLocationType) {
      case 'complex':
        complexFilePath = await s3Upload(buildingListFile!, uuid);
        break;
      case 'product': {
        products = productList
          .filter((product) => product.selected)
          .map((product) => ({ code: product.code, packageNumber: product.packageNumber }));

        break;
      }
    }

    // DB에는 UTC 시간을 저장하기로 했기 때문에 LocalDateTime을 UTC로 변환하여 BE로 보냅니다.
    timeFrame.startDate = new Date(timeFrame.startDate).toISOString();
    timeFrame.endDate = new Date(timeFrame.endDate).toISOString();

    const newPromotion: UpdatePromotionPayload = {
      id: promotion!.id,
      name: eventName.trim(),
      banner: {
        id: promotion!.banner.id,
        imageUrl: bannerUrl || bannerObjectKey,
        published: promotion!.banner.isPublished,
        text: bannerText,
      },
      location: {
        complexListUploadedFilePath: displayLocationType === 'product' ? undefined : complexFilePath,
        productList: displayLocationType === 'product' ? products : undefined,
      },
      landingPageUrl: landingUrl.trim(),
      timeFrame,
      displayOrder,
      note,
      isRegularMember,
    };
    dispatch(putPromotionAsync(newPromotion));
  };

  const generateCsv = () => {
    const csvContent = 'building_id\n\n';
    const downloadUrl = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv;encoding:utf-8' }));

    const anchor = document.createElement('a');
    anchor.href = downloadUrl;
    anchor.setAttribute('download', CSV_FILE_NAME);
    anchor.click();
  };

  const convertBlobToFile = (complexes: string[]) => {
    const csvContent = `building_id\n${complexes ? complexes.join('\n') : '\n'}`;
    const type = 'text/csv;encoding:utf-8';
    const csv = new Blob([csvContent], { type });

    return new File([csv], CSV_FILE_NAME, { type });
  };

  const openDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id: string) => {
    setButtonDisabled(true);
    dispatch(deletePromotionAsync(id));
  };

  const handleDisplayOrder = (event: SelectChangeEvent<string>) => {
    setDisplayOrder(Number(event.target.value) || undefined);
  };

  const handleRegularMember = (event: ChangeEvent<HTMLInputElement>) => {
    setRegularMember(event.target.checked);
  };

  return (
    <Layout>
      {loadingStatus === 'loading' && <LinearProgress />}
      {loadingStatus === 'idle' && promotion && (
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
              <Item sx={{ width: '50%' }}>
                <Typography variant="body1">
                  이벤트 이름 <RequiredMark />
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="글자수 제한 : 빈 칸 포함 50자까지"
                  onChange={changeEventName}
                  value={eventName}
                />
              </Item>
              <Item>
                <Typography variant="body1">
                  배너 이미지 <RequiredMark />
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="body1">{bannerObjectKey.split('/').pop()}</Typography>
                  <label htmlFor="bannerImg">
                    <FileInput>배너 이미지 업로드( 정사각형 비율, 최대 용량 900KB)</FileInput>
                  </label>
                  <input
                    type="file"
                    onChange={handleBannerFileChange}
                    accept="image/*"
                    name="bannerImg"
                    id="bannerImg"
                    style={{ display: 'none' }}
                  />
                </Stack>
              </Item>
              <Item sx={{ width: '50%' }}>
                <Stack direction="column" spacing={2}>
                  <Typography variant="body1">
                    배너 텍스트 <RequiredMark />
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="첫 번째 줄 / 글자수 제한 : 빈 칸 포함 11자까지"
                    onChange={(e) => handleBannerText(e, 0)}
                    value={bannerText[0]}
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="두 번째 줄 / 글자수 제한 : 빈 칸 포함 11자까지"
                    onChange={(e) => handleBannerText(e, 1)}
                    value={bannerText[1]}
                  />
                </Stack>
              </Item>
              <Item sx={{ width: '50%' }}>
                <Typography variant="body1">
                  랜딩 URL <RequiredMark />
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="랜딩 URL"
                  onChange={changeLadingUrl}
                  value={landingUrl}
                />
              </Item>
              <FormControlLabel
                control={<Checkbox checked={isRegularMember} onChange={handleRegularMember} name="isRegularMemeber" />}
                label="정회원 처리"
              />
              <Item sx={{ width: '50%' }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                  <Item>
                    <Typography variant="body1">
                      노출 시작 일시 <RequiredMark />
                    </Typography>
                    <TextField
                      type="datetime-local"
                      id="startDate"
                      variant="outlined"
                      onChange={handleDateChange}
                      value={timeFrame.startDate}
                      inputProps={{ max: timeFrame.endDate }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Item>
                  <Item>
                    <Typography variant="body1">
                      노출 종료 일시 <RequiredMark />
                    </Typography>
                    <TextField
                      type="datetime-local"
                      id="endDate"
                      variant="outlined"
                      onChange={handleDateChange}
                      value={timeFrame.endDate}
                      inputProps={{ min: timeFrame.startDate }}
                    />
                  </Item>
                </Stack>
              </Item>
              <Item>
                <Typography variant="body1">노출 순서</Typography>
                <Select value={String(displayOrder)} onChange={handleDisplayOrder}>
                  <MenuItem key={0} value={''}>
                    선택안함
                  </MenuItem>
                  {Array.from({ length: 10 }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </Item>
              <Item sx={{ width: '50%' }}>
                <Typography variant="body1">
                  노출 위치 <RequiredMark />
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={displayLocationType}
                    onChange={changeDisplayLocationType}
                  >
                    <FormControlLabel value="product" control={<Radio />} label="상품 선택" />
                    <FormControlLabel value="complex" control={<Radio />} label="개별 단지" />
                  </RadioGroup>
                </FormControl>
                {displayLocationType === 'product' ? (
                  <Item>
                    {
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={productList[0].selected && productList[1].selected}
                                onChange={handleSeoulAllClick}
                                name="seoulAll"
                              />
                            }
                            label="서울생활권 전체"
                          />
                          {productList.map((product) => (
                            <div key={product.code + product.packageNumber}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={product.selected}
                                    onChange={handleProductListChange}
                                    name={product.code + product.packageNumber}
                                  />
                                }
                                label={product.name}
                              />
                            </div>
                          ))}
                        </FormGroup>
                      </FormControl>
                    }
                  </Item>
                ) : (
                  <Item>
                    <Stack direction="row" alignItems="center" spacing={3}>
                      <Typography variant="body2">{buildingListFile?.name}</Typography>
                      <label htmlFor="buildingExcel">
                        <FileInput>빌딩 아이디 CSV 파일 업로드</FileInput>
                      </label>
                      <input
                        type="file"
                        onChange={handleBuildingListFile}
                        name="buildingExcel"
                        id="buildingExcel"
                        accept=".csv"
                        style={{ display: 'none' }}
                      />
                      <Button onClick={generateCsv}>CSV 포맷 다운로드</Button>
                    </Stack>
                  </Item>
                )}
              </Item>
              <Item sx={{ width: '50%' }}>
                <Typography variant="body1">특이사항</Typography>
                <TextField multiline fullWidth variant="outlined" onChange={changeNote} value={note} />
              </Item>
            </Stack>
          </CardContent>
        </Card>
      )}
      <Item sx={{ marginTop: 4 }}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mb="20px">
          <Box>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              취소
            </Button>
            <Button variant="contained" onClick={() => handleSubmit()} disabled={buttonDisabled} sx={{ ml: '8px' }}>
              수정 완료
            </Button>
          </Box>
          <Button color="error" variant="outlined" onClick={openDialog}>
            배너 삭제
          </Button>
        </Box>
      </Item>
      {promotion && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ borderRadius: '20px' }}
        >
          <DialogTitle id="alert-dialog-title" display="flex" alignItems="center" flexWrap="wrap">
            <Icon component={ErrorOutline} sx={{ color: '#FF3B30' }} />
            <Box component="span" ml="8px">
              배너 삭제
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {promotion.banner.isPublished && (
                <Typography>
                  <strong>{promotion.name}</strong>의 배너를 삭제하면 분기페이지에서 더 이상 보여지지 않으며 이 작업은
                  복구할 수 없습니다.
                  <br /> 그래도 삭제하시겠습니까?
                </Typography>
              )}
              {!promotion.banner.isPublished && (
                <Typography>
                  <strong>{promotion.name}</strong>의 배너를 삭제하시겠습니까?
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>취소</Button>
            <Button
              disabled={buttonDisabled}
              variant="contained"
              color="primary"
              onClick={() => handleDelete(promotion.id)}
            >
              삭제
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Layout>
  );
};

export { PromotionUpdatePage };
