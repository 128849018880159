import RestApiClient from 'lib/rest-api-client';
import { DeviceGroup } from 'models';

const restApiClient = new RestApiClient({
  baseUrl: process.env.REACT_APP_MEDIA_DEVICES_API_URL || '',
  timeout: 5000,
});

const getAll = async (): Promise<DeviceGroup[]> => {
  const response = await restApiClient.get('/device-groups');
  if (response.status === 200) {
    return response.data as DeviceGroup[];
  } else {
    return [];
  }
};

export { getAll };
