import { Dialog, Typography, Stack, styled, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export interface DialogConfig {
  icon?: boolean;
  title?: string;
  children?: React.ReactNode;
  closeButtonText?: string;
  okButtonText?: string;
  onClose?: () => void;
  onOk?: () => void;
}

interface DialogProps extends DialogConfig {
  open: boolean;
}

const DialogButton = styled(Button)(() => ({
  fontWeight: '500',
  fontSize: '16px',
  padding: '16px',
  borderRadius: '8px',
  height: '48px',
}));

const paperProps = {
  sx: {
    width: '444px',
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: '20px',
    padding: '40px',
    margin: 0,
    position: 'fixed',
    top: '120px',
    overflow: 'unset',
  },
};

export const FmkDialog = (props: DialogProps) => {
  const { icon = true, title, onClose, onOk, closeButtonText = '아니요', okButtonText, children, open } = props;

  const handleOk = () => {
    if (onOk) onOk();
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog PaperProps={paperProps} maxWidth="xs" open={open} disableRestoreFocus>
      {title && (
        <Stack mb={'16px'} direction="row" spacing={'10px'} sx={{ alignItems: 'center' }}>
          {icon && <ErrorOutlineIcon sx={{ color: 'error.main' }} />}
          <Typography variant="h5">{title}</Typography>
        </Stack>
      )}
      <Stack>{children}</Stack>
      {onClose && onOk && (
        <Stack direction={'row'} spacing={'16px'} sx={{ justifyContent: 'flex-end', marginTop: '50px' }}>
          <DialogButton color="secondary" onClick={handleClose} variant="text">
            {closeButtonText}
          </DialogButton>
          <DialogButton variant="contained" color="secondary" onClick={handleOk}>
            {okButtonText}
          </DialogButton>
        </Stack>
      )}
    </Dialog>
  );
};
