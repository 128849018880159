import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Divider,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useOktaAuth } from '@okta/okta-react';

import { showError } from 'features/notification/notification-slice';
import { FomigoLogo, OktaLogo, ClearButton } from 'components/icons';
import { Notification } from 'components/shared/Notification';

const loginTheme = createTheme({
  palette: {
    primary: {
      main: '#304FFE',
    },
    secondary: {
      main: '#C9CDD2',
    },
    error: {
      main: '#FF3B30',
    },
    text: {
      primary: '#26282B',
    },
    background: {
      paper: '#F5F7FF',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            bowShadow: '0 0 0 100px #ffffff inset',
            WebkitBoxShadow: '0 0 0 100px #ffffff inset',
          },
        },
      },
      defaultProps: {
        sx: {
          fontSize: '14px',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: '14px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '48px',
        },
        notchedOutline: {
          fontSize: '14px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          height: '48px',
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #304FFE',
          },
          '&:focused': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #304FFE',
          },
          '&:disabled': {
            color: 'white',
            background: '#304FFE',
            opacity: '0.2',
          },
        },
        outlinedSecondary: {
          height: '48px',
          color: '#26282B',
          '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF',
            border: '1px solid #C9CDD2',
          },
          '&:focused': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #F7F8F9',
            border: '1px solid #C9CDD2',
          },
        },
      },
    },
  },
});

// 기존 최상위 위치에 있던 Box 컴포넌트(background-color용)를 대체
// 최대 화면사이즈에서부터 더 작은 breakpoint를 만날 때 mediaquery를 실행
const OuterContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.paper,
  '@media (max-height: 700px)': {
    display: 'block',
    height: '700px',
    padding: '36px 0',
  },
  // mobile size
  [theme.breakpoints.down('md')]: {
    display: 'block',
    height: 'inherit',
    background: theme.palette.background.default,
    padding: '0',
  },
}));

// 기존 Container 컴포넌트(main 요소)를 대체
const InnerContainer = styled('main')(({ theme }) => ({
  boxSizing: 'border-box',
  width: '520px',
  padding: '100px 80px',
  margin: 'auto',
  backgroundColor: '#ffffff',
  boxShadow: '0px 12px 16px rgba(50, 100, 255, 0.08)',
  borderRadius: '16px',
  // mobile size
  [theme.breakpoints.down('md')]: {
    width: '100%',
    maxWidth: '360px',
    minWidth: '320px',
    padding: '80px 24px',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('xs')]: {
    width: '320px',
    padding: '80px 16px 0 16px',
    margin: 0,
  },
}));

const LoginPage = () => {
  const oktaContext = useOktaAuth();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [inputs, setInputs] = useState({ email: '', pw: '' });
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [passwordType, setPasswordType] = useState('password');
  const [hasError, setHasError] = useState(false);
  const [isFocused, setIsFocused] = useState({ email: true, pw: false });

  const emailReg = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+@[A-Za-z0-9_-]+\.[A-Za-z0-9]{2,4}/;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const errorMessage = ['이메일 주소 또는 비밀번호를 잘못 입력했습니다.', '입력하신 내용을 다시 확인해주세요.'].join(
    isMobile ? ' ' : '\n',
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // error 스타일 모습을 확인하기 위해 최소한의 조건을 검사 중
    if (!emailReg.test(inputs.email) || inputs.pw.length < 8 || inputs.pw.length > 25) {
      setHasError(true);
      dispatch(
        showError({
          message: errorMessage,
          duration: 6000,
        }),
      );
    } else {
      setHasError(false);
      const data = new FormData(event.currentTarget);

      // To-do : 로그인 기능 연동 후 console.log 삭제
      console.log({
        email: data.get('email'),
        password: data.get('password'),
      });
    }
  };

  // 사용자 입력값을 검사해 로그인 버튼 활성화 여부를 변경
  const checkInputs = (email?: string, pw?: string) => {
    if (email || email === '') {
      setInputs({ ...inputs, email });
      setLoginDisabled(!emailReg.test(email) || inputs.pw.length < 8);
    } else if (pw || pw === '') {
      setInputs({ ...inputs, pw });
      setLoginDisabled(!emailReg.test(inputs.email) || pw.length < 8);
    }
  };

  const togglePasswordType = () => {
    setPasswordType(passwordType === 'password' ? 'input' : 'password');
  };

  const redirectToOkta = async (): Promise<void> => {
    await oktaContext.oktaAuth.signInWithRedirect({
      scopes: ['openid', 'profile', 'email', 'offline_access'],
    });
  };

  return (
    <ThemeProvider theme={loginTheme}>
      <OuterContainer>
        <InnerContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              margin: 'auto',
            }}
          >
            <FomigoLogo />
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                id="email"
                name="email"
                autoComplete="email"
                value={inputs.email}
                onChange={(event) => checkInputs(event.target.value, undefined)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon
                        sx={{
                          fontSize: '20px',
                          // isFocused와 hasError 모두 충족될 경우 error 상태의 우선순위가 높아야 합니다.
                          ...(isFocused.email && { color: 'text.primary' }),
                          ...(hasError && { color: 'error.main' }),
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton onClick={() => setInputs({ ...inputs, email: '' })} sx={{ marginRight: '-8px' }}>
                      {inputs.email !== '' && <ClearButton />}
                    </IconButton>
                  ),
                }}
                sx={{ mt: 4.6, mb: 1.5 }}
                fullWidth
                autoFocus
                onFocus={() => setIsFocused({ ...isFocused, email: true })}
                onBlur={() => setIsFocused({ ...isFocused, email: false })}
                error={hasError}
                required
              />
              <TextField
                id="password"
                name="password"
                type={passwordType}
                value={inputs.pw}
                onChange={(event) => checkInputs(undefined, event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon
                        sx={{
                          fontSize: '20px',
                          // isFocused와 hasError 모두 충족될 경우 error 상태의 우선순위가 높아야 합니다.
                          ...(isFocused.pw && { color: 'text.primary' }),
                          ...(hasError && { color: 'error.main' }),
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ margin: 0 }}>
                      <IconButton onClick={() => setInputs({ ...inputs, pw: '' })}>
                        {inputs.pw !== '' && <ClearButton />}
                      </IconButton>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordType}
                        edge="end"
                        sx={{ marginLeft: '-8px', marginRight: '-8px' }}
                      >
                        {passwordType === 'password' ? (
                          <VisibilityOutlined sx={{ fontSize: '20px' }} />
                        ) : (
                          <VisibilityOffOutlined sx={{ fontSize: '20px' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onFocus={() => setIsFocused({ ...isFocused, pw: true })}
                onBlur={() => setIsFocused({ ...isFocused, pw: false })}
                error={hasError}
                sx={{ mb: 1.5 }}
                fullWidth
                autoComplete="current-password"
                required
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mb: 1.5 }} disabled={loginDisabled}>
                로그인
              </Button>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: -1 }}>
                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="로그인 상태 유지" />
                <Link href="" color="inherit" underline="none" sx={{ display: 'block' }}>
                  비밀번호 재설정
                </Link>
              </Box>
              <Divider sx={{ mt: 3.8, mb: 3 }} color="secondary">
                <Typography fontSize="16px" color="secondary" height="20px">
                  or
                </Typography>
              </Divider>
              <Button fullWidth color="secondary" variant="outlined" onClick={redirectToOkta}>
                <OktaLogo inheritViewBox sx={{ width: 59, height: 20 }} />
                <Typography ml={2}>옥타 계정으로 로그인</Typography>
              </Button>
            </Box>
          </Box>
        </InnerContainer>
      </OuterContainer>
      <Notification />
    </ThemeProvider>
  );
};

export { LoginPage };
