import { ActionReducerMapBuilder, createAsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import * as uuid from 'uuid';

import { RootState } from 'app/store';
import { showError, showSuccess } from 'features/notification/notification-slice';

import { postComplex } from './complex-api';
import { getS3SignedUrl, putDevicesToComplex, validateUploadedDevices } from './upload-devices-api';

type LoadingStatus = 'idle' | 'loading';

interface ComplexPayload {
  id: string;
  name?: string;
  salesForceId?: string;
  contract: ContractPayload;
  building?: BuildingPayload;
  devices?: DevicesPayload;
  numberOfDevices: number;
}

interface BuildingPayload {
  buildingId: string;
  country: string;
  state: string;
  city: string;
  street: string;
  zipcode: string;
  buildingName: string;
}

interface ContractPayload {
  id: string;
  salesForceId?: string;
  madeAt?: string;
  beginsAt?: string;
  endsAt?: string;
  numberOfBuildings: number;
}

interface ComplexEditorState {
  viewTitle: string;
  payload: ComplexPayload;
  activeStep: number;
  devicesUploadingStatus: LoadingStatus;
  devicesValidated: boolean;
  completingStatus: LoadingStatus | 'done';
}

interface DevicesPayload {
  uploadFilePath?: string;
}

const initialState: ComplexEditorState = {
  viewTitle: '단지 정보',
  payload: {
    id: uuid.v4(),
    numberOfDevices: 1,
    contract: {
      id: uuid.v4(),
      numberOfBuildings: 1,
    },
    devices: {
      uploadFilePath: undefined,
    },
  },
  activeStep: 0,
  devicesUploadingStatus: 'idle',
  devicesValidated: false,
  completingStatus: 'idle',
};

interface UploadDevicesPayload {
  complexId: string;
  file: File;
  numberOfDevices: number;
}

interface ValidateDevicesPayload {
  complexId: string;
  path: string;
  numberOfDevices: number;
}

const postComplexAsync = createAsyncThunk('complex/post', async (payload: ComplexPayload, { dispatch }) => {
  try {
    const response = await postComplex(payload);
    dispatch(showSuccess({ message: '단지를 생성 중입니다. 단지 생성에는 약 5~10분 정도가 소요됩니다' }));
    return response.data;
  } catch (error: any) {
    if (error.response.status === 409) {
      dispatch(showError({ message: '중복된 단지 이름입니다' }));
    } else {
      dispatch(
        showError({
          message: error?.response?.data?.message || '단지/계약을 등록하지 못했습니다. 잠시후 다시 시도해주세요',
        }),
      );
    }
    return Promise.reject(error);
  }
});

const uploadToStorageAsync = createAsyncThunk(
  'complex/upload-devices',
  async (payload: UploadDevicesPayload, { dispatch }) => {
    try {
      const response = await getS3SignedUrl(payload.file.name);
      const signedUrl = response.data.signedUrl;

      const headers = new Headers();
      headers.append('Content-Type', 'text/csv');

      await fetch(signedUrl, {
        method: 'PUT',
        headers,
        body: payload.file,
      });

      const s3FilePath: string = new URL(signedUrl).pathname.substring(1);
      dispatch(
        validateUploadedDevicesAsync({
          complexId: payload.complexId,
          numberOfDevices: payload.numberOfDevices,
          path: s3FilePath,
        }),
      );
      return s3FilePath;
    } catch (error: any) {
      dispatch(showError({ message: '업로드를 실패했습니다. 잠시 후 다시 시도해주세요' }));
      return Promise.reject(error);
    }
  },
);

const validateUploadedDevicesAsync = createAsyncThunk(
  'complex/validate-devices',
  async (payload: ValidateDevicesPayload, { dispatch }) => {
    try {
      await validateUploadedDevices(payload.complexId, payload.path, payload.numberOfDevices);
      return true;
    } catch (error: any) {
      dispatch(
        showError({
          message:
            error.response.message ||
            '업로드 파일에 문제가 있습니다. 파일 내용이 올바른지 다시 확인하고 업로드 해주세요',
        }),
      );
      return Promise.reject(error);
    }
  },
);

const putDevicesToComplexAsync = createAsyncThunk(
  'complex/complexId/devices',
  async (payload: { complexId: string; uploadDevices: DevicesPayload }, { dispatch }) => {
    try {
      const response = await putDevicesToComplex(payload.complexId, payload.uploadDevices);
      dispatch(showSuccess({ message: '단지에 매체를 연결했습니다.' }));
      return response.data;
    } catch (error: any) {
      if (error.response.status === 409) {
        dispatch(showError({ message: '단지에 이미 등록된 매체가 존재합니다.' }));
      } else {
        dispatch(
          showError({
            message: error?.response?.data?.message || '단지에 매체를 등록하지 못했습니다. 잠시 후 다시 시도해주세요',
          }),
        );
      }
      return Promise.reject(error);
    }
  },
);

const newComplexEditorSlice = createSlice({
  name: 'complexCreator',
  initialState,
  reducers: {
    resetComplexEditor: (state: Draft<ComplexEditorState>) => {
      state.viewTitle = initialState.viewTitle;
      state.payload = {
        ...initialState.payload,
        id: uuid.v4(),
        contract: {
          ...initialState.payload.contract,
          id: uuid.v4(),
        },
      };
      state.activeStep = initialState.activeStep;
      state.completingStatus = initialState.completingStatus;
      state.devicesUploadingStatus = initialState.devicesUploadingStatus;
    },
    showComplexStep: (state: Draft<ComplexEditorState>) => {
      state.activeStep = 0;
      state.viewTitle = '단지 정보';
    },
    showContractStep: (state: Draft<ComplexEditorState>) => {
      state.activeStep = 1;
      state.viewTitle = '계약 정보';
    },
    showDevicesStep: (state: Draft<ComplexEditorState>) => {
      state.activeStep = 2;
      state.viewTitle = '매체 정보';
    },
    setComplexName: (state: Draft<ComplexEditorState>, action: PayloadAction<string>) => {
      state.payload.name = action.payload;
    },
    setComplexSalesForceId: (state: Draft<ComplexEditorState>, action: PayloadAction<string>) => {
      state.payload.salesForceId = action.payload;
    },
    setComplexBuilding: (state: Draft<ComplexEditorState>, action: PayloadAction<BuildingPayload | undefined>) => {
      state.payload.building = action.payload;
    },
    setContractSalesForceId: (state: Draft<ComplexEditorState>, action: PayloadAction<string>) => {
      state.payload.contract.salesForceId = action.payload;
    },
    setContractMadeDate: (state: Draft<ComplexEditorState>, action: PayloadAction<string>) => {
      state.payload.contract.madeAt = action.payload;
    },
    setContractBeginsDate: (state: Draft<ComplexEditorState>, action: PayloadAction<string>) => {
      state.payload.contract.beginsAt = action.payload;
    },
    setContractEndsDate: (state: Draft<ComplexEditorState>, action: PayloadAction<string>) => {
      state.payload.contract.endsAt = action.payload;
    },
    setContractNumberOfBuildings: (state: Draft<ComplexEditorState>, action: PayloadAction<number>) => {
      state.payload.contract.numberOfBuildings = action.payload;
    },
    setContractNumberOfDevices: (state: Draft<ComplexEditorState>, action: PayloadAction<number>) => {
      state.payload.numberOfDevices = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ComplexEditorState>) => {
    builder
      .addCase(postComplexAsync.rejected, (state: ComplexEditorState) => {
        state.completingStatus = 'idle';
      })
      .addCase(postComplexAsync.pending, (state: ComplexEditorState) => {
        state.completingStatus = 'loading';
      })
      .addCase(postComplexAsync.fulfilled, (state: ComplexEditorState) => {
        state.completingStatus = 'done';
      })
      .addCase(uploadToStorageAsync.rejected, (state: ComplexEditorState) => {
        state.devicesUploadingStatus = 'idle';
        state.payload.devices = {
          ...(state.payload.devices || {}),
          uploadFilePath: undefined,
        };
      })
      .addCase(uploadToStorageAsync.pending, (state: ComplexEditorState) => {
        state.devicesUploadingStatus = 'loading';
      })
      .addCase(uploadToStorageAsync.fulfilled, (state: ComplexEditorState, action: PayloadAction<string>) => {
        state.payload.devices = {
          ...(state.payload.devices || {}),
          uploadFilePath: action.payload,
        };
      })
      .addCase(validateUploadedDevicesAsync.fulfilled, (state: ComplexEditorState) => {
        state.devicesUploadingStatus = 'idle';
        state.devicesValidated = true;
      })
      .addCase(validateUploadedDevicesAsync.rejected, (state: ComplexEditorState) => {
        state.devicesUploadingStatus = 'idle';
        state.devicesValidated = false;
      })
      .addCase(putDevicesToComplexAsync.rejected, (state: ComplexEditorState) => {
        state.completingStatus = 'idle';
      })
      .addCase(putDevicesToComplexAsync.pending, (state: ComplexEditorState) => {
        state.completingStatus = 'loading';
      })
      .addCase(putDevicesToComplexAsync.fulfilled, (state: ComplexEditorState) => {
        state.completingStatus = 'idle';
      });
  },
});

// selector
const selectComplexEditorState = (root: RootState) => root.complexCreator;

export const {
  setComplexBuilding,
  setComplexName,
  setComplexSalesForceId,
  setContractBeginsDate,
  setContractEndsDate,
  setContractMadeDate,
  setContractNumberOfBuildings,
  setContractNumberOfDevices,
  setContractSalesForceId,
  showDevicesStep,
  showComplexStep,
  showContractStep,
  resetComplexEditor,
} = newComplexEditorSlice.actions;

export { postComplexAsync, uploadToStorageAsync, putDevicesToComplexAsync, selectComplexEditorState };

export type { BuildingPayload, ComplexEditorState, ComplexPayload, ContractPayload, DevicesPayload };

export default newComplexEditorSlice.reducer;
