import { ActionReducerMapBuilder, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

import { DeviceActivity, DeviceApp, DeviceDetail } from 'models/device';
import {
  DeviceDetailResponse,
  getDeviceDetailAsync,
  refreshDeviceDetailsAsync,
  requestChangeAttributeAsync,
  updateApplicationVersionAsync,
  updateRfSyncInfoAsync,
  deleteRfSyncInfoAsync,
} from './device-editor-thunks';

interface DeviceEditorState {
  device: DeviceDetail | undefined;
  activities: DeviceActivity[];
  apps: DeviceApp[] | undefined;
  appUpdateData: {
    [key: string]: number;
  };
  loading: 'init' | 'loading' | 'failed';
  changingAttributes: boolean;
}

const initialState: DeviceEditorState = {
  device: undefined,
  activities: [],
  apps: [],
  appUpdateData: {},
  loading: 'init',
  changingAttributes: false,
};

const deviceEditorSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    clearEditor: (state: Draft<DeviceEditorState>) => {
      state.loading = 'init';
    },
    addAppUpdateData: (
      state: Draft<DeviceEditorState>,
      action: PayloadAction<{
        [key: string]: number;
      }>,
    ) => {
      state.appUpdateData = {
        ...state.appUpdateData,
        ...(action.payload as {
          [key: string]: number;
        }),
      };
    },
    deleteAppUpdateData: (state: Draft<DeviceEditorState>, action: PayloadAction<any>) => {
      const tempList = state.appUpdateData;
      delete tempList[action.payload.appName];
      state.appUpdateData = tempList;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DeviceEditorState>) => {
    builder
      .addCase(getDeviceDetailAsync.pending, (state: Draft<DeviceEditorState>) => {
        state.loading = 'loading';
      })
      .addCase(getDeviceDetailAsync.rejected, (state: Draft<DeviceEditorState>) => {
        state.loading = 'failed';
      })
      .addCase(
        getDeviceDetailAsync.fulfilled,
        (state: Draft<DeviceEditorState>, action: PayloadAction<DeviceDetailResponse>) => {
          state.loading = 'init';
          state.device = action.payload.device;
          state.activities = action.payload.activities;
          state.apps = action.payload.apps;
        },
      )
      .addCase(
        refreshDeviceDetailsAsync.fulfilled,
        (state: Draft<DeviceEditorState>, action: PayloadAction<DeviceDetailResponse>) => {
          if (state.device?.serialNumber === action.payload.device?.serialNumber) {
            state.device = action.payload.device;
            state.activities = action.payload.activities;
            state.apps = action.payload.apps;
          }
        },
      )
      .addCase(requestChangeAttributeAsync.pending, (state: Draft<DeviceEditorState>) => {
        state.changingAttributes = true;
      })
      .addCase(requestChangeAttributeAsync.rejected, (state: Draft<DeviceEditorState>) => {
        state.changingAttributes = false;
      })
      .addCase(
        requestChangeAttributeAsync.fulfilled,
        (state: Draft<DeviceEditorState>, action: PayloadAction<DeviceActivity[]>) => {
          state.changingAttributes = false;
          state.activities = action.payload;
        },
      )
      .addCase(updateApplicationVersionAsync.pending, (state: Draft<DeviceEditorState>) => {
        state.changingAttributes = true;
      })
      .addCase(updateApplicationVersionAsync.rejected, (state: Draft<DeviceEditorState>) => {
        state.changingAttributes = false;
      })
      .addCase(
        updateApplicationVersionAsync.fulfilled,
        (state: Draft<DeviceEditorState>, action: PayloadAction<DeviceActivity[]>) => {
          state.changingAttributes = false;
          state.activities = action.payload;
        },
      )
      .addCase(updateRfSyncInfoAsync.pending, (state: Draft<DeviceEditorState>) => {
        state.changingAttributes = true;
      })
      .addCase(updateRfSyncInfoAsync.rejected, (state: Draft<DeviceEditorState>) => {
        state.changingAttributes = false;
      })
      .addCase(
        updateRfSyncInfoAsync.fulfilled,
        (state: Draft<DeviceEditorState>, action: PayloadAction<DeviceActivity[]>) => {
          state.changingAttributes = false;
          state.activities = action.payload;
        },
      )
      .addCase(deleteRfSyncInfoAsync.pending, (state: Draft<DeviceEditorState>) => {
        state.changingAttributes = true;
      })
      .addCase(deleteRfSyncInfoAsync.rejected, (state: Draft<DeviceEditorState>) => {
        state.changingAttributes = false;
      })
      .addCase(
        deleteRfSyncInfoAsync.fulfilled,
        (state: Draft<DeviceEditorState>, action: PayloadAction<DeviceActivity[]>) => {
          state.changingAttributes = false;
          state.activities = action.payload;
        },
      );
  },
});

// selectors
export const selectDeviceEditor = (state: RootState) => state.deviceEditor;

export const { clearEditor, addAppUpdateData, deleteAppUpdateData } = deviceEditorSlice.actions;

export default deviceEditorSlice.reducer;
