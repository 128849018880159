import React from 'react';
import { createTheme } from '@mui/material';

// blue, green, red, yellow, gray 색상 설정을 위한 인터페이스
interface CustomColorSet {
  50?: string;
  100?: string;
  150?: string;
  200?: string;
  250?: string;
  300?: string;
  350?: string;
  400?: string;
  500?: string;
  600?: string;
  650?: string;
  700?: string;
  750?: string;
  770?: string;
  800?: string;
  850?: string;
  870?: string;
  900?: string;
  dark?: string;
  main?: string;
  light?: string;
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    body3: true;
    body4: true;
    body5: true;
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    subtitle3: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
  }

  interface Palette {
    blue: CustomColorSet;
    gray: CustomColorSet;
    green: CustomColorSet;
    red: CustomColorSet;
    yellow: CustomColorSet;
    gradient: Palette['primary'];
    background2: Palette['primary'];
  }

  interface PaletteOptions {
    blue?: CustomColorSet;
    gray?: CustomColorSet;
    green?: CustomColorSet;
    red?: CustomColorSet;
    yellow?: CustomColorSet;
    gradient?: PaletteOptions['primary'];
    background2?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gradient: true;
    gray: true;
  }
}

const fmkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 480,
      sm: 768,
      md: 1280,
      lg: 1440,
      xl: 1700,
    },
  },
  typography: {
    fontFamily: ['Pretendard', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: 32,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    h2: {
      fontSize: 28,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    h3: {
      fontSize: 28,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    h4: {
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    h5: {
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    h6: {
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    subtitle3: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    body3: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    body4: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    body5: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      dark: '#006BD6',
      main: '#0080FF',
      light: '#5CADFF',
    },
    secondary: {
      dark: '#000E1A',
      main: '#001B34',
      light: '#002B57',
    },
    blue: {
      50: '#F5FAFF',
      100: '#C2E0FF',
      200: '#8FC7FF',
      300: '#5CADFF',
      400: '#2994FF',
      500: '#0080FF',
      600: '#0066CC',
      700: '#003D7A',
      800: '#002B57',
      900: '#001B34',
    },
    gray: {
      100: '#FCFCFC',
      150: '#F5F5F5',
      200: '#EFEFEF',
      250: '#E8E8E8',
      300: '#DFDFDF',
      350: '#C8C8C8',
      400: '#B7B7B7',
      500: '#949494',
      600: '#777777',
      650: '#616161',
      700: '#555555',
      750: '#3F3F3F',
      770: '#383838',
      800: '#2A2A2A',
      850: '#1F1F1F',
      870: '#1A1A1A',
      900: '#111111',
      dark: '#C8C8C8',
      main: '#DFDFDF',
      light: '#EFEFEF',
    },
    error: {
      dark: '#D62B20',
      main: '#FF334B',
      light: '#FF7081',
    },
    red: {
      50: '#FFEBED',
    },
    warning: {
      dark: '#FFB500',
      main: '#FFCC00',
      light: '#FFD743',
    },
    yellow: {
      50: '#FFF8E0',
    },
    success: {
      dark: '#009035',
      main: '#15BD66',
      light: '#8DD8A6',
    },
    green: {
      50: '#E8F7ED',
    },
    gradient: {
      main: 'linear-gradient(132.75deg, #0080FF 24.69%, #00E16D 100%)',
      dark: 'linear-gradient(91.69deg, #0054A7 7.84%, #00F275 100.84%, #00E16D 100.84%, #00E16D 100.84%)',
      contrastText: '#fff',
    },
    background: {
      default: '#FFF',
    },
    background2: {
      main: '#FCFCFC',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          height: '40px',
          borderColor: '#E0E0E0',
          borderRadius: '4px',
          '&:hover': {
            borderColor: '#949494',
            'fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: '#949494',
            },
          },
          '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
          },
          '&.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
            backgroundColor: '#0000000A',
            borderColor: '#DFDFDF',
          },
          '&.Mui-readOnly input': {
            fontWeight: 500,
            backgroundColor: '#FCFCFC',
          },
          '&.Mui-readOnly fieldset': {
            fontWeight: 500,
            border: 'none',
          },
        },
        input: {
          boxSizing: 'border-box',
          height: '40px',
          '&:-webkit-autofill': {
            bowShadow: '0 0 0 100px #ffffff inset',
            WebkitBoxShadow: '0 0 0 100px #ffffff inset',
          },
          '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          height: '40px',
          border: '1px solid #F5F5F5',
          borderRadius: '4px',
          backgroundColor: '#F5F5F5',
          '&:hover': {
            borderColor: '#949494',
            backgroundColor: '#F5F5F5',
          },
          '&:active': {
            borderColor: '#0080FF',
          },
          '&:focus-within': {
            borderColor: '#0080FF',
          },
        },
        input: {
          height: '36px',
          '&:-webkit-autofill': {
            bowShadow: '0 0 0 100px #F5F5F5 inset',
            WebkitBoxShadow: '0 0 0 100px #F5F5F5 inset',
          },
        },
        underline: {
          '&&&:before': {
            borderBottom: 'none',
          },
          '&&:after': {
            borderBottom: 'none',
          },
          '&&&:before:hover': {
            borderBottom: 'none',
          },
          '&&:after:hover': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '8px 0 0 0',
          fontSize: 12,
          fontStyle: 'normal',
          fontWeight: 400,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          sx: {
            marginTop: '8px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 128, 255, 0.04)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          width: 'fit-content',
          borderRadius: '8px',
          boxShadow: 'none',
          textTransform: 'unset',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeSmall: {
          height: '34px',
          padding: '6px 12px',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '22px',
        },
        sizeMedium: {
          height: '40px',
          padding: '8px 16px',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
        },
        sizeLarge: {
          height: '48px',
          padding: '10px 20px',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '28px',
        },
        containedWarning: {
          color: '#FFFFFF',
        },
        containedSuccess: {
          color: '#FFFFFF',
        },
        startIcon: {
          margin: '0px',
        },
        iconSizeSmall: {
          '& > *:first-of-type': { fontSize: 18, margin: '-1px', marginRight: '4px' },
        },
        iconSizeMedium: {
          '& > *:first-of-type': { fontSize: 20, margin: '-1px', marginRight: '4px' },
        },
        iconSizeLarge: {
          '& > *:first-of-type': { fontSize: 26, margin: '-1px', marginRight: '6px' },
        },
      },
      variants: [
        {
          props: { color: 'gradient' },
          style: {
            height: '48px',
            fontSize: '20px',
            fontWeight: '500',
            padding: '14px 32px',
            borderRadius: '100px',
            lineHeight: '20px',
            background: 'linear-gradient(132.75deg, #0080FF 24.69%, #00E16D 100%)',
            '&:disabled': {
              color: '#B7B7B7',
              background: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
        {
          props: { color: 'gray', variant: 'outlined' },
          style: {
            color: '#2A2A2A',
          },
        },
      ],
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '32px',
          minWidth: '32px',
          '@media (min-width: 600px)': {
            minHeight: '32px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: () => {
          return {
            '&.MuiTooltip-tooltip': {
              '&.MuiTooltip-tooltipPlacementTop': {
                marginLeft: '0',
                marginBottom: '4px',
                maxWidth: '380px',
              },
            },
          };
        },
      },
    },
  },
});

export { fmkTheme };
