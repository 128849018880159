// lib/fmk-axios
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

interface CustomConfig {
  baseUrl: string;
  timeout?: number;
  withCredentials?: boolean;
  headers?: Record<string, string>;
}

class RestApiClient {
  private axiosInstance: AxiosInstance;

  constructor(config: CustomConfig) {
    const axiosInstance = axios.create({
      baseURL: config.baseUrl,
      timeout: config.timeout || 60 * 1000, // set default timeout to 1 minute
      withCredentials: config.withCredentials || true,
      headers: config.headers || {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });

    axiosInstance.interceptors.request.use(function (config) {
      const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage') as any);
      config.headers = !oktaToken?.accessToken?.accessToken
        ? config.headers
        : { ...config.headers, Authorization: `Bearer ${oktaToken.accessToken.accessToken}` };

      return config;
    });

    this.axiosInstance = axiosInstance; // axios custom을 위해 저장
  }

  /**
   * @description GET 요청
   * @param url path
   * @param config AxiosRequestConfig
   */
  async get<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> {
    return await this.axiosInstance.get<T, AxiosResponse<T, D>, D>(url, config);
  }

  /**
   * @description POST 요청
   * @param url path
   * @param data 파라미터
   * @param config AxiosRequestConfig
   */
  async post<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> {
    return await this.axiosInstance.post<T, AxiosResponse<T, D>, D>(url, data, config);
  }

  /**
   * @description PUT 요청
   * @param url path
   * @param data 파라미터
   * @param config AxiosRequestConfig
   */
  async put<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> {
    return await this.axiosInstance.put<T, AxiosResponse<T, D>, D>(url, data, config);
  }

  /**
   * @description PATCH 요청
   * @param url path
   * @param data 파라미터
   * @param config AxiosRequestConfig
   */
  async patch<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> {
    return await this.axiosInstance.patch<T, AxiosResponse<T, D>, D>(url, data, config);
  }

  /**
   * @description DELETE 요청
   * @param url path
   * @param data
   * @param config AxiosRequestConfig
   */
  async delete<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> {
    return await this.axiosInstance.delete<T, AxiosResponse<T, D>, D>(url, config);
  }
}

export default RestApiClient;
