import Skeleton from '@mui/material/Skeleton';

const AddressSkeleton = () => {
  return (
    <>
      <Skeleton height={70} />
      <Skeleton height={70} />
      <Skeleton height={70} />
    </>
  );
};

export { AddressSkeleton };
