import { Paper } from '@mui/material';

import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { Icon } from 'leaflet';

import { useAppSelector } from 'app/hooks';
import { selectDeviceEditor } from 'features/devices/device-editor-slice';

const markerIcon = new Icon({
  iconUrl: 'https://img.icons8.com/ultraviolet/2x/visit.png',
  iconSize: [42, 42],
});

const defaultLocation = { lat: 37.52232, lng: 126.92021 };

const DeviceMiniMap = (): JSX.Element => {
  const { device } = useAppSelector(selectDeviceEditor);

  if (!device) {
    return <></>;
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        width: '100%',
        height: '300px',
        minHeight: '250px',
        maxHeight: '400px',
        opacity: device.building.coordinates?.lat ? 1 : 0.3,
        borderRadius: '10px',
      }}
    >
      <MapContainer
        center={device.building.coordinates?.lat ? device.building.coordinates : defaultLocation}
        zoom={17}
        zoomControl={false}
        scrollWheelZoom={false}
        dragging={false}
        style={{ width: '100%', height: '300px', borderRadius: '10px' }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={device.building.coordinates || { lat: 0, lng: 0 }} icon={markerIcon} />
      </MapContainer>
    </Paper>
  );
};

export { DeviceMiniMap };
